<template>
  <van-overlay :z-index="200" :show="showModal" @click.stop :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop>
      <div @click.stop>
        <div class="centeredInline row">
          <div class="ib-defeat-body">
            <div class="centeredInline">
              <h1 class="shine" style="padding:2rem 4rem">{{ $t('common.loseTitle') }}</h1>
            </div>
          </div>
            <div class="centeredInline">
              <van-button @click="ressurect" class="ib-ressurect-btn greetings_btn" size="small" round plain :disabled="!canRespawn">
                <div class="centeredInline">
                  {{ $t('common.ressurectBtn') }}
                  <van-count-down style="color:#fff" v-show="!canRespawn" @finish="canRespawn=true" :time="respawnTime">
                    <template #default="timeData">(
                      <template v-if="timeData.minutes">
                        {{ timeData.minutes }} :
                      </template>
                      <span class="block">{{ timeData.seconds }}</span>
                    )</template>
                  </van-count-down>
                </div>
              </van-button>
            </div>
          </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import EventDispatcher from '../game/utils/EventDispatcher'
export default {
  name: 'defeat-component',
  props: {
    result: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      respawnTime: 0,
      canResp: false,
      eventEmitter: null
    }
  },
  computed: {
    canRespawn: {
      get () {
        return this.canResp
      },
      set (value) {
        this.canResp = value
      }
    },
    showModal: {
      get () {
        return this.$store.state.components.popups.defeat.show
      },
      set (value) {
        this.$store.dispatch('components/SHOW_DEFEAT_POPUP', value)
        if (!value) {
          setTimeout(() => {
            this.$emit('rerender')
          }, 500)
        }
      }
    }
  },
  methods: {
    async ressurect () {
      if (!this.canRespawn) return false
      await this.$client.service('userdata').patch(this.user.id, {
        respawn: true
      }).then(() => {
        this.$toast(this.$t('common.ressurectOk'))
        // this.eventEmitter.emit('restartScene')
//        this.eventEmitter.emit('playerRessurected')
        this.showModal = false
      }).catch(e => {
        console.log(e)
        this.$toast(this.$t('common.ressurectError'))
      })
    }
  },
  mounted () {
    this.respawnTime = (this.user.ressurectTime - Math.floor(Math.round(new Date().getTime() / 1000))) * 1000
    this.canResp = false
    this.eventEmitter = EventDispatcher.getInstance()
  }
}
</script>

<style>

</style>