<template>
  <van-overlay :lock-scroll="false" :z-index="200" :show="showModal" @click="closeModal">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div>
          <div class="centeredInline">
            <div class="modalTitle van-ellipsis">{{ `${$t('modules.controlPanel.addNpcBtn')}` }}</div>
          </div>
          <van-form @submit="onSubmit" ref="form">
            <div class="infoText">{{$t('modules.controlPanel.npcName')}}</div>
            <van-field
              type="text"
              v-model.trim="inputed.name"
              name="name"
              :placeholder="$t('modules.controlPanel.npcName')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
            <div class="infoText">{{$t('modules.controlPanel.npcMapId')}}<span style="margin-left:.5rem" @click="$store.dispatch('cpanel/SHOW_MAP_VISUALIZER', true)" class="underline pointer">{{ $t('common.select') }}</span></div>
            <van-field
              type="number"
              v-model.number="inputed.mapId"
              name="mapId"
              :placeholder="$t('modules.controlPanel.npcMapId')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
            <!-- sprite upload -->
            <div class="infoText">{{$t('modules.controlPanel.npcSprite')}}</div>
            <div class="centeredInline" id="__selectSpriteBtn">
              <div class="fake-button" id="__selectSpriteBtn" v-if="!fileSelected && !inputed.spritePath">{{ $t('common.selectFile') }}</div>
            </div>
            <div class="centeredInline" v-if="uploading" style="width:100%;">
              <van-progress :percentage="uploadProgress"></van-progress>>
            </div>
            <div class="centeredInline" v-if="inputed.spritePath">
              <div class="cell-icon">
                <img :src="cdnPath(inputed.spritePath)" alt="" />
              </div>
            </div>
            <!-- sprite upload end -->
            <!-- image upload start -->
            <div class="infoText">{{$t('modules.controlPanel.npcImage')}}</div>
            <div class="centeredInline" id="__selectImageBtn">
              <div class="fake-button" id="__selectImageBtn" v-if="!imageSelected && !inputed.imagePath">{{ $t('common.selectFile') }}</div>
            </div>
            <div class="centeredInline" v-if="imageUploading" style="width:100%;">
              <van-progress :percentage="imageUploadProgress"></van-progress>>
            </div>
            <div class="centeredInline" v-if="inputed.imagePath">
              <div class="cell-icon">
                <img :src="cdnPath(inputed.imagePath)" alt="" />
              </div>
            </div>
            <!-- image upload end -->
            <div class="infoText">{{$t('modules.controlPanel.npcDialogIdOnOpen')}}</div>
            <van-field
              type="number"
              v-model.number="inputed.dialogIdOnOpen"
              name="dialogIdOnOpen"
              :placeholder="$t('modules.controlPanel.npcDialogIdOnOpen')"
              />
            <div class="infoText">{{$t('modules.controlPanel.npcCoors')}}</div>
            <van-field
              type="number"
              v-model.number="inputed.coorsX"
              name="coorsX"
              label="X"
              :placeholder="$t('common.coorsX')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
            <van-field
              type="number"
              v-model.number="inputed.coorsY"
              name="coorsY"
              label="Y"
              :placeholder="$t('common.coorsX')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
            <div style="margin-top: 16px;">
              <van-button :loading="savePending" :disabled="savePending" block type="primary" native-type="submit">
                {{ $t('common.save') }}
              </van-button>
            </div>
          </van-form>
        </div>
        <file-upload
          :key="`ucmpk-${uploadingCmpKey}`"
          filetype = "npc-sprite"
          trigger = "__selectSpriteBtn"
          @on-selected = "onSelected"
          @on-upload = "onUploading"
          @on-progress = "onProgress"
          @on-success = "onSuccess"
          @on-error = "onError"></file-upload>
        <file-upload
          :key="`unicmp-${uploadingCmpKey}`"
          filetype = "npc-image"
          trigger = "__selectImageBtn"
          @on-selected = "onImageSelected"
          @on-upload = "onImageUploading"
          @on-progress = "onImageProgress"
          @on-success = "onImageSuccess"
          @on-error = "onImageError"></file-upload>
        <map-visualizer @selected="locSelected"></map-visualizer>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import FileUpload from '../../../../components/FileUpload.vue'
import MapVisualizer from '../../settings/modals/MapVisualizer.vue'
import { mapState } from 'vuex'
export default {
  name: 'control-panel-add-npc-modal',
  components: {
    MapVisualizer,
    FileUpload
  },
  data () {
    return {
      savePending: false,
      uploading: false,
      fileSelected: null,
      imageSelected: null,
      imageUploadProgress: 0,
      imageUploading: false,
      uploadProgress: 0,
      uploadingCmpKey: 0,
      inputed: {
        name: '',
        mapId: '',
        spritePath: '',
        imagePath: '',
        dialogIdOnOpen: '',
        coorsX: 1,
        coorsY: 1,
      }
    }
  },
  computed: {
    ...mapState({
      showModal: state => state.cpanel.modals.addNpc.show
    })
  },
  methods: {
    onSelected (file = null) {
      if (!file) return false
      this.fileSelected = file
    },
    onUploading () {
      this.uploading = true
    },
    onProgress (perc = 0) {
      this.uploadProgress = perc
    },
    onSuccess (file = null) {
      if (!file) return false
      this.$toast(this.$t('modules.controlPanel.uploadNpcSpriteSuccess'))
      this.inputed.spritePath = file.data.path
      this.uploading = false
      this.fileSelected = null
      this.uploadProgress = 0
      this.uploadingCmpKey++
    },
    onError () {
      this.$toast(this.$t('modules.controlPanel.uploadNpcSpriteError'))
      this.uploading = false
      this.uploadProgress = 0
      this.fileSelected = false
      this.uploadingCmpKey++
    },
    onImageSelected (file = null) {
      if (!file) return false
      this.imageSelected = file
    },
    onImageUploading () {
      this.imageUploading = true
    },
    onImageProgress (perc = 0) {
      this.imageUploadProgress = perc
    },
    onImageSuccess (file = null) {
      if (!file) return false
      this.$toast(this.$t('modules.controlPanel.uploadNpcImageSuccess'))
      this.inputed.imagePath = file.data.path
      this.imageUploading = false
      this.imageSelected = null
      this.imageUploadProgress = 0
      this.uploadingCmpKey++
    },
    onImageError () {
      this.$toast(this.$t('modules.controlPanel.uploadNpcImageError'))
      this.imageUploading = false
      this.imageUploadProgress = 0
      this.imageSelected = false
      this.uploadingCmpKey++
    },
    closeModal () {
      this.$store.dispatch('cpanel/SHOW_ADD_NPC_MODAL', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    },
    async onSubmit () {
      if (this.inputed.name.length < 2 || this.inputed.name.length > 56) return this.$toast(this.$t('modules.controlPanel.npcNameLengthError'))
      if (isNaN(this.inputed.dialogIdOnOpen)) return this.$toast(this.$t('modules.controlPanel.npcDialogIdErr'))
      const checkout = await this.$refs.form.validate()
      if (!checkout) {
        this.savePending = true
        this.$client.service('npc').create({
          name: this.inputed.name,
          mapId: this.inputed.mapId,
          coorsX: this.inputed.coorsX,
          coorsY: this.inputed.coorsY,
          spritePath: this.inputed.spritePath,
          dialogOnOpenId: this.inputed.dialogIdOnOpen,
          imagePath: this.inputed.imagePath
        }).then(() => {
          this.$toast(this.$t('modules.controlPanel.addNpcOk'))
          this.savePending = false
          this.closeModal()
        }).catch(e => {
          console.log(e)
          this.$toast(this.$t('modules.controlPanel.addNpcError'))
          this.savePending = false
        })
      }
    },
    locSelected (id = null) {
      if (!id) return false
      this.inputed.mapId = id
    }
  },
  mounted () {
  }
}
</script>

<style>

</style>