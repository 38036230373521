<template>
  <van-overlay :z-index="50" :show="showModal" @click="closeModal">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline row">
          <div class="modalTitle van-ellipsis">{{ `${$t('modules.controlPanel.editMapDataTitle')} - ${data.mapId}` }}</div>
          <van-form @submit="onSubmit" ref="form">
            <div class="smallText" style="margin:1rem 0;">
              1. {{ $t('modules.controlPanel.mapDataI') }}
            </div>
            <van-field
              type="text"
              v-model="inputed.title"
              name="title"
              :label="$t('common.title')"
              :placeholder="$t('modules.controlPanel.mapTitle')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <div style="margin: 16px;">
              <van-button :loading="savePending" :disabled="savePending" round plain block class="greetings_btn" native-type="submit">
                {{ $t('common.save') }}
              </van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'control-panel-edit-mapdata-modal',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      savePending: false,
      inputed: {
        title: '',
        description: '',
        level: '',
        coorsY: '',
        coorsX: '',
        mapKey: ''
      }
    }
  },
  computed: {
    ...mapState({
      showModal: state => state.cpanel.modals.editMapData.show
    })
  },
  methods: {
    closeModal () {
      this.$store.dispatch('cpanel/SHOW_EDIT_MAP_DATA', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    },
    async onSubmit () {
      const checkout = await this.$refs.form.validate()
      if (!checkout) {
        this.$emit('save', {
          mapId: this.data.mapId,
          title: this.inputed.title
        })
        this.closeModal()
      }
    }
  },
  mounted () {
    this.inputed = Object.assign(this.inputed, this.data)
    this.inputed.coorsX = this.data.initX || '0'
    this.inputed.coorsY = this.data.initY || '0'
    this.inputed.level = this.data.requiredLevel || '0'
  }
}
</script>

<style>

</style>