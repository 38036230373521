<template>
  <div class="ib-fight-overlay" :class="show?'opened':'closed'">
    <div v-for="(skill, index) in skills" :key="`skill-${index}`" @click="useSpell(skill)">
      <img :src="skill.icon" alt="" />
    </div>
  </div>
</template>

<script>
import EventDispatcher from '../game/utils/EventDispatcher'
export default {
  name: 'fight-overlay-component',
  data () {
    return {
      spellsBlocked: false,
      eventEmitter: null,
      blockSpellsHandler: null,
      unblockSpellsHandler: null
    }
  },
  computed: {
    skills () {
      if (this.user.class === 'warrior') {
        return [{
          icon: '/assets/icons/skills/sword-fire.png',
          name: 'Autoattack',
          eventName: 'autoattack'
        }]
      } else if (this.user.class === 'mage') {
        return [{
          icon: '/assets/icons/skills/arcane-sceptre.png',
          name: 'Autoattack',
          eventName: 'autoattack'
        }]
      } else if (this.user.class === 'paladin') {
        return [{
          icon: '/assets/icons/skills/sword-holy.png',
          name: 'Autoattack',
          eventName: 'autoattack'
        }]
      } else if (this.user.class === 'necromancer') {
        return [{
          icon: '/assets/icons/skills/arcane-sceptre.png',
          name: 'Autoattack',
          eventName: 'autoattack'
        }]
      } else if (this.user.class === 'assasin') {
        return [{
          icon: '/assets/icons/skills/dagger-1.png',
          name: 'Autoattack',
          eventName: 'autoattack'
        }]
      }
    },
    show () {
      return this.$store.state.components.popups.fightOverlay.show
    }
  },
  methods: {
    useSpell (skill = null) {
      if (!skill) return false
      if (this.spellsBlocked) return false
      this.eventEmitter.emit('useSpell', {
        spell: skill.eventName
      })
      this.$client.service('combat').patch(this.combat.id, {
        hit: true
      }).catch(e => {
        console.log('combat error', e)
      })
    }
  },
  mounted () {
    this.eventEmitter = EventDispatcher.getInstance()
    this.blockSpellsHandler = data => {
      this.spellsBlocked = true
    }
    this.unblockSpellsHandler = data => {
      this.spellsBlocked = false
    }
    this.eventEmitter.on('blockSpells', this.blockSpellsHandler)
    this.eventEmitter.on('unblockSpells', this.unblockSpellsHandler)
  },
  beforeDestroy () {
    this.eventEmitter.removeListener('blockSpells', this.blockSpellsHandler)
    this.eventEmitter.removeListener('unblockSpells', this.unblockSpellsHandler)
    this.blockSpellsHandler = this.unblockSpellsHandler = null
  }
}
</script>

<style>

</style>