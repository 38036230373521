<template>
  <div>
    <div class="sticky-btn centeredInline">
      <van-button round size="small" type="primary" @click="$store.dispatch('cpanel/SHOW_ADD_DIALOGUE_MODAL', true)">
        <div class="centeredInline">
          <fa-icon icon="plus-circle" style="margin-right:.5rem"></fa-icon>
          {{ $t('common.add') }}
        </div>
      </van-button>
    </div>
    <div class="centered" v-if="pending">
      <van-loading></van-loading>
    </div>
    <div v-else>
      <div v-if="items.length">
        <van-cell clickable v-for="(item, index) in items" :key="`dialogue-${item.id}`">
          <template #icon>
            <div class="mr-05 centeredInline" style="margin:0">
              {{ `ID ${item.id}` }}
            </div>
          </template>
          <template #title>
            {{ `${$t('modules.dialogue.title')} ID ${item.id}` }}
          </template>
          <template #label>
            {{ `${$t('modules.controlPanel.dialogueStartPhrase')}: "${item.textOnOpen}"` }}<br>
            {{ `${$t('modules.dialogue.phrasesLength')}: ${item.additionPhrases.length}` }}
          </template>
          <template #right-icon>
            <div class="centeredInline">
              <fa-icon class="pointer" @click="playDialogue(index)" icon="play-circle" style="font-size:1.5rem;margin:0 .25rem;"></fa-icon>
              <fa-icon class="pointer" @click="removeDialogue(item.id)" icon="trash" style="color:coral;font-size:1.5rem;margin:0 .25rem;"></fa-icon>
            </div>
          </template>
        </van-cell>
        <div class="load-more-manual centeredInline">
          <van-button @click.stop="onLoad" type="primary" round size="small" :loading="loadPending" :disabled="loadPending" v-if="itemsLoaded < itemsTotal">
            <div class="centeredInline">
              <fa-icon icon="sync-alt" style="margin-right:.5rem"></fa-icon>
              {{$t('common.loadMore')}}
            </div>
          </van-button>
        </div>
      </div>
      <div class="centered" v-else>
        <div class="infoText">
          {{ $t('modules.controlPanel.noDialogues') }}
        </div>
      </div>
    </div>
    <add-dialogue></add-dialogue>
    <test-dialogue v-if="selectedDialogue" :dialogue="selectedDialogue" @rerender="selectedDialogue=null"></test-dialogue>
  </div>
</template>

<script>
import AddDialogue from './modals/AddDialogue.vue'
import TestDialogue from './modals/TestDialogue.vue'
export default {
  name: 'cpanel-manage-dialogues-index',
  components: {
    AddDialogue,
    TestDialogue
  },
  data () {
    return {
      pending: true,
      items: [],
      itemsLoaded: 0,
      itemsTotal: 0,
      selectedDialogue: null,
      loadPending: false,
      loadFinished: false,
      itemCreated: null,
      itemPatched: null,
      itemRemoved: null
    }
  },
  methods: {
    onLoad () {
      if (this.itemsLoaded >= this.itemsTotal) return this.loadPending = false
      this.loadPending = true
      this.$client.service('dialogues').find({
        query: {
          $skip: this.itemsLoaded,
          $sort: {
            createdAt: -1
          }
        }
      }).then(d => {
        if (d.data.length) {
          this.itemsLoaded+=d.data.length
          this.itemsTotal = d.total
          this.items = this.items.concat(d.data)
          this.loadPending = false
        } else {
          this.loadFinished = true
        }
        this.loadPending = false
      }).catch(e => {
        console.log(e)
        this.loadPending = false
        this.$toast(this.$t('common.fetchDataError'))
      })
    },
    playDialogue (index = null) {
      if (isNaN(index) || !this.items[index]) return false
      this.selectedDialogue = this.items[index]
      this.$nextTick(() => {
        this.$store.dispatch('cpanel/SHOW_TEST_DIALOGUE_MODAL', true)
      })
    },
    removeDialogue (id = null) {
      if (isNaN(id)) return false
      let self = this
      this.$dialog.confirm({
        title: `${self.$t('modules.controlPanel.dialogueRemoveTitle')} (ID ${id})`,
        message: self.$t('modules.controlPanel.dialogueRemoveQ'),
        confirmButtonText: self.$t('common.remove'),
        cancelButtonText: self.$t('common.cancel'),
        showCancelButton: true,
        className: 'confirm'
      }).then(() => {
        self.$client.service('dialogues').remove(id).then(() => {
          self.$toast(self.$t('modules.controlPanel.dialogueRemoveOk'))
        }).catch(() => {
          self.$toast(self.$t('modules.controlPanel.dialogueRemoveErr'))
        })
      }).catch(() => {
        return false
      })
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.controlPanel.manageDialogues'))
  },
  async mounted () {
    this.items = await this.$client.service('dialogues').find({
      query: {
        $sort: {
          createdAt: -1
        }
      }
    }).then(d => {
      this.itemsLoaded = d.data.length
      this.itemsTotal = d.total
      return d.data
    }).catch(e => {
      console.log(e)
      this.$toast(this.$t('common.fetchDataError'))
      return []
    })
    this.pending = false
    this.itemCreated = data => {
      this.items.unshift(data)
    }
    this.itemPatched = data => {
      let index = this.items.findIndex(i => +i.id === +data.id)
      if (index > -1) this.$set(this.items, index, data)
    }
    this.itemRemoved = data => {
      let index = this.items.findIndex(i => +i.id === +data.id)
      if (index > -1) index ? this.items.splice(index, 1) : this.items.shift()
    }
    let dialoguesService = this.$client.service('dialogues')
    dialoguesService.on('created', this.itemCreated)
    dialoguesService.on('updated', this.itemPatched)
    dialoguesService.on('patched', this.itemPatched)
    dialoguesService.on('removed', this.itemRemoved)
  },
  beforeDestroy () {
    let dialoguesService = this.$client.service('dialogues')
    dialoguesService.removeListener('created', this.itemCreated)
    dialoguesService.removeListener('updated', this.itemPatched)
    dialoguesService.removeListener('patched', this.itemPatched)
    dialoguesService.removeListener('removed', this.itemRemoved)
    this.itemCreated = this.itemPatched = this.itemRemoved = null
  }
}
</script>

<style>

</style>