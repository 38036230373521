<template>
  <div class="centered">
    <h1>
      404 - Page not found    
    </h1>
  </div>
</template>

<script>
export default {
  name: 'not-found',
  data () {
    return {

    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', 'Not Found')
    this.$router.replace('/')
  }
}
</script>

<style>

</style>