<template>
  <div>
    <div class="centered" v-if="pending">
      <van-loading></van-loading>
    </div>
    <div v-else>
      <div class="head-post">
        <template v-if="headPost">
        <div class="author-info">
          <span>
            <svg-icon :name="classIcon(headPost.author.class)" size=".75rem" style="margin-right:.25rem"></svg-icon>
            {{ headPost.author.nickname }}
          </span>
          <span>
            <van-tag type="primary">
              {{ headPost.createdAt | dateJS }}
            </van-tag>
          </span>
        </div>
        <div class="pointer" :class="headPostCollapsed?'van-multi-ellipsis--l3':''" @click="headPostCollapsed=!headPostCollapsed">
          {{ headPost.text }}
        </div>
        <div class="head-post-collapser centeredInline">
          <van-button block size="small" type="primary" @click="headPostCollapsed=!headPostCollapsed">
            {{ $t(`common.${headPostCollapsed?'expand':'collapse'}`) }}
          </van-button>
        </div>
        </template>
        <div class="centeredInline" v-else>
          <div class="infoText">{{ $t('modules.forum.headPostNotFound') }}</div>
        </div>
        <div class="forum-posts-header">
          <div class="infoTextMiddle">
            {{ `${$t('common.posts')}`}}
            <template v-if="itemsTotal">
              {{`(${itemsTotal})` }}
            </template>
          </div>
          <span>
            <van-button @click="editThreadMode=true" style="margin-right:.5rem" size="small" type="warning" v-if="user.role !== 'usr' || +user.id===+thread.createdBy">
              <div class="centeredInline">
                <fa-icon icon="cog"></fa-icon>
              </div>
            </van-button>
            <van-button size="small" @click="postMode=true" type="primary" :disabled="!canAddPost">
              <div class="centeredInline">
                <fa-icon icon="plus-circle" style="margin-right:.25rem" v-if="canAddPost"></fa-icon>
                {{ $t(thread.isClosed?'modules.forum.threadClosed':'common.write') }}
              </div>
            </van-button>
          </span>
        </div>
      </div>
      <div class="forum-thread-posts">
        <div v-if="items.length">
          <van-cell :class="item.replyId && +item.replyId === +user.id?'forum-post-reply':''" v-for="item in items" :key="item.id">
            <template #title>
              <div class="post-author-info">
                <span>
                  <svg-icon size=".75rem" style="margin-right:.25rem" :name="classIcon(item.author.class)"></svg-icon>
                  {{ item.author.nickname }}
                  <template v-if="+user.id===+thread.createdBy">({{ $t('modules.forum.author') }})</template>
                </span>
                <span>
                  <van-tag>{{ item.createdAt | dateJS }}</van-tag>
                </span>
              </div>
            </template>
            <template #label>
              <span style="color:#fff">
                {{ item.text }}
              </span>
              <div class="forum-post-actions">
                <span v-if="canReplyPost(item)" @click="reply(item)">{{ $t('common.replyS') }}</span>
                <!--<span v-if="canCitatePost(item)">{{ $t('common.citateS') }}</span>-->
                <span v-if="canEditPost(item)" @click="showEditPost(item)">{{ $t('common.editS') }}</span>
                <span v-if="canRemovePost(item)" @click="removePost(item.id)" style="color:coral">{{ $t('common.removeS') }}</span>
              </div>
            </template>
          </van-cell>
          <div class="load-more-manual centeredInline">
            <van-button @click.stop="onLoad" type="primary" round size="small" :loading="loadPending" :disabled="loadPending" v-if="itemsLoaded < itemsTotal">
              <div class="centeredInline">
                <fa-icon icon="sync-alt" style="margin-right:.5rem"></fa-icon>
                {{$t('common.loadMore')}}
              </div>
            </van-button>
          </div>
        </div>
        <div v-else class="centeredInline" style="margin-top:15%">
          <div class="infoText">{{ $t('modules.forum.threadEmpty') }}</div>
        </div>
      </div>
    </div>
    <van-overlay :z-index="200" :show="postMode" @click="flushPostMode" :lock-scroll="false">
      <div class="modal_common-overlay" @click.stop="flushPostMode">
        <div class="modal_common-content" @click.stop>
          <div class="close-modal" @click.stop="flushPostMode"><fa-icon icon="times"></fa-icon></div>
          <div class="centeredInline row">
          <div class="modalTitle van-ellipsis">{{ `${$t('modules.forum.sendPostTitle')}` }}</div>
            <van-form style="width:100%" @submit="sendPost" ref="form">
              <van-field
                type="textarea"
                v-model.trim="inputed.post"
                name="message"
                maxlength="2048"
                show-word-limit
                :placeholder="$t('modules.chat.messageFieldPlch')"
                :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
              <div style="margin: 16px;">
                <van-button :loading="sendPending" :disabled="sendPending" round plain block class="greetings_btn" native-type="submit">
                  {{ $t('common.save') }}
                </van-button>
              </div>
            </van-form>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :z-index="200" :show="editMode" @click="flushEditMode" :lock-scroll="false">
      <div class="modal_common-overlay" @click.stop="flushEditMode">
        <div class="modal_common-content" @click.stop>
          <div class="close-modal" @click.stop="flushEditMode"><fa-icon icon="times"></fa-icon></div>
          <div class="centeredInline row">
          <div class="modalTitle van-ellipsis">{{ `${$t('modules.forum.editPostTitle')}` }}</div>
            <van-form style="width:100%" @submit="editPost" ref="form">
              <van-field
                type="textarea"
                v-model.trim="inputed.editPost"
                name="message"
                maxlength="2048"
                show-word-limit
                :placeholder="$t('modules.chat.messageFieldPlch')"
                :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
              <div style="margin: 16px;">
                <van-button :loading="sendPending" :disabled="sendPending" round plain block class="greetings_btn" native-type="submit">
                  {{ $t('common.save') }}
                </van-button>
              </div>
            </van-form>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :z-index="200" :show="editThreadMode" :lock-scroll="false">
      <div class="modal_common-overlay">
        <div class="modal_common-content" @click.stop>
          <div class="close-modal" @click.stop="editThreadMode=false"><fa-icon icon="times"></fa-icon></div>
          <div class="centeredInline row">
          <div class="modalTitle van-ellipsis">{{ `${$t('modules.forum.editThreadTitle')}` }}</div>
            <van-form style="width:100%" @submit="editThread" ref="form">
              <van-field
                type="text"
                v-model.trim="inputed.threadName"
                name="name"
                :placeholder="$t('modules.forum.threadName')"
                :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
              <div style="margin: 16px;" class="centeredInline">
                <van-button :loading="sendPending" block :disabled="sendPending" type="primary" size="small" native-type="submit">
                  {{ $t('common.save') }}
                </van-button>
                <van-button v-if="thread && !thread.isClosed" style="margin-left:.5rem" block :loading="closePending" :disabled="closePending" type="warning" size="small" @click.prevent="closeThread">
                  {{ $t('modules.forum.close') }}
                </van-button>
                <van-button v-if="thread && thread.isClosed && user.role !== 'usr'" style="margin-left:.5rem" block :loading="openPending" :disabled="openPending" type="warning" size="small" @click.prevent="openThread">
                  {{ $t('modules.forum.open') }}
                </van-button>
                <van-button style="margin-left:.5rem" block :loading="removePending" :disabled="removePending" type="warning" size="small" @click.prevent="removeThread">
                  {{ $t('common.remove') }}
                </van-button>
              </div>
            </van-form>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: 'forum-thread',
  data () {
    return {
      pending: true,
      loadPending: false,
      loadFinished: false,
      sendPending: false,
      savePending: false,
      removePending: false,
      closePending: false,
      openPending: false,
      headPost: null,
      thread: null,
      items: [],
      itemsLoaded: 0,
      itemsTotal: 0,
      itemCreated: null,
      itemPatched: null,
      itemRemoved: null,
      forumPatched: null,
      forumRemoved: null,
      headPostCollapsed: true,
      postMode: false,
      editMode: false,
      editThreadMode: false,
      editThreadPending: false,
      inputed: {
        replyId: null,
        isReply: null,
        post: '',
        editPost: '',
        editPostId: null,
        threadName: ''
      }
    }
  },
  computed: {
    threadId () {
      return this.$route.params.threadId || null
    },
    canAddPost () {
      if (!this.thread.forAdmins) {
        return !this.thread.isClosed
      } else return this.user.role !== 'usr'
    }
  },
  methods: {
    openThread () {
      this.openPending = true
      this.$client.service('forum').patch(this.thread.id, {
        isClosed: false
      }).then(() => {
        this.$toast(this.$t('modules.forum.openThreadOk'))
        this.openPending = false
        this.editThreadMode = false
      }).catch(e => {
        console.log(e)
        this.$toast(this.$t('modules.forum.openThreadError'))
        this.openPending = false
        this.editThreadMode = false
      })
    },
    closeThread () {
      let self = this
      this.$dialog.confirm({
        title: self.$t('modules.forum.closeThreadTitle'),
        message: self.$t('modules.forum.closeThreadQ'),
        confirmButtonText: self.$t('modules.forum.close'),
        cancelButtonText: self.$t('common.cancel'),
        showCancelButton: true,
        className: 'confirm'
      }).then(() => {
        self.closePending = true
        self.$client.service('forum').patch(self.thread.id, {
          isClosed: true
        }).then(() => {
          self.$toast(self.$t('modules.forum.closeThreadOk'))
          self.closePending = false
          self.editThreadMode = false
        }).catch(e => {
          console.log(e)
          self.closePending = false
          self.$toast(self.$t('modules.forum.closeThreadError'))
          self.editThreadMode = false
        })
      }).catch(() => {
        // 
      })
    },
    canEditPost (post = null) {
      if (!post) return false
      return this.user.role !== 'usr' || +post.userId === +this.user.id
    },
    canRemovePost (post = null) {
      if (!post) return false
      return this.user.role !== 'usr' || +post.userId === +this.user.id
    },
    canCitatePost (post = null) {
      if (!post) return false
      return !this.thread.isClosed
    },
    canReplyPost (post = null) {
      if (!post) return false
      if (!post.author.id || +post.author.id === +this.user.id) return false
      return !this.thread.isClosed
    },
    onLoad () {
      if (this.itemsLoaded >= this.itemsTotal) return this.loadPending = false
      this.loadPending = true
      this.$client.service('forum-messages').find({
        query: {
          $skip: this.itemsLoaded,
          threadId: this.thread.id,
          $sort: {
            createdAt: -1
          }
        }
      }).then(d => {
        if (d.data.length) {
          this.itemsLoaded+=d.data.length
          this.itemsTotal = d.total
          this.items = this.items.concat(d.data)
          this.loadPending = false
        } else {
          this.loadFinished = true
        }
        this.loadPending = false
      }).catch(e => {
        console.log(e)
        this.loadPending = false
        this.$toast(this.$t('modules.forum.errorFetchThreads'))
      })
    },
    removeThread () {
      let self = this
      this.$dialog.confirm({
        title: self.$t('modules.chat.removeMessTitle'),
        message: self.$t('modules.forum.removeThreadQ'),
        confirmButtonText: self.$t('common.remove'),
        cancelButtonText: self.$t('common.cancel'),
        showCancelButton: true,
        className: 'confirm'
      }).then(() => {
        this.removePending = true
        this.$client.service('forum').remove(this.thread.id).then(() => {
          this.$toast(this.$t('modules.forum.removeThreadOk'))
          this.removePending = false
          return this.$router.go(-1)
        }).catch(e => {
          console.log(e)
          this.removePending = false
          this.$toast(this.$t('modules.forum.removeThreadError'))
        })
      }).catch(() => {
        // 
      })
    },
    reply (post = null) {
      if (!post || !post.author.id) return false
      this.inputed.isReply = true
      this.inputed.replyId = post.author.id
      this.inputed.post = `${post.author.nickname}, `
      this.postMode = true
    },
    showEditPost (post = null) {
      if (!post) return false
      this.inputed.editPostId = post.id
      this.inputed.editPost = post.text
      this.editMode = true
    },
    async editThread () {
      if (this.inputed.threadName.length < 5 || this.inputed.threadName.length > 128) return this.$toast(this.$t('modules.forum.subforumNameLengthError'))
      const checkout = await this.$refs.form.validate().catch(e => {
        console.log(e)
      })
      if (!checkout) {
        this.editThreadPending = true
        this.$client.service('forum').patch(this.thread.id, {
          name: this.inputed.threadName
        }).then(t => {
          this.editThreadPending = false
          this.thread.name = t.name
          this.$store.dispatch('common/SET_APP_TITLE', t.name)
          this.inputed.threadName = t.name
          this.$toast(this.$t('modules.forum.editThreadOk'))
          this.editThreadMode = false
        }).catch(e => {
          this.editThreadPending = false
          console.log(e)
          this.$toast(this.$t('modules.forum.editThreadError'))
        })
      }
    },
    removePost (pid = null) {
      if (!pid) return false
      let self = this
      this.$dialog.confirm({
        title: self.$t('modules.chat.removeMessTitle'),
        message: self.$t('modules.chat.removeMessConf'),
        confirmButtonText: self.$t('common.remove'),
        cancelButtonText: self.$t('common.cancel'),
        showCancelButton: true,
        className: 'confirm'
      }).then(() => {
        this.$client.service('forum-messages').remove(pid).then(() => {
          this.$toast(this.$t('modules.chat.removeMessOk'))
        }).catch(e => {
          console.log(e)
          this.$toast(this.$t('modules.chat.removeMessError'))
        })
      }).catch(() => {
        // 
      })
    },
    async editPost () {
      if (this.inputed.editPost.length < 4 || this.inputed.editPost.length > 2048) return this.$toast(this.$t('modules.forum.postLengthError'))
      const checkout = await this.$refs.form.validate()
      if (!checkout) {
        this.sendPending = true
        this.$client.service('forum-messages').patch(this.inputed.editPostId, {
          text: this.inputed.editPost
        }).then(() => {
          this.$toast(this.$t('modules.forum.editPostOk'))
          this.sendPending = false
          this.flushEditMode()
        }).catch(e => {
          console.log(e)
          this.$toast(this.$t('modules.forum.editPostError'))
          this.sendPending = false
        })
      }
    },
    async sendPost () {
      if (this.inputed.post.length < 4 || this.inputed.post.length > 2048) return this.$toast(this.$t('modules.forum.postLengthError'))
      const checkout = await this.$refs.form.validate().catch(e => {
        console.log(e)
      })
      if (!checkout) {
        this.sendPending = true
        this.$client.service('forum-messages').create({
          threadId: this.thread.id,
          text: this.inputed.post,
          ... this.inputed.isReply ? {
            replyId: this.inputed.replyId
          } : {}
        }).then(() => {
          this.$toast(this.$t('modules.forum.sendPostOk'))
          this.sendPending = false
          this.flushPostMode()
        }).catch(e => {
          console.log(e)
          this.$toast(this.$t('modules.forum.sendPostError'))
          this.sendPending = false
        })
      }
    },
    flushPostMode () {
      this.postMode = false
      this.inputed.post = ''
      this.inputed.postId = null
      this.inputed.isReply = false
      this.inputed.replyId = null
    },
    flushEditMode () {
      this.editMode = false
      this.inputed.editPost = ''
    },
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.forum.title'))
  },
  async mounted () {
    if (!this.threadId) {
      this.$toast(this.$t('modules.forum.errorFetchPosts'))
      return this.$router.go(-1)
    }
    this.thread = await this.$client.service('forum').get(this.threadId).catch(e => {
      console.log(e)
      this.$toast(this.$t('modules.forum.getThreadError'))
      return this.$router.go(-1)
    })
    this.inputed.threadName = this.thread.name
    this.$store.dispatch('common/SET_APP_TITLE', this.thread.name)
    this.items = await this.$client.service('forum-messages').find({
      query: {
        threadId: this.thread.id,
        $sort: {
          createdAt: -1
        }
      }
    }).then(p => {
      this.itemsLoaded = p.data.length
      this.itemsTotal = p.total
      this.headPost = p.data.filter(i => i.isHeadPost)[0]
      return p.data.filter(i => !i.isHeadPost)
    }).catch(e => {
      console.log(e)
      this.$toast(this.$t('modules.forum.errorFetchPosts'))
      return []
    })
    if (!this.headPost) {
      this.headPost = await this.$client.service('forum-messages').find({
        query: {
          $limit: 1,
          isHeadPost: true,
          threadId: this.thread.id
        }
      }).then(p => p.data.length ? p.data[0] : null).catch(e => {
        console.log('Error getting head post', e)
        this.$toast(this.$t('modules.forum.headPostNotFound'))
        return null
      })
    }
    this.pending = false
    this.itemCreated = data => {
      if (+data.threadId === +this.thread.id) {
        this.itemsLoaded++
        this.itemsTotal++
        this.items.unshift(data)
      }
    }
    this.itemPatched = data => {
      if (+data.threadId === +this.thread.id) {
        const index = this.items.findIndex(i => +i.id === +data.id)
        if (index > -1) this.$set(this.items, index, data)
      }
    }
    this.itemRemoved = data => {
      if (+data.threadId === +this.thread.id) {
        const index = this.items.findIndex(i => +i.id === +data.id)
        if (index > -1) {
          this.itemsTotal--
          this.itemsLoaded--
          index ? this.items.splice(index, 1) : this.items.shift()
        }
      }
    }
    this.forumPatched = data => {
      if (+data.id === +this.threadId) this.thread = {
        ...this.thread,
        ...data
      }
    }
    this.forumRemoved = data => {
      if (+data.id === +this.threadId) {
        this.$toast(this.$t('modules.forum.removeThreadOk'))
        return this.$router.go(-1)
      }
    }
    this.$client.service('forum-messages').on('created', this.itemCreated)
    this.$client.service('forum-messages').on('patched', this.itemPatched)
    this.$client.service('forum-messages').on('updated', this.itemPatched)
    this.$client.service('forum-messages').on('removed', this.itemRemoved)
    this.$client.service('forum').on('updated', this.forumPatched)
    this.$client.service('forum').on('patched', this.forumPatched)
    this.$client.service('forum').on('removed', this.forumRemoved)
  },
  beforeDestroy () {
    this.$client.service('forum-messages').removeListener('created', this.itemCreated)
    this.$client.service('forum-messages').removeListener('patched', this.itemPatched)
    this.$client.service('forum-messages').removeListener('updated', this.itemPatched)
    this.$client.service('forum-messages').removeListener('removed', this.itemRemoved)
    this.$client.service('forum').removeListener('updated', this.forumPatched)
    this.$client.service('forum').removeListener('patched', this.forumPatched)
    this.$client.service('forum').removeListener('removed', this.forumRemoved)
    this.itemCreated = this.itemPatched = this.itemRemoved = this.forumPatched = this.forumRemoved = null
  }
}
</script>

<style>
@import url(../../assets/css/forum.css);
</style>