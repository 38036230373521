<template>
  <van-overlay :z-index="11" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline row">
          <div class="ib-dbox-img">
            <img :src="cdnPath(npc.imagePath)" alt="" />
          </div>
          <div class="ib-dbox-name">{{ npc.name }}</div>
          <p v-if="npc.dialogue">
            {{ npc.dialogue.textOnOpen }}
          </p>
          <p v-else>
            {{ `${$t('modules.npc.defaultGreeting')}, ${user.nickname}` }}
          </p>
        </div>
        <van-divider dashed>{{ $t('common.information') }}</van-divider>
        <ul>
          <li>
            {{ $t('modules.npc.locationInfo') }}:
            <van-loading style="display:inline-block" v-if="scenePending"></van-loading>
            <template v-else>
              <span class="underline" v-if="sceneInfo.title">
                {{ sceneInfo.title }}
              </span>
              <span v-else>
                {{ $t('common.unknown') }}
              </span>
            </template>
          </li>
        </ul>
        <div class="centeredInline">
          <van-button type="warning" @click="showOnMapMode=!showOnMapMode" class="mt-05" size="small">{{ $t(`common.${showOnMapMode?'hideMap':'showOnMap'}`) }}</van-button>
        </div>
        <div class="centeredInline mt-05" v-show="showOnMapMode">
          <div class="">
            <map-visualizer-inline :show-loc-numbers="false" :mapId="npc.mapId" :coors="{x:npc.coorsX,y:npc.coorsY}"></map-visualizer-inline>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import MapVisualizerInline from './MapVisualizerInline.vue'
export default {
  name: 'global-component-npc-info',
  components: {
    MapVisualizerInline
  },
  props: {
    npc: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      scenePending: true,
      sceneInfo: null,
      showOnMapMode: false
    }
  },
  computed: {
    showModal () {
      return this.$store.state.components.modals.npcInfo.show
    }
  },
  methods: {
    cancelQuest () {
      this.closeModal()
    },
    closeModal () {
      this.$store.dispatch('components/SET_NPC_INFO', { npc: this.npc, show: false })
      setTimeout(() => {
        this.$emit('rerender')
        this.$store.dispatch('components/SET_NPC_INFO', { npc: null, show: false })
      }, 300)
    }
  },
  async mounted () {
    this.sceneInfo = await this.$client.service('app-settings').get(2).then(m => {
      return {
        mapId: this.npc.mapId,
        title: m.titles[this.npc.mapId]
      }
    }).catch(() => null)
    this.scenePending = false
  }
}
</script>

<style>

</style>