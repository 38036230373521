<template>
  <van-overlay :z-index="200" :show="showModal" @click="showModal=false" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="showModal=false">
      <div @click.stop>
        <div class="centeredInline row">
          <div class="ib-victory-header centeredInline">
            <h1 class="shine">{{ $t('common.victory') }}</h1>
          </div>
          <div class="ib-victory-body">
            <div class="rw" style="z-index:50"></div>
            <div class="lw" style="z-index:50"></div>
            <div class="ib-combat-result" style="z-index:500">
              <div class="centeredInline row" v-if="result.loot">
                <div class="infoTextSmall">
                  {{ $t('modules.combat.itemsLoot') }}
                </div>
                <div class="ib-victory-body-loot-items" @click="$router.push('/rack')">
                  <div :style="{borderImageSource:`url(/assets/UI/images/borders/item-${item.rarity}.png)`}" class="ib-victory-body-loot-item pointer" v-for="item in result.loot" :key="`ib-v-it-${item.id}`">
                    <img :src="cdnPath(item.image)" alt="" />
                  </div>
                </div>
              </div>
              <div class="centeredInline" :style="{marginTop:result.loot?'':'2rem'}" v-if="result.exp">
                {{ `+${result.exp}` }}
                <exp-icon size="1rem"></exp-icon>
              </div>
              <div class="centeredInline" v-if="result.coins">
                {{ `+${result.coins}` }}
                <coin-icon size="1rem"></coin-icon>
              </div>
              <div class="centeredInline" v-if="result.upgradeProfession">
                +1 <svg-icon style="margin-left:.5rem" color="#ffb703" name="hunter" size="1rem"></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: 'victory-component',
  props: {
    result: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    showModal: {
      get () {
        return this.$store.state.components.popups.victory.show
      },
      set (value) {
        this.$store.dispatch('components/SHOW_VICTORY_POPUP', value)
        if (!value) {
          setTimeout(() => {
            this.$emit('rerender')
          }, 500)
        }
      }
    }
  }
}
</script>

<style>

</style>