<template>
  <div class="chat-map-overlay pointer" @click="$store.dispatch('components/SHOW_CHAT_POPUP', true)" style="overflow:hidden">
    <div class="centeredInline" style="width:100%;height:3rem;" v-if="pending">
      <van-loading size="16px"></van-loading>
    </div>
    <div ref="messages" v-else>
      <template v-if="items.length">
        <div class="chat-overlay-message" v-for="message in items" :key="message.id">
            <span style="font-size:85%">
              <template v-if="!message.isSystem">
                [{{$t(`modules.chat.${message.type}Ss`)}}]
                [{{message.createdAt | dateShort}}]
                <svg-icon size=".5rem" :name="classIcon(message.author.class)" class="mr-05"></svg-icon>
                <span class="underline">{{`${message.author.nickname}`}}</span>
                {{`: ${message.text}`}}
              </template>
              <div class="centeredInline" style="font-size:smaller;color:#cecece" v-else>
                {{ message.text }}
              </div>
            </span>
        </div>
      </template>
      <div class="centeredInline" v-else style="width:100%;padding:.75rem 0">
        <div class="smallText">{{$t('modules.chat.noMessages')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'map-chat-overlay-component',
  data () {
    return {
      pending: true,
      messCreated: null,
      messRemoved: null,
      items: [],
      userOnlineHandler: null,
      userOfflineHandler: null
    }
  },
  methods: {
    scrollToStart () {
      this.$refs.messages.scrollTop = 0
    }
  },
  async mounted () {
    this.items = await this.$client.service('chat').find({
      query: {
        $sort: {
          createdAt: -1
        },
        $limit: 3
      }
    }).then(m => m.data).catch(e => {
      console.log('Error getting chat overlay messages', e)
      return []
    })
    this.pending = false
    this.messCreated = data => {
      if (data.type === 'location' && +data.locId === +this.user.mapId) {
        this.items.unshift(data)
        this.$nextTick(() => {
          this.scrollToStart()
        })
      } else {
        this.items.unshift(data)
        this.$nextTick(() => {
          this.scrollToStart()
        })
      }
      if (this.items.length > 20) this.items.pop()
    }
    this.userOnlineHandler = data => {
      if (!data.realEvent) return false
      this.items.unshift({
        text: `${this.userInfo(data).nickname} ${this.$t('modules.chat.userOnlineMessage')}`,
        isSystem: true
      })
    }
    this.userOfflineHandler = data => {
      if (!data.realEvent) return false
      this.items.unshift({
        text: `${this.userInfo(data).nickname} ${this.$t('modules.chat.userOfflineMessage')}`,
        isSystem: true
      })
    }
    this.messRemoved = data => {
      const index = this.items.findIndex(i => +i.id === +data.id)
      if (index > -1) index ? this.items.splice(index, 1) : this.items.shift()
    }
    this.$client.service('chat').on('created', this.messCreated)
    this.$client.service('chat').on('removed', this.messRemoved)
    this.$client.service('signals').on('user-online', this.userOnlineHandler)
    this.$client.service('signals').on('user-offline', this.userOfflineHandler)
  },
  beforeDestroy () {
    this.$client.service('chat').removeListener('created', this.messCreated)
    this.$client.service('chat').removeListener('removed', this.messRemoved)
    this.$client.service('signals').removeListener('user-online', this.userOnlineHandler)
    this.$client.service('signals').removeListener('user-offline', this.userOfflineHandler)
    this.messCreated = this.messRemoved = this.userOnlineHandler = this.userOfflineHandler = null
  }
}
</script>

<style>

</style>