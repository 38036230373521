import client from '../../services/api-client'

const state = {
  entry: null
}
const getters = {}
const mutations = {
  setCombatEntry (state, entry) {
    state.entry = entry
  },
  flushCombat (state) {
    state.entry = null
  }
}
const actions = {
  RESOLVE_COMBAT ({ state, commit }) {
    return new Promise((resolve, reject) => {
      client.service('userdata').find({
        query: {
          getCombat: true
        }
      }).then(combat => {
        commit('setCombatEntry', combat)
        resolve(combat)
      }).catch(e => {
        console.log('Can not resolve combat')
        reject(e.message)
      })
    })
  },
  SET_COMBAT ({ commit }, combat = null) {
    if (!combat) return false
    commit('setCombatEntry', combat)
  },
  UPDATE_COMBAT ({ commit }, combat = null) {
    if (!combat) return false
    commit('setCombatEntry', combat)
  },
  FLUSH_COMBAT ({ commit }) {
    commit('flushCombat')
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}