<template>
  <div>
    <div class="centered" v-if="pending">
      <van-loading></van-loading>
    </div>
    <div v-else class="centeredInline row">
      <h4>{{ $t('modules.controlPanel.initParamsDesc') }}</h4>
        <van-form @submit="onSubmit" ref="form">
          <div v-for="(className, index) in Object.keys(inputed)" :key="index">
            <div class="smallText" style="margin:1rem 0;">
              {{`${index+1}. ${$t(`common.${className}`)} `}}
            </div>
            <van-field
              type="text"
              v-model="inputed[className].stamina"
              :name="`${className}-stamina`"
              :label="$t('common.stamina')"
              :placeholder="$t('common.stamina')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <van-field
              type="text"
              v-model="inputed[className].strength"
              :name="`${className}-strength`"
              :label="$t('common.strength')"
              :placeholder="$t('common.strength')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <van-field
              type="text"
              v-model="inputed[className].intelligence"
              :name="`${className}-intelligence`"
              :label="$t('common.intelligence')"
              :placeholder="$t('common.intelligence')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <van-field
              type="text"
              v-model="inputed[className].dexterity"
              :name="`${className}-dexterity`"
              :label="$t('common.dexterity')"
              :placeholder="$t('common.dexterity')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
          </div>
          <div style="margin: 16px;">
            <van-button :loading="savePending" :disabled="savePending" round plain block class="greetings_btn" native-type="submit">
              {{ $t('common.save') }}
            </van-button>
          </div>
        </van-form>
      </div>
    </div>
</template>

<script>
export default {
  name: 'cpanel-init-params-settings',
  data () {
    return {
      pending: true,
      savePending: false,
      config: null,
      inputed: {
        warrior: {
          intelligence: '',
          strength: '',
          stamina: '',
          dexterity: ''
        }, paladin: {
          intelligence: '',
          strength: '',
          stamina: '',
          dexterity: ''
        }, mage: {
          intelligence: '',
          strength: '',
          stamina: '',
          dexterity: ''
        }, assasin: {
          intelligence: '',
          strength: '',
          stamina: '',
          dexterity: ''
        }, necromancer: {
          intelligence: '',
          strength: '',
          stamina: '',
          dexterity: ''
        }
      },
      configPatched: null
    }
  },
  methods: {
    async onSubmit () {
      const checkout = await this.$refs.form.validate()
      if (!checkout) {
        this.savePending = true
        await this.$client.service('app-settings').patch(3, {
          ...this.inputed
        }).then(() => {
          this.$toast(this.$t('common.saveOk'))
        }).catch(e => {
          console.log('ERROR SAVING DATA', e)
          this.$toast(this.$t('common.saveError'))
        })
        this.savePending = false
      }
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.controlPanel.initParamsTitle'))
  },
  async mounted () {
    this.config = await this.$client.service('app-settings').get(3).then(c => {
      this.inputed = Object.assign(this.inputed, c)
      return c
    }).catch(e => {
      console.log(e)
      this.$toast(this.$t('common.fetchDataError'))
      return null
    })
    this.pending = false
    this.configPatched = data => {
      if (+data.id === 3) {
        delete data.id
        this.inputed = Object.assign(this.inputed, data)
      }
    }
    this.$client.service('app-settings').on('updated', this.configPatched)
    this.$client.service('app-settings').on('patched', this.configPatched)
  },
  beforeDestroy () {
    this.$client.service('app-settings').removeListener('updated', this.configPatched)
    this.$client.service('app-settings').removeListener('patched', this.configPatched)
    this.configPatched = null
  }
}
</script>

<style>

</style>