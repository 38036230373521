// const serverIp = '192.168.100.6'
const serverIp = '89.105.201.23' // live
export const apiServerUrl = `https://${serverIp}:3443` // online
export const cdnServerUrl = `${apiServerUrl}/`
export const defaultLocale = 'ru'
let localeRaw = null
try {
  localeRaw = (window.localStorage.getItem('CURRENT_LOCALE') || navigator.language || navigator.userLanguage).substring(0, 2) || defaultLocale
} catch (error) {}

export const locale = localeRaw

