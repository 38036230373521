<template>
  <div style="max-height:100vh">
    <bars-main v-if="showBars"></bars-main>
    <app-header v-if="showHeader"></app-header>
    <map-component v-if="user" v-show="showMap" :key="`map-key-${mapRenderKey}`" @rerender="mapRenderKey++"></map-component>
    <router-view @rerender="routerRenderKey++" :key="`router-render-${$route.fullPath}-${routerRenderKey}`" @on-authenticated="onAuthenticated" class="page_container"></router-view>
    <app-footer></app-footer>
    <template v-if="user">
      <chat-component></chat-component>
      <menu-global></menu-global>
      <item-info-modal v-if="itemToShow" :item="itemToShow"></item-info-modal>
      <monster-info-modal v-if="monsterToShow" :monster="monsterToShow"></monster-info-modal>
      <npc-info-modal v-if="npcToShow" :npc="npcToShow"></npc-info-modal>
    </template>
    <game-events-handler v-if="user"></game-events-handler>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'
import MapComponent from './components/MapComponent.vue'
import BarsMain from './components/BarsMain.vue'
import GameEventsHandler from './components/GameEventsHandler.vue'
import ChatComponent from './components/Chat.vue'
import MenuGlobal from './components/MenuGlobal.vue'
import ItemInfoModal from './components/ItemInfoModal.vue'
import MonsterInfoModal from './components/MonsterInfoModal.vue'
import NpcInfoModal from './components/NpcInfoModal.vue'
export default {
  name: 'App',
  components: {
    BarsMain,
    AppHeader,
    MapComponent,
    AppFooter,
    GameEventsHandler,
    ChatComponent,
    MenuGlobal,
    ItemInfoModal,
    MonsterInfoModal,
    NpcInfoModal
  },
  data () {
    return {
      mapRenderKey: 0,
      routerRenderKey: 0,
      userDataPatched: null,
      settingsPatched: null,
      combatCreated: null,
      combatPatched: null,
      combatRemoved: null,
      rackPatched: null,
      uitemsCreated: null,
      uitemsPatched: null,
      uitemsRemoved: null,
      uactsPatched: null,
      excludedUrls: [
        '/control-panel',
        '/character',
        '/rack',
        '/settings',
        '/forum',
        '/quests'
      ]
    }
  },
  computed: {
    showHeader () {
      if (this.$route.path === '/') {
        return false
      } else return this.user || this.$route.fullPath === '/start'
    },
    showMap () {
      if (!this.user) return false
      return !new RegExp(this.excludedUrls.join("|")).test(this.$route.path)
    },
    showBars () {
      if (!this.user) return false
      return !new RegExp(this.excludedUrls.join("|")).test(this.$route.path)
    },
    ...mapState({
      appTitle: state => state.common.appTitle,
      itemToShow: state => state.components.modals.itemInfo.item,
      monsterToShow: state => state.components.modals.monsterInfo.monster,
      npcToShow: state => state.components.modals.npcInfo.npc
    })
  },
  methods: {
    optimizeViewportUnits () {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    async onAuthenticated () {
      this.$worker.postMessage({cmd:'init', loadRack: this.user.rackId > 0, rid: this.user.rackId })
      if (this.user) {
        if (this.user.settingsId) {
          this.$store.dispatch('settings/RESOLVE_ENTRY', this.user.settingsId).catch(() => {
            console.log('error resolving settings entry')
          })
        }
        if (this.user.actionsId) {
          this.$store.dispatch('uactions/RESOLVE_ENTRY', this.user.actionsId).catch(() => {
            console.log('error resolving uactions entry')
          })
        }
      }
      this.userDataPatched = data => {
        // console.log(`userdata patched, data.id: ${data.id}, user.id: ${this.user.id}`, data)
        if (this.user && data.id && +data.id === +this.user.id) {
          // console.log(`user patched, coorsX: ${data.coorsX}, coorsY: ${data.coorsY}`)
          this.$store.dispatch('auth/UPDATE_USER_SIMPLE', data)
        }
      }
      this.settingsPatched = data => {
        if (+data.userId === +this.user.id) {
          this.$store.dispatch('settings/SET_ENTRY', data)
        }
      }
      this.combatCreated = data => {
        if (+data.userId === +this.user.id) {
          this.$store.dispatch('combat/SET_COMBAT', data)
        }
      }
      this.combatPatched = data => {
        if (+data.userId === +this.user.id) {
          this.$store.dispatch('combat/UPDATE_COMBAT', data)
        }
      }
      this.combatRemoved = data => {
        if (+data.userId === +this.user.id) {
          this.$store.dispatch('combat/FLUSH_COMBAT')
        }
      }
      this.uactsPatched = data => {
        this.$store.dispatch('uactions/UPDATE_ENTRY', data)
      }
      this.rackPatched = data => {
        if (+data.id !== +this.user.rackId || +data.size === +this.$store.state.rack.size) return false
        this.$store.dispatch('rack/SET_SIZE', data.size)
      }
      this.uitemsCreated = data => {
        if (+data.rackId !== +this.user.rackId) return false
        this.$store.dispatch('rack/ADD_ITEM', data)
      }
      this.uitemsPatched = data => {
        if (+data.rackId !== +this.user.rackId) return false
        this.$store.dispatch('rack/SET_ITEM', data)
      }
      this.uitemsRemoved = data => {
        if (+data.rackId !== +this.user.rackId) return false
        this.$store.dispatch('rack/REMOVE_ITEM', data.id)
      }
      this.$client.service('userdata').on('updated', this.userDataPatched)
      this.$client.service('userdata').on('patched', this.userDataPatched)
      this.$client.service('settings').on('patched', this.settingsPatched)
      this.$client.service('settings').on('updated', this.settingsPatched)
      this.$client.service('combat').on('created', this.combatCreated)
      this.$client.service('combat').on('updated', this.combatPatched)
      this.$client.service('combat').on('patched', this.combatPatched)
      this.$client.service('combat').on('removed', this.combatRemoved)
      this.$client.service('combat').on('ended', this.combatRemoved)
      this.$client.service('combat').on('started', this.combatCreated)
      this.$client.service('uactions').on('updated', this.uactsPatched)
      this.$client.service('uactions').on('patched', this.uactsPatched)
      this.$client.service('rack').on('patched', this.rackPatched)
      this.$client.service('uitems').on('created', this.uitemsCreated)
      this.$client.service('uitems').on('updated', this.uitemsPatched)
      this.$client.service('uitems').on('patched', this.uitemsPatched)
      this.$client.service('uitems').on('removed', this.uitemsRemoved)
    }
  },
  watch: {
    'appTitle' () {
      document.title = this.appTitle
    },
    'showMap' () {
      this.$store.dispatch('common/SET_MAP_VISIBILITY', this.showMap)
    }
  },
  mounted () {
    this.optimizeViewportUnits()
    document.title = this.appTitle
    this.$store.dispatch('auth/auth').then(() => {
      this.onAuthenticated()
    }).catch(() => {
      if (this.$route.path !== '/welcome') this.$router.replace('/welcome')
    })
    window.addEventListener('resize', () => {
      this.optimizeViewportUnits()
    })
  }
}
</script>
<style>
  @import url(./assets/css/common.css);
</style>
