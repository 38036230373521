<template>
  <van-popup
    v-model="showPopup"
    position="bottom"
    style="height:40vh"
    class="ib-dbox"
    :show-overlay="false"
    :close-on-click-overlay="false"
    >
    <div class="ib-dbox-l centeredInline row">
      <div class="ib-dbox-img">
        <img :src="cdnPath(npc.imagePath)" alt="" />
      </div>
      <div class="ib-dbox-name">
        {{ npc.name }}
      </div>
    </div>
    <div class="ib-dbox-r">
      <div class="ib-dbox-body" v-if="npc.dialogue">
        <div v-if="!showQuestMode">
          <template v-if="!currPhraseId.length">
            {{ parsedDialogue.mainPhrase }}
          </template>
          <div class="ml-05" v-else>
            {{ parsedDialogue.phrases[currPhraseId].text }}
          </div>
          <div v-if="showQuestsList" class="mx-05">
            <div class="centeredInline infoTextSmall mt-1" v-if="!npc.dialogue.quests.length">
              {{ $t('modules.quests.noAvailable') }}
            </div>
            <div style="margin-top:2rem" v-else>
              <div @click="showQuestInfo(quest)" class="mr-05 ib-dbox-variant" v-for="(quest, index) in npc.dialogue.quests" :key="`a-q-${quest.id}-${index}`">
                <img :src="`/assets/icons/small/quest_${isQuestDone(quest)?'done':'available'}.png`" alt="" style="width:1.5rem;height:1.5rem;" />
                {{ `${quest.name} (${quest.level} ${$t('common.levelShort')})` }}
              </div>
            </div>
          </div>
        </div>
        <div style="box-sizing:content-box" v-else-if="showQuestMode">
          <div class="infoText" style="margin:-2rem 0 0 1rem">{{ currQuest.name }}</div>
          <p class="ml-05">
            {{ currQuest.description }}
          </p>
          <van-divider dashed>{{ $t('modules.quests.target') }}</van-divider>
          <div class="ml-05">
            <svg-icon size="1rem" :name="currQuest.targetType==='monster'?'sword-1':'bag'"></svg-icon>
            {{ $t(`modules.quests.${currQuest.targetType==='item'?'needGatherItems':'needKill'}`) }}:
            <span v-for="(qi, qindex) in currQuest.targetData" :key="`q-td-t-${qi.id}-${qindex}`"><span :class="`rarity-${qi.rarity}`">{{ qi.name }}</span>{{ `${qi.quantity>1?` x${qi.quantity}`:''}` }}{{`${currQuest.targetData.length>qindex+1?' ,':''}`}}</span>
          </div>
          <van-divider dashed>{{ $t('modules.quests.reward') }}</van-divider>
          <ul class="ml-05 mb-05">
            <li>
              {{ `${$t('modules.parameters.exp')}: +${currQuest.rewardExp}` }} <exp-icon size="1rem" style="margin:0"></exp-icon>
            </li>
            <li>
              {{ `${$t('common.coins')}: +${currQuest.rewardGold}` }} <coin-icon size="1rem" style="margin:0"></coin-icon>
            </li>
            <li v-if="currQuest.rewardItems.length">
              {{ `${$t('modules.quests.items')}: ` }}
              <span v-for="(ri, rindex) in currQuest.rewardItems" :key="`q-td-t-${ri.id}-${rindex}`"><span :class="`rarity-${ri.rarity}`">{{ ri.name }}</span>{{ `${ri.quantity>1?` x${ri.quantity}`:''}` }}{{`${currQuest.rewardItems.length>rindex+1?' ,':''}`}}</span>
            </li>
          </ul>
          <div class="centeredInline">
            <template v-if="isQuestDone(currQuest)">
              <van-button size="small" :loading="questCompletingId===currQuest.id" class="ib-dbox-variant" plain @click="completeQuest(currQuest.id)">
                {{ $t('modules.quests.completeQuest') }}
              </van-button>
            </template>
            <template v-else>
              <van-button size="small" :loading="questAcceptingId===currQuest.id" class="ib-dbox-variant" plain @click="acceptQuest(currQuest.id)">
                {{ $t('modules.quests.acceptQuest') }}
              </van-button>
            </template>
          </div>
        </div>
        <div class="centeredInline row" style="margin-top:2rem">
          <div @click="clickVariant(variant)" class="mr-05 ib-dbox-variant" v-for="(variant, index) in currPhraseId.length ? parsedDialogue.phrases[currPhraseId].phrases : parsedDialogue.initPhrases" :key="`d-i-phr-${index}`">
            {{ parsedDialogue.phrases[variant].btnText }}
          </div>
          <div v-if="showExit" class="ib-dbox-variant" @click="showPopup=false">
            {{ $t('modules.npc.defaultCloseDialogue') }}
          </div>
        </div>
      </div>
      <template v-else>
        {{ `${$t('modules.npc.defaultGreeting')}${user.nickname}` }}
      </template>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'npc-dialogue-component',
  props: {
    npc: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      currPhraseId: '',
      showQuestMode: false,
      currQuest: null,
      questAcceptingId: null,
      questCompletingId: null,
      parsedDialogue: {
        mainPhrase: '',
        initPhrases: '',
        phrases: {}
      }
    }
  },
  computed: {
    showExit () {
      if (!this.npc.dialogue || !this.npc.dialogue.id) return true
      return this.parsedDialogue.phrases[this.currPhraseId] && this.parsedDialogue.phrases[this.currPhraseId].function && this.parsedDialogue.phrases[this.currPhraseId].function==='accept-quest'
    },
    showQuestsList () {
      if (!this.npc.dialogue || !this.npc.dialogue.id) return false
      return this.currPhraseId.length && this.parsedDialogue.phrases[this.currPhraseId] && this.parsedDialogue.phrases[this.currPhraseId].function && this.parsedDialogue.phrases[this.currPhraseId].function==='accept-quest'
    },
    showPopup: {
      get () {
        return this.$store.state.components.popups.npcDialogue.show
      },
      set (val = false) {
        this.$store.dispatch('components/SHOW_NPC_DIALOGUE_POPUP', val)
        setTimeout(() => {
          this.$emit('rerender')
        }, 500)
      }
    }
  },
  methods: {
    completeQuest (id = null) {
      if (!id || isNaN(id)) return false
      this.questCompletingId = id
      this.$client.service('acts').create({
        completeQuest: true,
        questId: id
      }).then(() => {
        this.questCompletingId = null
        this.$toast(this.$t('modules.quests.completeQuestOk'))
        this.showQuestInfo = false
        this.$nextTick(() => {
          this.currQuest = null
        })
      }).catch(() => {
        this.questCompletingId = null
        this.$toast(this.$t('modules.quests.completeQuestError'))
      })
    },
    acceptQuest (id = null) {
      if (!id) return false
      this.questAcceptingId = id
      this.$client.service('acts').create({
        acceptQuest: true,
        questId: id,
        acceptedFrom: this.npc.id
      }).then(() => {
        this.questAcceptingId = null
        this.$toast(this.$t('modules.quests.acceptQuestOk'))
        this.showPopup = false
      }).catch(() => {
        this.questAcceptingId = null
        this.$toast(this.$t('modules.quests.acceptQuestErr'))
      })
    },
    showQuestInfo (quest = null) {
      if (!quest) return false
      this.currQuest = quest
      this.showQuestMode = true
    },
    clickVariant (variant = null) {
      if (!variant) return false
      let clickedVariant = this.parsedDialogue.phrases[variant]
      if (clickedVariant.function.length) {
        if (clickedVariant.function === 'close-dialogue') return this.showPopup = false
      }
      this.currPhraseId = variant
    },
    async parseDialogue () {
      if (!this.npc.dialogue) return false
      this.parsedDialogue.mainPhrase = this.npc.dialogue.textOnOpen
      this.parsedDialogue.initPhrases = this.npc.dialogue.phrasesOnOpen
      await Promise.all(this.npc.dialogue.additionPhrases.map(phrase => {
        this.parsedDialogue.phrases[phrase.id] = phrase
      }))
      this.processAvailableQuests()
    },
    processAvailableQuests () {
      if (!this.npc.dialogue.quests.length) return false
      this.npc.dialogue.quests = this.npc.dialogue.quests.filter(q => {
        return q.id && (this.isQuestAvailable(q.id) || this.isQuestDone(q))
      })
    }
  },
  mounted () {
    this.parseDialogue()
  }
}
</script>

<style>

</style>