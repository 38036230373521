<template>
  <div class="header">
    <span>
      <div class="van-ellipsis" style="font-family: 'Playfair Display SC', serif !important">
        {{ appTitle }}
      </div>
    </span>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'app-header-component',
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      appTitle: state => state.common.appTitle
    })
  },
  methods: {
  },
  created () {

  },
  mounted () {
    
  }
}
</script>

<style>

</style>