<template>
  <div>
    <div class="sticky-btn center" v-if="user.savedAccount">
      <van-button type="primary" @click="$store.dispatch('forum/SHOW_CREATE_SUBFORUM_MODAL', true)" size="small" round v-if="canCreateSubforum">
        <div class="centeredInline">
          <fa-icon icon="plus-circle" style="margin-right:.25rem"></fa-icon>
          {{ $t('modules.forum.createSubforum') }}
        </div>
      </van-button>
      <van-button style="margin-left:.5rem" type="warning" @click="$store.dispatch('forum/SHOW_EDIT_SUBFORUM_MODAL', true)" size="small" round v-if="canEditSubforum">
        <div class="centeredInline">
          <fa-icon icon="edit" style="margin-right:.25rem"></fa-icon>
          {{ $t('common.edit') }}
        </div>
      </van-button>
      <van-button @click="$store.dispatch('forum/SHOW_CREATE_THREAD_MODAL', true)" style="margin:0 .25rem 0 .25rem" type="primary" size="small" round v-if="canCreateThread">
        <div class="centeredInline">
          <fa-icon icon="plus-circle" style="margin-right:.25rem"></fa-icon>
          {{ $t('modules.forum.createThread') }}
        </div>
      </van-button>
    </div>
    <div class="centered" v-if="pending">
      <van-loading></van-loading>
    </div>
    <div v-else>
      <div v-if="items.length">
        <van-cell @click="redirect(item)" center clickable v-for="item in items" :key="item.id">
          <template #icon>
            <div class="cell-icon">
              <svg-icon :name="item.type==='subforum'?'talk':'pencil'"></svg-icon>
            </div>
          </template>
          <template #title>
            <van-tag v-if="item.type==='thread'" style="margin-right:.5rem">{{ item.createdAt | dateShort }}</van-tag>
            <fa-icon icon="lock" v-if="item.forAdmins" style="font-size:smaller;margin-right:.25rem"></fa-icon>
            <span style="color:#cecece" v-if="item.hasOwnProperty('author')">{{ `${item.author.nickname}: ` }}</span>
            {{`${item.name}`}}
          </template>
          <template #label>
            <template v-if="item.type==='subforum'">
              {{ item.description || $t('modules.forum.noDescription') }}
            </template>
            <div v-else-if="item.lastPost != null" class="van-cell-text">
              {{`${$t('modules.forum.lastPost')}: ${item.lastPost.text || $t('modules.forum.threadEmpty')}` }}
            </div>
          </template>
          <template #extra v-if="item.hasOwnProperty('childrenCount')">
            <div class="infoTextSmall">
              {{ item.childrenCount }}
            </div>
          </template>
        </van-cell>
        <div class="load-more-manual centeredInline">
          <van-button @click.stop="onLoad" type="primary" round size="small" :loading="loadPending" :disabled="loadPending" v-if="itemsLoaded < itemsTotal">
            <div class="centeredInline">
              <fa-icon icon="sync-alt" style="margin-right:.5rem"></fa-icon>
              {{$t('common.loadMore')}}
            </div>
          </van-button>
        </div>
      </div>
      <div class="centered" v-else>
        <div class="infoText">{{ $t('modules.forum.subforumEmpty') }}</div>
      </div>
    </div>
    <create-subforum-modal :key="`c-sf-${modalRenderKey}`" :parentId="current?current.id:0" v-if="user.role!=='usr'"></create-subforum-modal>
    <edit-subforum-modal :key="`e-sf-${modalRenderKey}`" :subforum="current" v-if="current"></edit-subforum-modal>
    <create-thread-modal :parent="current" :key="`c-thread-${modalRenderKey}`" :parentId="current?current.id:0" v-if="user.savedAccount && current"></create-thread-modal>
  </div>
</template>

<script>
import CreateSubforumModal from './modals/CreateSubforum.vue'
import EditSubforumModal from './modals/EditSubforum.vue'
import CreateThreadModal from './modals/CreateThread.vue'
export default {
  name: 'forum-subforum',
  components: {
    CreateSubforumModal,
    CreateThreadModal,
    EditSubforumModal
  },
  data () {
    return {
      modalRenderKey: 0,
      pending: true,
      current: null,
      items: [],
      itemsLoaded: 0,
      itemsTotal: 0,
      loadPending: false,
      loadFinished: false,
      itemCreated: null,
      itemPatched: null,
      itemRemoved: null
    }
  },
  computed: {
    subforumId () {
      return this.$route.params.subforumId || null
    },
    canCreateSubforum () {
      return this.user.role !== 'usr'
    },
    canEditSubforum () {
      return this.user.role !== 'usr'
    },
    canCreateThread () {
      if (!this.user.savedAccount) return false
      if (this.current) {
        if (this.current.canCreateThreads) {
          return this.current.forAdmins ? this.user.role !== 'usr' : true
        } else return false
      } else return false
    }
  },
  methods: {
    onLoad () {
      if (this.itemsLoaded >= this.itemsTotal) return this.loadPending = false
      this.loadPending = true
      this.$client.service('forum').find({
        query: {
          $skip: this.itemsLoaded,
          parentId: this.current.id,
          $sort: {
            type: 1,
            createdAt: -1
          }
        }
      }).then(d => {
        if (d.data.length) {
          this.itemsLoaded+=d.data.length
          this.itemsTotal = d.total
          this.items = this.items.concat(d.data)
          this.loadPending = false
        } else {
          this.loadFinished = true
        }
        this.loadPending = false
      }).catch(e => {
        console.log(e)
        this.loadPending = false
        this.$toast(this.$t('modules.chat.fetchMessagesError'))
      })
    },
    editSubforum () {

    },
    redirect (item = null) {
      if (!item) return false
      if (!item.forAdmins) {
        return this.$router.push(`/forum/${item.type}/${item.id}`)
      } else {
        // return this.user.role !== 'usr' ? this.$router.push(`/forum/${item.type}/${item.id}`) : false
        return this.$router.push(`/forum/${item.type}/${item.id}`)
      }
    },
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.forum.title'))
  },
  async mounted () {
    this.current = await this.$client.service('forum').get(this.subforumId).catch(e => {
      console.log(e)
      this.$toast(this.$t('modules.forum.subforumNotFound'))
      return this.$router.go(-1)
    })
    this.$store.dispatch('common/SET_APP_TITLE', this.current.name)
    this.items = await this.$client.service('forum').find({
      query: {
        $sort: {
          type: 1,
          createdAt: -1
        },
        parentId: this.current.id
      }
    }).then(d => {
      this.itemsLoaded = d.data.length
      this.itemsTotal = d.total
      return d.data
    }).catch(e => {
      console.log(e)
      this.$toast(this.$t('modules.forum.errorFetchThreads'))
      return []
    })
    this.pending = false
    this.itemCreated = data => {
      if (+data.parentId === +this.current.id) data.type === 'thread' ? this.items.push(data) : this.items.unshift(data)
    }
    this.itemPatched = data => {
      if (+data.parentId === +this.current.id) {
        const index = this.items.findIndex(s => +s.id === +data.id)
        if (index > -1) this.$set(this.items, index, data)
      }
    }
    this.itemRemoved = data => {
      if (+data.parentId === +this.current.id) {
        const index = this.items.findIndex(s => +s.id === +data.id)
        if (index > -1) index ? this.items.splice(index, 1) : this.items.shift()
      }
    }
    this.$client.service('forum').on('created', this.itemCreated)
    this.$client.service('forum').on('updated', this.itemPatched)
    this.$client.service('forum').on('patched', this.itemPatched)
    this.$client.service('forum').on('removed', this.itemRemoved)
  },
  beforeDestroy () {
    this.$client.service('forum').removeListener('created', this.itemCreated)
    this.$client.service('forum').removeListener('updated', this.itemPatched)
    this.$client.service('forum').removeListener('patched', this.itemPatched)
    this.$client.service('forum').removeListener('removed', this.itemRemoved)
    this.itemCreated = this.itemPatched = this.itemRemoved = null
  }
}
</script>

<style>

</style>