<template>
  <van-overlay :z-index="200" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline row">
          <div class="modalTitle van-ellipsis">
            {{ `${$t('modules.items.addItemTitle')}` }}
            <br>
            <div class="tiny-text">{{ $t('modules.items.names')[type] }}</div>
          </div>
          <van-form style="width:100%" @submit="onSubmit" ref="form">
            <div class="infoText">{{ $t('modules.items.itemImage') }}</div>
            <div class="centeredInline">
              <div class="image-upload pointer" :key="`preview-${previewKey}`" id="__imgPreview">
                <fa-icon id="__selectImageBtn" icon="image" style="width:5rem;height:5rem;opacity:.8;" v-if="!uploading && !inputed.image && !file"></fa-icon>
                <van-loading style="position:absolute;z-index:50" v-if="!inputed.image && uploading"></van-loading>
                <fa-icon style="position:absolute;z-index:50;color:lightgreen;font-size:3rem;opacity:.9" v-if="inputed.image" icon="check-circle"></fa-icon>
              </div>
            </div>
            <div class="infoText">{{ $t('modules.items.itemName') }}</div>
            <van-field
              type="text"
              v-model.trim="inputed.name"
              name="name"
              :placeholder="$t('modules.items.itemName')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <div class="infoText">{{ $t('common.description') }}</div>
            <van-field
              type="textarea"
              v-model.trim="inputed.description"
              name="description"
              :placeholder="$t('common.description')"
              maxlength="512"
              show-word-limit
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <template v-if="type === 'wear'">
              <div class="infoText">{{ $t('modules.items.incParams') }}</div>
              <van-field
                type="number"
                v-model.number="inputed.incStam"
                name="incStam"
                :label="$t('common.stamina')"
                :placeholder="$t('common.stamina')"
                :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
              <van-field
                type="number"
                v-model.number="inputed.incStr"
                name="incStr"
                :label="$t('common.strength')"
                :placeholder="$t('common.strength')"
                :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
              <van-field
                type="number"
                v-model.number="inputed.incInt"
                name="incInt"
                :label="$t('common.intelligence')"
                :placeholder="$t('common.intelligence')"
                :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
              <van-field
                type="number"
                v-model.number="inputed.incDex"
                name="incDex"
                :label="$t('common.dexterity')"
                :placeholder="$t('common.dexterity')"
                :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
            </template>
            <van-radio-group v-model="inputed.rarity">
              <van-cell-group :title="$t('common.rarity')">
                <van-cell :style="{borderLeft:`3px solid ${$t('common.rarityColors')[rarity]}`}" v-for="rarity in Object.keys($t('common.rarityNames'))" :key="`rarity-${rarity}`" :title="$t('common.rarityNames')[rarity]" clickable @click="inputed.rarity = rarity">
                  <template #right-icon>
                    <van-radio :name="rarity">
                      <template #icon="props">
                        <template v-if="props.checked">
                          <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                        </template>
                      </template>
                    </van-radio>
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
            <template v-if="type === 'wear'">
              <van-radio-group v-model="inputed.equipType">
                <van-cell-group :title="$t('modules.items.equipSlot')">
                  <van-cell v-for="type in Object.keys($t('modules.items.equipTypes'))" :key="`eq-type-${type}`" :title="$t('modules.items.equipTypes')[type].name" clickable @click="inputed.equipType = type">
                    <template #icon>
                      <div class="cell-icon">
                        <svg-icon :name="$t('modules.items.equipTypes')[type].icon" size="1rem"></svg-icon>
                      </div>
                    </template>
                    <template #right-icon>
                      <van-radio :name="type">
                        <template #icon="props">
                          <template v-if="props.checked">
                            <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                          </template>
                        </template>
                      </van-radio>
                    </template>
                  </van-cell>
                </van-cell-group>
              </van-radio-group>
              <div class="infoText">{{ $t('modules.items.equipLevel') }}</div>
              <van-field
                type="number"
                v-model.number="inputed.equipLevel"
                name="equipLevel"
                :placeholder="$t('modules.items.equipLevel')"
                :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
            <div class="infoText">{{ $t('modules.items.defenceParams') }}</div>
            <van-field
              type="number"
              v-model.number="inputed.magDef"
              name="magDef"
              :label="$t('modules.parameters.magDef')"
              :placeholder="$t('modules.parameters.magDef')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <van-field
              type="number"
              v-model.number="inputed.physDef"
              name="physDef"
              :label="$t('modules.parameters.physDef')"
              :placeholder="$t('modules.parameters.physDef')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            </template>
            <template v-if="type === 'resource'">
              <van-radio-group v-model="inputed.professionType">
                <van-cell-group :title="$t('modules.items.professionType')">
                  <van-cell v-for="profession in Object.keys($t('modules.items.itemProfessions'))" :key="`profession-${profession}`" :title="$t('modules.items.itemProfessions')[profession].name" clickable @click="inputed.professionType = profession">
                    <template #icon>
                      <div class="cell-icon">
                        <svg-icon :name="$t('modules.items.itemProfessions')[profession].icon" size="1rem"></svg-icon>
                      </div>
                    </template>
                    <template #right-icon>
                      <van-radio :name="profession">
                        <template #icon="props">
                          <template v-if="props.checked">
                            <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                          </template>
                        </template>
                      </van-radio>
                    </template>
                  </van-cell>
                </van-cell-group>
              </van-radio-group>
            </template>
            <template v-if="type === 'resource'">
              <div class="infoText">{{ $t('modules.items.professionLevel') }}</div>
              <van-field
                type="number"
                v-model.number="inputed.professionLevel"
                name="professionLevel"
                :placeholder="$t('modules.items.professionLevel')"
                :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
            </template>
            <template v-if="type === 'wear'">
              <div class="infoText">{{ $t('modules.items.setId') }}</div>
              <van-field
                type="number"
                v-model.number="inputed.setId"
                name="setId"
                :placeholder="$t('modules.items.setId')"
                :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
              <van-checkbox-group v-model="inputed.equipClass">
                <van-cell-group :title="$t('modules.items.equipClass')">
                  <van-cell v-for="(className, index) in Object.keys($t('modules.items.playerClasses'))" clickable :key="`player-class-${index}`" :title="$t('modules.items.playerClasses')[className].name" @click="toggleClass(index)">
                    <template #icon>
                      <div class="cell-icon">
                        <svg-icon :name="$t('modules.items.playerClasses')[className].icon" size="1rem"></svg-icon>
                      </div>
                    </template>
                    <template #right-icon>
                      <van-checkbox :name="className" ref="clcheckbox">
                        <template #icon="props">
                          <template v-if="props.checked">
                            <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                          </template>
                        </template>
                      </van-checkbox>
                    </template>
                  </van-cell>
                </van-cell-group>
              </van-checkbox-group>
            </template>
            <template v-if="type!=='wear'">
              <br>
              <van-cell clickable @click="inputed.canStack=inputed.canStack?false:true" :title="$t('modules.controlPanel.itemCanStack')">
                <template #extra>
                  <van-checkbox v-model="inputed.canStack">
                    <template #icon="props">
                      <template v-if="props.checked">
                        <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                      </template>
                    </template>
                  </van-checkbox>
                </template>
              </van-cell>
            </template>
            <div class="infoText">{{ $t('modules.items.sellPrice') }}</div>
            <van-field
              type="number"
              v-model.number="inputed.sellPrice"
              name="sellPrice"
              :placeholder="$t('modules.items.sellPrice')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <div style="margin-top:1rem;">
              <van-button :loading="savePending" :disabled="savePending" round plain block class="greetings_btn" native-type="submit">
                {{ $t('common.add') }}
              </van-button>
            </div>
          </van-form>
        </div>
        <file-upload
          :clear-preview="false"
          :key="`uii-${uploadingCmpKey}`"
          filetype = "item-image"
          preview="__imgPreview"
          trigger = "__selectImageBtn"
          @on-selected = "onSelected"
          @on-upload = "onUploading"
          @on-progress = "onProgress"
          @on-success = "onSuccess"
          @on-error = "onError"></file-upload>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import FileUpload from '../../../../components/FileUpload.vue'
export default {
  name: 'control-panel-add-item',
  components: {
    FileUpload
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      savePending: false,
      file: null,
      uploading: false,
      uploadProgress: 0,
      uploadingCmpKey: 0,
      previewKey: 0,
      inputed: {
        image: '',
        name: '',
        description: '',
        equipLevel: '1',
        equipType: 'lhand',
        rarity: 'common',
        professionType: 'miner',
        professionLevel: '1',
        setId: '',
        incStam: '0',
        incInt: '0',
        incStr: '0',
        incDex: '0',
        magDef: '0',
        physDef: '0',
        equipClass: [],
        sellPrice: '5',
        canStack: false
      }
    }
  },
  computed: {
    showModal () {
      return this.$store.state.cpanel.modals.addItem.show
    }
  },
  methods: {
    toggleClass (index) {
      this.$refs.clcheckbox[index].toggle()
    },
    onSelected (file) {
      this.file = file
    },
    onUploading () {
      this.uploading = true
    },
    onProgress (perc = 0) {
      this.uploadProgress = perc
    },
    onSuccess (result = null) {
      this.inputed.image = result.data.path
      this.uploading = false
      this.uploadProgress = 0
      this.file = null
    },
    onError (err) {
      this.$toast(this.$t('modules.controlPanel.uploadNpcImageError'))
      console.log(err)
      this.uploading = false
      this.uploadProgress = 0
      this.file = null
      this.previewKey++
    },
    closeModal () {
      this.$store.dispatch('cpanel/SHOW_ADD_ITEM_MODAL', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    },
    async onSubmit () {
      const checkout = await this.$refs.form.validate()
      if (!this.inputed.sellPrice || +this.inputed.sellPrice < 1) return this.$toast(this.$t('modules.controlPanel.sellPriceError'))
      if (!this.inputed.magDef || +this.inputed.magDef < 0) return this.$toast(this.$t('modules.controlPanel.itemMagDefError'))
      if (!this.inputed.physDef || +this.inputed.physDef < 0) return this.$toast(this.$t('modules.controlPanel.itemPhysDefError'))
      if (this.inputed.name.length < 3 || this.inputed.name.lenght > 76) return this.$toast(this.$t('modules.controlPanel.itemNameLenghtError'))
      if (this.inputed.description.length < 10 || this.inputed.description.lenght > 512) return this.$toast(this.$t('modules.controlPanel.itemDescLenghtError'))
      if (this.type === 'wear' && this.inputed.equipClass.length < 1) return this.$toast(this.$t('modules.controlPanel.itemClassLengthError'))
      if (this.type === 'wear' && this.inputed.equipLevel < 1 || this.inputed.equipLevel > 100) return this.$toast(this.$t('modules.controlPanel.itemEquipLevelError'))
      if (this.type === 'wear' && this.inputed.incStam < 0 || this.inputed.incStr < 0 || this.inputed.incInt < 0 || this.inputed.incDex < 0) return this.$toast(this.$t('modules.controlPanel.itemIncParamsError'))
      if (this.type === 'resource' && this.inputed.professionLevel < 1 || this.inputed.professionLevel > 100) return this.$toast(this.$t('modules.controlPanel.itemProfessionLevelError'))
      if (!checkout) {
        this.savePending = true
        await this.$client.service('items').create({
          type: this.type,
          name: this.inputed.name,
          description: this.inputed.description,
          image: this.inputed.image,
          rarity: this.inputed.rarity,
          sellPrice: this.inputed.sellPrice,
          canStack: this.inputed.canStack,
          ...this.type === 'wear' ? {
            setId: this.inputed.setId,
            equipType: this.inputed.equipType,
            equipLevel: this.inputed.equipLevel,
            equipClass: this.inputed.equipClass,
            incStam: this.inputed.incStam,
            incStr: this.inputed.incStr,
            incInt: this.inputed.incInt,
            incDex: this.inputed.incDex,
            magDef: this.inputed.magDef,
            physDef: this.inputed.physDef
          } : {},
          ...this.type === 'resource' ? {
            professionType: this.inputed.professionType,
            professionLevel: this.inputed.professionLevel
          } : {}
        }).then(() => {
          this.$toast(this.$t('modules.items.addItemOk'))
          this.closeModal()
        }).catch(e => {
          console.log(e)
          this.$toast(this.$t('modules.items.addItemError'))
        })
        this.savePending = false
      }
    }
  },
  mounted () {
  }
}
</script>

<style>

</style>