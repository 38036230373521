<template>
  <div>
    <div class="centeredInline row">
      <van-form style="width:100%" @submit="onSubmit" ref="form">
        <van-radio-group v-model="inputed.showMinimap">
          <van-cell-group :title="$t('modules.settings.showMinimap')">
            <van-cell v-for="mode in Object.keys($t('modules.settings.showMinimapTypes'))" :key="`shw-minimap-${mode}`" :title="$t('modules.settings.showMinimapTypes')[mode]" clickable @click="inputed.showMinimap=mode">
              <template #right-icon>
                <van-radio :name="mode">
                  <template #icon="props">
                    <template v-if="props.checked">
                      <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                    </template>
                  </template>
                </van-radio>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-radio-group v-model="inputed.gameFps">
          <van-cell-group :title="$t('modules.settings.gameFps')">
            <van-cell v-for="fps in Object.keys($t('modules.settings.gameFpsTypes'))" :key="`fps-${fps}`" :title="$t('modules.settings.gameFpsTypes')[fps]" clickable @click="inputed.gameFps=fps">
              <template #right-icon>
                <van-radio :name="fps">
                  <template #icon="props">
                    <template v-if="props.checked">
                      <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                    </template>
                  </template>
                </van-radio>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <div style="margin-top:.75rem" class="centeredInline">
          <van-button style="width:50%" :loading="savePending" :disabled="savePending" round plain block class="greetings_btn" native-type="submit">
            {{ $t('common.save') }}
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'settings-index',
  data () {
    return {
      savePending: false,
      inputed: {
        showMinimap: 'show',
        gameFps: '60'
      }
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.entry
    })
  },
  methods: {
    async onSubmit() {
      if (!this.settings.id) return this.$toast(this.$t('modules.settings.settingsEntryNotFound'))
      const checkout = await this.$refs.form.validate()
      if (!checkout) {
        this.savePending = true
        await this.$client.service('settings').patch(this.settings.id, {
          gameFps: this.inputed.gameFps,
          showMinimap: this.inputed.showMinimap
        }).then(() => {
          this.$toast(this.$t('modules.settings.updateSettingsOk'))
          this.savePending = false
        }).catch(e => {
          console.log(e)
          this.$toast(this.$t('modules.settings.updateSettingsError'))
          this.savePending = false
        })
      }
    }
  },
  watch: {
    'settings' () {
      this.inputed.showMinimap = this.settings.showMinimap.toString()
      this.inputed.gameFps =  this.settings.gameFps.toString()
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.settings.title'))
  },
  mounted () {
    if (this.settings) {
      this.inputed.showMinimap = this.settings.showMinimap.toString()
      this.inputed.gameFps =  this.settings.gameFps.toString()
    }
  }
}
</script>

<style>

</style>