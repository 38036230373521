<template>
  <van-overlay :z-index="200" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline modalTitle">{{ $t('modules.controlPanel.players.manageItems') }}</div>
        <template v-if="setSizeMode">
            <div class="infoTextSmall">
              {{ `${$t('modules.controlPanel.players.itemsLength')}:` }}
              <van-loading style="display:inline" size="16px" v-if="itemsSizePending"></van-loading>
              <span v-else>
                {{ itemsSize }}
              </span>
            </div>
            <van-field :disabled="itemsSizePending" type="number" v-model.number="inputed.size" :placeholder="$t('modules.controlPanel.players.sizePlaceholder')" />
            <van-button @click="setRackSize" :loading="setSizePending" :disabled="setSizePending || itemsSizePending" type="primary" size="small" class="my-05" block>{{ $t('common.edit') }}</van-button>
            <van-button size="small" block @click="setMode('flush')" type="warning">{{ $t('common.cancel') }}</van-button>
        </template>
        <template v-else>
          <div class="centeredInline">
            <van-button size="small" block @click="setMode('setSize')" type="warning">{{ $t('modules.controlPanel.players.setRackSize') }}</van-button>
          </div>
        </template>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  props: {
    uid: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    activeMode: false,
    setSizeMode: false,
    itemsSizePending: true,
    setSizePending: false,
    itemsSize: 0,
    inputed: {
      size: ''
    }
  }),
  computed: {
    showModal () {
      return this.$store.state.cpanel.modals.managePlayerRack.show
    }
  },
  methods: {
    setRackSize () {
      if (this.inputed.size < this.itemsSize) return this.$toast(this.$t('modules.controlPanel.players.rackSizeItemsWarn'))
      this.setSizePending = true
      this.$client.service('setdata').create({
        userId: this.uid,
        setRackSize: true,
        size: this.inputed.size
      }).then(res => {
        this.$emit('setsize', res.resultSize)
        this.setSizePending = false
        this.$toast(this.$t('modules.controlPanel.players.setSizeOk'))
        this.closeModal()
      }).catch(() => {
        this.setSizePending = false
        this.$toast(this.$t('modules.controlPanel.players.setSizeError'))
      })
    },
    getRackItemsSize () {
      this.$client.service('getdata').find({
        query: {
          getRackSize: true,
          uid: this.uid
        }
      }).then(res => {
        this.itemsSize = res.itemsLength
        this.inputed.size = res.totalSize
        this.$nextTick(() => {
          this.itemsSizePending = false
        })
      }).catch(() => {
        this.itemsSize = 0
        this.inputed.size = 0
        this.$nextTick(() => {
          this.itemsSizePending = false
        })
      })
    },
    setMode (mode = null) {
      if (!mode) return false
      switch (mode) {
        case 'setSize':
          this.setSizeMode = true
          if (this.itemsSizePending) this.getRackItemsSize()
          this.activeMode = true
          break
        case 'flush':
          this.setSizeMode = false
          this.activeMode = false
          break
        default:
          break
      }
    },
    closeModal () {
      this.$store.dispatch('cpanel/SHOW_MANAGE_PLAYER_RACK_MODAL', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    },
  },
  mounted () {}
}
</script>