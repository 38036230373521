<template>
  <van-overlay :show="showModal" @click="closeModal">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="centeredInline row grt_si_btns">
          <div class="modalTitle">{{ $t('modules.greetings.SIFormTitle') }}</div>
          <p class="smallText">{{ $t('modules.greetings.SIFormDescr') }}</p>
          <input type="email" v-model.trim="inputed.email" :placeholder="$t('common.email')" />
          <input type="password" v-model.trim="inputed.password" :placeholder="$t('common.password')" />
          <van-button @click="signIn" :loading="pending" plain class="greetings_btn" style="margin:.5rem 0 0 0;">{{ $t('modules.greetings.SIFormBtn') }}</van-button>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'greetings-signin-form-modal',
  data () {
    return {
      pending: false,
      inputed: {
        email: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapState({
      showModal: state => state.common.modals.signInForm.show
    })
  },
  methods: {
    signIn () {
      if (!this.inputed.email.length || !this.inputed.password.length) return this.$toast(this.$t('common.fieldsError'))
      this.pending = true
      this.$store.dispatch('auth/authenticate', {
        email: this.inputed.email,
        password: this.inputed.password
      }).then(() => {
        this.pending = false
        this.closeModal()
        return this.$router.replace('/')
      }).catch(e => {
        this.$toast(this.$t(e.message === "Invalid login" ? 'modules.greetings.SIErrorIL' : 'common.errorOccured'))
        this.pending = false
      })
    },
    closeModal () {
      this.$store.dispatch('common/SHOW_SI_MODAL', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    }
  }
}
</script>

<style>

</style>