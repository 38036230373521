<template>
  <div>
    <npc-dialogue v-if="interactingNpc" :npc="interactingNpc" @rerender="interactingNpc=null" :key="`ndk-${npcDialogueKey}`"></npc-dialogue>
    <fight-overlay></fight-overlay>
    <victory-overlay v-if="combatResult" :result="combatResult" @rerender="combatResult=null"></victory-overlay>
    <defeat-overlay v-if="user.isDead"></defeat-overlay>
  </div>
</template>
<script>
import NpcDialogue from '../components/NpcDialogue.vue'
import VictoryOverlay from '../components/VictoryOverlay.vue'
import DefeatOverlay from '../components/DefeatOverlay.vue'
import FightOverlay from '../components/FightOverlay.vue'
import { getTeleportInfo, resolveSpawnPoint, resolveScene, getGameContainerSize } from '../game/utils'
import throttle from 'lodash-es/throttle'
import EventDispatcher from '../game/utils/EventDispatcher'
import { mapState } from 'vuex'
export default {
  name: 'game-events-handler',
  components: {
    NpcDialogue,
    FightOverlay,
    VictoryOverlay,
    DefeatOverlay
  },
  data () {
    return {
      combatResult: null,
      teleporting: false,
      locSwitching: false,
      currentTeleportId: null,
      eventEmitter: null,
      overlapHanlder: null,
      teleportEndedHandler: null,
      positionChanged: null,
      userdataPatched: null,
      sceneRestartedHandler: null,
      movementStartedHandler: null,
      movementStoppedHandler: null,
      resizeGameHandler: null,
      canMoveResponseHandler: null,
      userMovedHandler: null,
      onWorkerMessage: null,
      showNotify: null,
      interactingNpc: null,
      npcDialogueKey: 0,
      combatStarted: null,
      combatEnded: null,
      fightOverlayShowHandler: null,
      combatHit: null,
      combatMonsterHit: null,
      mapSceneRenderedHandler: null,
      monsterKilledHandler: null
    }
  },
  computed: {
    apiClient () {
      return this.$client
    },
    ...mapState({
      positionSyncPending: state => state.common.positionSyncPending
    })
  },
  methods: {
    showNpcDialogue (npc = null) {
      if (!npc) return false
      this.interactingNpc = npc
      this.$nextTick(() => {
        this.$store.dispatch('components/SHOW_NPC_DIALOGUE_POPUP', true)
      })
    },
    onOverlap (data) {
      if (!data.type || !data.name || !data.currentPosition) return false
      if (data.type.toUpperCase() === 'TELEPORT') {
        return this.teleport(data.name, data.currentPosition)
      }
    },
    teleport: throttle(function (to = null, position = null) {
      if (!to || this.teleporting || !position || this.positionSyncPending) return false
      const teleportInfo = getTeleportInfo(to)
      if (teleportInfo) {
        this.teleporting = true
        this.$store.dispatch('common/POSITION_SYNC', true)
        this.syncLocation(teleportInfo.locationId, position, teleportInfo).then(() => {
          this.currentTeleportId = teleportInfo.locationId
          this.eventEmitter.emit('switchScene', teleportInfo.locationId)
          this.teleporting = false
          this.locSwitching = false
          this.$store.dispatch('common/POSITION_SYNC', false)
        }).catch(e => {
          console.log('EROR_SYNCH_USER_LOCATION', e)
          this.$toast(this.$t('common.teleportError'))
          this.locSwitching = false
          this.teleporting = false
          this.$store.dispatch('common/POSITION_SYNC', false)
        })
      } else console.error('CAN_NOT_GET_TELEPORT_INFO:LOCATION_NOT_FOUND')
    }, 500),
    syncLocation (id = null, position = null, teleportInfo = null) {
      if (!id) throw new Error('CAN_NOT_SYNCHRONIZE_LOCATION:LOCATION_ID_MISSING')
      if (!position) throw new Error('CAN_NOT_SYNCHRONIZE_LOCATION:POSITION_MISSING')
      if (!teleportInfo) throw new Error('CAN_NOT_SYNCHRONIZE_LOCATION:TELEPORT_INFO_MISSING')
      if (+this.user.mapId === +id) return new Promise((resolve, reject) => resolve())
      this.locSwitching = true
      let newPosition = resolveSpawnPoint(resolveScene(), teleportInfo)
      return this.$client.service('userdata').patch(this.user.id, {
        switchLocation: {
          mapId: id,
          x: newPosition.x,
          y: newPosition.y
        }
      })
    }
  },
  mounted () {
    this.eventEmitter = EventDispatcher.getInstance()
    this.mapSceneRenderedHandler = data => {
      if (this.user.isDead) this.$store.dispatch('components/SHOW_DEFEAT_POPUP', true)
    }
    this.overlapHanlder = data => {
      this.onOverlap(data)
    },
    this.teleportEndedHandler = data => {
      if (+data === this.currentTeleportId) {
        this.currentTeleportId = null
        this.teleporting = false
      }
    }
    this.canMoveResponseHandler = data => {
      if (data.result && data.pos && !isNaN(data.pos.x) && !isNaN(data.pos.y)) {
        if (+data.pos.x !== +this.user.coorsX || +data.pos.y !== +this.user.coorsY) {
         this.$client.service('signals').create({
           move: true,
           coordinates: data.pos
         })
        } else return false
      } else return false
    }
    this.sceneRestartedHandler = () => {
      this.$store.dispatch('common/POSITION_SYNC', false)
    }
    this.movementStartedHandler = data => {
      // if (data === 'current-player') this.$store.dispatch('common/POSITION_SYNC', true)
    }
    this.movementStoppedHandler = data => {
      if (data === 'current-player') this.$store.dispatch('common/POSITION_SYNC', false)
    }
    this.positionChanged = data => {
      // console.log(`data.x: ${data.x}, data.y: ${data.y}, user.x: ${this.user.coorsX}, user.y: ${this.user.coorsY}`)
      if (+data.x !== +this.user.coorsX || +data.y !== +this.user.coorsY) {
        if (Math.abs(data.x - this.user.coorsX) > 1 || Math.abs(data.y - this.user.coorsY) > 1) return false
        this.$worker.postMessage({
          cmd: 'sync-location',
          uid: this.user.id,
          position: {
            x: data.x,
            y: data.y
          }
        })
      }
    }
    this.userdataPatched = data => {
      if (!data.id) return false
      let playerId = null
      if (+data.id === +this.user.id) {
        playerId = 'current-player'
      } else {
        playerId = `player-${data.id}`
      }
      if (playerId && !this.locSwitching) {
        this.eventEmitter.emit('playerPatched', data)
        if (+data.id === +this.user.id && +data.mapId !== +this.user.mapId) return this.eventEmitter.emit('restartScene')
        this.eventEmitter.emit('syncPlayerPosition', {
          playerId,
          x: data.coorsX,
          y: data.coorsY
        })
      }
    }
    this.resizeGameHandler = () => {
      this.eventEmitter.emit('resizeGame', getGameContainerSize())
    }
    this.onWorkerMessage = workerData => {
      const data = workerData.data
      switch (data.cmd) {
        case 'store-position-sync':
          this.$store.dispatch('common/POSITION_SYNC', data.state)
          break
        case 'ping':
          this.$store.dispatch('components/SET_PING', data.ms)
          break
        case 'sync-position-error':
          this.$toast(this.$t('common.moveError'))
          this.eventEmitter.emit('movePlayerTo', {
            playerId: 'current-player',
            x: this.user.coorsX,
            y: this.user.coorsY
          })
          break
        case 'set-rack-size':
          this.$store.dispatch('rack/SET_SIZE', data.data.size)
          break
        case 'set-rack-items':
          this.$store.dispatch('rack/SET_ITEMS', data.data.items)
          break
        case 'flush-rack':
          this.$store.dispatch('rack/FLUSH_RACK')
          break
        default:
          console.log('UNKNOWN_WORKER_MESSAGE', data)
          break
      }
    }
    this.showNotify = data => {
      if (!data.type) return false
      switch (data.type) {
        case 'showNpcDistanceWarn':
          this.$notify({
            type: 'warning',
            message: this.$t('common.npcDistanceWarn')
          })
          break
        default:
          break
      }
    }
    this.combatStarted = data => {
      this.$store.dispatch('components/SHOW_NPC_DIALOGUE_POPUP', false)
    }
    this.combatEnded = data => {
      // console.log('combat ended', data)
      if (+data.userId === +this.user.id && !data.canEscape) this.eventEmitter.emit('escapeCombat')
      if (+data.userId === +this.user.id && data.combatResult) {
        this.combatResult = data.combatResult
        this.$nextTick(() => {
          this.$store.dispatch(data.playerDead ? 'components/SHOW_DEFEAT_POPUP' : 'components/SHOW_VICTORY_POPUP', true)
          this.eventEmitter.emit('combatEnded', {
            die: data.playerDead
          })
        })
      }
    }
    this.fightOverlayShowHandler = data => {
      this.$store.dispatch('components/SET_SHOW_FIGHT_OVERLAY', data.show || false)
    }
    this.combatHit = data => {
      if (+data.userId === +this.user.id) {
        this.eventEmitter.emit('playerHit', data)
      }
    }
    this.combatMonsterHit = data => {
      if (+data.userId === +this.user.id) {
        this.eventEmitter.emit('monsterHit', data)
      }
    }
    this.monsterKilledHandler = data => {
      if (+data.userId === +this.user.id && this.uActions) {
        if (this.uActions.monstersData.some(actdata => actdata.data.some(m => +m.id === +data.monsterId))) {
          const index = this.uActions.monstersData.findIndex(act => act.data.some(m => +m.id === +data.monsterId))
          if (index > -1) {
            this.$client.service('quests').get(this.uActions.monstersData[index].questId).then(quest => {
              this.$client.service('monsters').get(data.monsterId).then(monster => {
                const sindex = this.uActions.monstersData[index].data.findIndex(m => +m.id === +monster.id)
                if (index > -1) {
                  this.$toast(`${quest.name}. ${monster.name} ${this.$t('modules.quests.killInfo')[0]} ${this.uActions.monstersData[index].data[sindex].killed} ${this.$t('modules.quests.killInfo')[1]} ${this.uActions.monstersData[index].data[sindex].needed}`)
                }
              }).catch(() => {})
            }).catch(() => {})
          }
        }
      }
    }
    window.addEventListener('resize', this.resizeGameHandler)
    this.eventEmitter.on('onoverlap', this.overlapHanlder)
    this.eventEmitter.on('mapSceneRendered', this.mapSceneRenderedHandler)
    this.eventEmitter.on('showNotify', this.showNotify)
    this.eventEmitter.on('showNpcDialogue', this.showNpcDialogue)
    this.eventEmitter.on('canMoveResponse', this.canMoveResponseHandler)
    this.eventEmitter.on('teleportEnded', this.teleportEndedHandler)
    this.eventEmitter.on('sceneRestarted', this.sceneRestartedHandler)
    this.eventEmitter.on('playerPositionChanged', this.positionChanged)
    this.eventEmitter.on('movementStarted', this.movementStartedHandler)
    this.eventEmitter.on('movementStopped', this.movementStoppedHandler)
    this.eventEmitter.on('showFightOverlay', this.fightOverlayShowHandler)
    this.$client.service('userdata').on('patched', this.userdataPatched)
    this.$client.service('userdata').on('updated', this.userdataPatched)
    this.$client.service('combat').on('started', this.combatStarted)
    this.$client.service('combat').on('ended', this.combatEnded)
    this.$client.service('combat').on('hit', this.combatHit)
    this.$client.service('combat').on('monster-hit', this.combatMonsterHit)
    this.$client.service('signals').on('monster-killed', this.monsterKilledHandler)
    this.$worker.onmessage = this.onWorkerMessage
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeGameHandler)
    this.eventEmitter.off('onoverlap')
    this.eventEmitter.off('mapSceneRendered')
    this.eventEmitter.off('showNpcDistanceWarn')
    this.eventEmitter.off('canMoveResponse')
    this.eventEmitter.off('teleportEnded')
    this.eventEmitter.off('sceneRestarted')
    this.eventEmitter.off('playerPositionChanged')
    this.eventEmitter.off('showNpcDialogue')
    this.eventEmitter.off('movementStarted')
    this.eventEmitter.off('movementStopped')
    this.$client.service('userdata').removeListener('patched', this.userdataPatched)
    this.$client.service('userdata').removeListener('updated', this.userdataPatched)
    this.$client.service('combat').removeListener('started', this.combatStarted)
    this.$client.service('combat').removeListener('ended', this.combatEnded)
    this.$client.service('combat').removeListener('hit', this.combatHit)
    this.$client.service('combat').removeListener('monster-hit', this.combatMonsterHit)
    this.$client.service('signals').removeListener('monster-killed', this.monsterKilledHandler)
    this.combatStarted = this.combatEnded = this.overlapHanlder = this.teleportEndedHandler = this.positionChanged = this.userdataPatched = this.sceneRestartedHandler = this.movementStartedHandler = this.movementStoppedHandler = this.userdataPatched = this.canMoveResponseHandler = this.combatHit = this.combatMonsterHit = this.resizeGameHandler = this.monsterKilledHandler = null
  }
}
</script>

<style>

</style>