<template>
  <div>
    <div class="centered" v-if="pending">
      <van-loading></van-loading>
    </div>
    <div class="centeredInline row" v-else>
      <van-tabs style="z-index:10;position:sticky;top:.5rem;margin:.5rem 0 1rem 0;width:100%">
        <van-tab v-for="(tab, index) in $t('modules.rack.tabs')" style="padding:1rem 5rem;" :key="`${index}--tab`" :title="tab.title"></van-tab>
      </van-tabs>
      <div class="rack-currency-info">
        <div>
          {{ user.gold }}
          <coin-icon class="ml-05" size="1.3rem"></coin-icon>
        </div>
        <div>
          {{ user.tokens }}
          <svg-icon size="1.3rem" color="#ffb703" style="margin-left:.5rem" name="token"></svg-icon>
        </div>
      </div>
      <div class="rack">
        <div @click="showItemInfo(item)" :style="{borderImageSource:`url(/assets/UI/images/borders/item-${item.rarity}.png)`}" class="rack-item pointer" v-for="item in rack.items" :key="`rack-item-${item.id}`">
          <img class="rack-item-img" :src="cdnPath(item.image)" alt="" />
          <div class="rack-item-info">
            <template>
              {{ item.stackCount }}
            </template>
          </div>
        </div>
        <template v-if="rack.size > rack.items.length">
          <div class="rack-item noborder pointer" v-for="(item, index) in rack.size - rack.items.length" :key="`rack-item-plch-${index}`">
            <svg-icon name="bag" color="#FFFFFF33"></svg-icon>
          </div>
        </template>
      </div>
      <div style="width:100%" class="centeredInline pointer">
        {{ $t('modules.rack.upgradeBtn') }}
        {{ upgradeCost }} <coin-icon class="ml-05" size="1rem"></coin-icon>
      </div>
    </div>
    <item-info v-if="selectedItem" :item="selectedItem" @rerender="selectedItem=null"></item-info>
  </div>
</template>

<script>
import ItemInfo from './modals/ItemInfo.vue'
export default {
  name: 'rack-index',
  components: {
    ItemInfo
  },
  data () {
    return {
      pending: true,
      rack: null,
      upgradeCost: 5,
      selectedItem: null,
      itemCreated: null,
      itemPatched: null,
      itemRemoved: null
    }
  },
  methods: {
    showItemInfo (item = null) {
      if (!item) return false
      this.selectedItem = item
      this.$nextTick(() => {
        this.$store.dispatch('rack/SHOW_ITEM_INFO', true)
      })
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.rack.title'))
  },
  async mounted () {
    this.rack = await this.$client.service('rack').get(this.user.rackId).catch(e => {
      this.$toast(this.$t('common.errorOccured'))
      return {
        id: null,
        items: [],
        size: 50
      }
    })
    this.pending = false
    this.itemCreated = data => {
      if (+data.rackId !== +this.rack.id) return false
      this.rack.items.push(data)
    }
    this.itemPatched = data => {
      if (+data.rackId !== +this.rack.id) return false
      let index = this.rack.items.findIndex(i => +i.id === +data.id)
      if (index > -1) {
        this.$set(this.rack.items, index, {
          ...this.rack.items[index],
          ...data
        })
      }
    }
    this.itemRemoved = data => {
      if (+data.rackId !== +this.rack.id) return false
      let index = this.rack.items.findIndex(i => +i.id === +data.id)
      if (index > -1) {
        index ? this.rack.items.splice(index, 1) : this.rack.items.shift()
      }
    }
    this.$client.service('uitems').on('created', this.itemCreated)
    this.$client.service('uitems').on('updated', this.itemPatched)
    this.$client.service('uitems').on('patched', this.itemPatched)
    this.$client.service('uitems').on('removed', this.itemRemoved)
  },
  beforeDestroy () {
    this.$client.service('uitems').removeListener('created', this.itemCreated)
    this.$client.service('uitems').removeListener('updated', this.itemPatched)
    this.$client.service('uitems').removeListener('patched', this.itemPatched)
    this.$client.service('uitems').removeListener('removed', this.itemRemoved)
    this.itemCreated = this.itemPatched = this.itemRemoved = null
  }
}
</script>

<style>
@import url(../../assets/css/rack.css);
</style>