<template>
  <div>
    <div class="centeredInline infoText">{{ $t('modules.items.selectCategory') }}</div>
    <van-cell clickable :to="`/control-panel/manage-items/${category.value}`" v-for="(category, index) in $t('modules.items.categories')" :key="`ic-${index}`">
      <template #icon>
        <div class="cell-icon">
          <svg-icon :name="category.icon" size="1.5rem"></svg-icon>
        </div>
      </template>
      <template #title>
        {{ category.name }}
      </template>
      <template #extra>
        <van-loading v-if="isNaN(total[category.value])"></van-loading>
        <span v-else>{{ total[category.value] }}</span>
      </template>
    </van-cell>
  </div>
</template>

<script>
export default {
  name: 'control-panel-manage-items-index',
  data () {
    return {
      total: {
        wear: null,
        resource: null,
        quest: null,
        loot: null,
        consumable: null
      }
    }
  },
  methods: {

  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.controlPanel.manageItemsTitle'))
  },
  mounted () {
    this.$client.service('items').find({
      query: {
        $limit: 0,
        type: 'wear'
      }
    }).then(w => {
      this.total.wear = w.total
    }).catch(() => {
      this.total.wear = 0
    })
    this.$client.service('items').find({
      query: {
        $limit: 0,
        type: 'resource'
      }
    }).then(r => {
      this.total.resource = r.total
    }).catch(() => {
      this.total.resource = 0
    })
    this.$client.service('items').find({
      query: {
        $limit: 0,
        type: 'loot'
      }
    }).then(l => {
      this.total.loot = l.total
    }).catch(() => {
      this.total.loot = 0
    })
    this.$client.service('items').find({
      query: {
        $limit: 0,
        type: 'quest'
      }
    }).then(q => {
      this.total.quest = q.total
    }).catch(() => {
      this.total.quest = 0
    })
    this.$client.service('items').find({
      query: {
        $limit: 0,
        type: 'consumable'
      }
    }).then(l => {
      this.total.consumable = l.total
    }).catch(() => {
      this.total.consumable = 0
    })
  }
}
</script>

<style>

</style>