const state = {
  modals: {
    editMapData: {
      show: false
    },
    createMapData: {
      show: false
    },
    mapVisualizer: {
      show: false
    },
    addMonster: {
      show: false
    },
    editMonster: {
      show: false
    },
    addNpc: {
      show: false
    },
    editNpc: {
      show: false
    },
    addItem: {
      show: false
    },
    editItem: {
      show: false
    },
    addDialogue: {
      show: false
    },
    testDialogue: {
      show: false
    },
    addQuest: {
      show: false
    },
    editQuest: {
      show: false
    },
    addItemsToRack: {
      show: false
    },
    managePlayerRack: {
      show: false
    },
    managePlayerQuests: {
      show: false
    },
    coinsExp: {
      show: false
    }
  }
}
const getters = {}
const mutations = {
  setShowEditMapDataModal (state, bool = false) {
    state.modals.editMapData.show = bool
  },
  setShowCreateMapDataModal (state, bool = false) {
    state.modals.createMapData.show = bool
  },
  setShowMapVisualizerModal (state, bool = false) {
    state.modals.mapVisualizer.show = bool
  },
  setShowAddMonsterModal (state, bool = false) {
    state.modals.addMonster.show = bool
  },
  setShowEditMonsterModal (state, bool = false) {
    state.modals.editMonster.show = bool
  },
  setShowAddNpcModal (state, bool = false) {
    state.modals.addNpc.show = bool
  },
  setShowEditNpcModal (state, bool = false) {
    state.modals.editNpc.show = bool
  },
  setShowAddItemModal (state, bool = false) {
    state.modals.addItem.show = bool
  },
  setShowEditItemModal (state, bool = false) {
    state.modals.editItem.show = bool
  },
  setShowAddDialogueModal (state, bool = false) {
    state.modals.addDialogue.show = bool
  },
  setShowTestDialogueModal (state, bool = false) {
    state.modals.testDialogue.show = bool
  },
  setShowAddQuestModal (state, bool = false) {
    state.modals.addQuest.show = bool
  },
  setShowEditQuestModal (state, bool = false) {
    state.modals.editQuest.show = bool
  },
  setShowAddItemsToRackModal (state, bool = false) {
    state.modals.addItemsToRack.show = bool
  },
  setShowManagePlayerRackModal (state, bool = false) {
    state.modals.managePlayerRack.show = bool
  },
  setShowManagePlayerQuestsModal (state, bool = false) {
    state.modals.managePlayerQuests.show = bool
  },
  setShowManagePlayerCoinsExpModal (state, bool = false) {
    state.modals.coinsExp.show = bool
  }
}
const actions = {
  SHOW_EDIT_MAP_DATA ({ commit }, bool) {
    commit('setShowEditMapDataModal', bool)
  },
  SHOW_CREATE_MAP_DATA ({ commit }, bool) {
    commit('setShowCreateMapDataModal', bool)
  },
  SHOW_MAP_VISUALIZER ({ commit }, bool) {
    commit('setShowMapVisualizerModal', bool)
  },
  SHOW_ADD_MONSTER_MODAL ({ commit }, bool) {
    commit('setShowAddMonsterModal', bool)
  },
  SHOW_EDIT_MONSTER_MODAL ({ commit }, bool) {
    commit('setShowEditMonsterModal', bool)
  },
  SHOW_ADD_NPC_MODAL ({ commit }, bool) {
    commit('setShowAddNpcModal', bool)
  },
  SHOW_EDIT_NPC_MODAL ({ commit }, bool) {
    commit('setShowEditNpcModal', bool)
  },
  SHOW_ADD_ITEM_MODAL ({ commit }, bool) {
    commit('setShowAddItemModal', bool)
  },
  SHOW_EDIT_ITEM_MODAL ({ commit }, bool) {
    commit('setShowEditItemModal', bool)
  },
  SHOW_ADD_DIALOGUE_MODAL ({ commit }, bool) {
    commit('setShowAddDialogueModal', bool)
  },
  SHOW_TEST_DIALOGUE_MODAL ({ commit }, bool) {
    commit('setShowTestDialogueModal', bool)
  },
  SHOW_ADD_QUEST_MODAL ({ commit }, bool) {
    commit('setShowAddQuestModal', bool)
  },
  SHOW_EDIT_QUEST_MODAL ({ commit }, bool) {
    commit('setShowEditQuestModal', bool)
  },
  SHOW_ADD_ITEMS_TO_RACK_MODAL ({ commit }, bool) {
    commit('setShowAddItemsToRackModal', bool)
  },
  SHOW_MANAGE_PLAYER_RACK_MODAL ({ commit }, bool) {
    commit('setShowManagePlayerRackModal', bool)
  },
  SHOW_MANAGE_PLAYER_QUESTS_MODAL ({ commit }, bool) {
    commit('setShowManagePlayerQuestsModal', bool)
  },
  SHOW_MANAGE_PLAYER_COINS_EXP_MODAL ({ commit }, bool) {
    commit('setShowManagePlayerCoinsExpModal', bool)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}