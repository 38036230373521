<template>
  <van-overlay :z-index="200" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline modalTitle" v-show="!item">{{ $t('modules.controlPanel.players.addItemsTitle') }}</div>
        <template v-if="!item">
          <div class="infoText my-05">{{ $t('modules.quests.targetItemId') }}</div>
          <van-field @keyup.enter="checkItemData" type="number" v-model.number="inputed.itemId" :placeholder="$t('modules.quests.targetItemId')"></van-field>
          <div class="centeredInline">
            <van-button :loading="checkPending" :disabled="!inputed.itemId" type="warning" block @click="checkItemData" class="mt-05" size="small">{{ $t('common.find') }}</van-button>
          </div>
        </template>
        <template v-else>
          <div class="modalTitle centeredInline">{{ item.name }}</div>
          <div class="centeredInline tinyText mb-05">
            {{ $t('modules.items.names')[item.type] }}
          </div>
          <div class="centeredInline">
            <div class="image-upload mt-05" style="padding:1rem">
              <img :src="cdnPath(item.image)" alt="" />
            </div>
          </div>
          <p>{{ item.description }}</p>
          <van-divider dashed>{{ $t('common.information') }}</van-divider>
          <ul>
            <li>{{ `${$t('common.rarity')}: `}}<span :class="`rarity-${item.rarity}`">{{`${$t('common.rarityNames')[item.rarity]}` }}</span></li>
            <li>{{ `${$t('modules.items.type')}: ${$t('modules.items.names')[item.type]}` }}</li>
            <li>{{ `${$t('modules.items.sellPrice')}: ${item.sellPrice} `}} <coin-icon size="1rem"></coin-icon></li>
            <li v-if="item.type==='wear'">{{ `${$t('common.level')}: ${item.equipLevel} ${$t('common.levelShort')}` }}</li>
            <template v-if="item.type==='wear'">
              <li>
                {{ `${$t('modules.items.equipClass')}: `}}
                <span class="ml-05" v-for="(eqClass, index) in item.equipClass" :key="`eq-class-${eqClass}`">
                  <svg-icon class="" :name="$t('modules.items.playerClasses')[eqClass].icon" size=".8rem"></svg-icon>
                  {{`${$t('modules.items.playerClasses')[eqClass].name }` }}
                  <template v-if="index+1 !== item.equipClass.length">
                    ,
                  </template>
                </span>
              </li>
              <li>
                {{ `${$t('modules.items.equipSlot')}: `}}
                <svg-icon class="ml-05" :name="$t('modules.items.equipTypes')[item.equipType].icon" size=".8rem"></svg-icon>
                {{`${$t('modules.items.equipTypes')[item.equipType].name}` }}
              </li>
              <li>
                <van-divider dashed>{{ $t('common.parameters') }}</van-divider>
                <ul>
                  <li v-for="param in Object.keys($t('modules.parameters.types'))" :key="`eq-params-${param}`">
                    <svg-icon style="margin:0 .25rem" :name="$t('modules.parameters.types')[param].icon" size=".75rem"></svg-icon>
                    {{ $t('modules.parameters.types')[param].name }}
                    {{ item[$t('modules.parameters.types')[param].itemKey] > 0 ? `+${item[$t('modules.parameters.types')[param].itemKey]}` : item[$t('modules.parameters.types')[param].itemKey] }}
                  </li>
                  <li v-if="item.critChance">
                    <svg-icon style="margin:0 .25rem" name="critical" size=".75rem"></svg-icon>
                    {{ `${$t('modules.parameters.critChance')}: +${item.critChance}%` }}
                  </li>
                </ul>
              </li>
            </template>
          </ul>
          <div class="centeredInline mt-05">
            <template v-if="addMode">
              <van-stepper :min="1" :max="item.canStack ? 99 : 1" style="width:100%" v-model="inputed.quantity" />
            </template>
            <van-button @click="addMode?addItem():addMode=true" :loading="addPending" type="primary" class="mr-05" block>{{ $t('common.add') }}</van-button>
            <van-button v-show="!addMode" type="warning" block @click="item=null">{{ $t('common.cancel') }}</van-button>
          </div>
        </template>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  props: {
    uid: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    checkPending: false,
    addPending: false,
    addMode: false,
    item: null,
    inputed: {
      itemId: '',
      quantity: ''
    }
  }),
  computed: {
    showModal () {
      return this.$store.state.cpanel.modals.addItemsToRack.show
    }
  },
  methods: {
    addItem () {
      if (!this.item || !this.inputed.quantity || this.inputed.quantity > 99) return false
      if (!this.item.canStack && this.inputed.quantity > 1) return false
      this.addPending = true
      this.$client.service('setdata').create({
        addItemToRack: true,
        userId: this.uid,
        itemId: this.item.id,
        quantity: this.inputed.quantity
      }).then(() => {
        this.$toast(this.$t('modules.controlPanel.players.addItemOk'))
        this.addPending = false
        this.$nextTick(() => {
          this.item = null
          this.inputed.itemId = ''
          this.inputed.quantity = ''
        })
      }).catch(() => {
        this.$toast(this.$t('modules.controlPanel.players.addItemError'))
        this.addPending = false
      })
    },
    checkItemData () {
      if (!this.inputed.itemId) return false
      this.$client.service('items').get(this.inputed.itemId).then(item => {
        console.log(item)
        this.item = item
        this.$nextTick(() => {
          this.inputed.itemId = ''
          this.checkPending = false
        })
      }).catch(() => {
        this.$toast(this.$t('modules.controlPanel.monsterLootItemIDNotFound'))
        this.checkPending = false
      })
    },
    closeModal () {
      this.$store.dispatch('cpanel/SHOW_ADD_ITEMS_TO_RACK_MODAL', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    },
  },
  mounted () {}
}
</script>