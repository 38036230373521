import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import Router from './router'
import Store from './store'
import mixins from './mixins'
import ClientPlugin from './plugins/client'
import { DropdownMenu, DropdownItem, CountDown, Stepper, Notify, Progress, Tag, List, TreeSelect, Checkbox, CheckboxGroup, Icon, Overlay, Button, Toast, Cell, Loading, Form, Field, Dialog, Empty, Divider, Tabs, Tab, Radio, RadioGroup, CellGroup, Popup } from 'vant'
import { Locale, Badge } from 'vant'
import Vue2TouchEvents from 'vue2-touch-events'
import 'vant/lib/index.less'
import lang from 'vant/lib/locale/lang/en-US'
import { defaultLocale, languages } from './locales'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlayCircle, faRunning, faSortUp, faSortDown, faArrowUp, faArrowDown, faArrowLeft, faArrowRight, faUnlock, faCheckCircle, faImage, faCog, faLock, faPlusCircle, faHandsHelping, faEdit, faTrash, faTimes, faCheck, faGlobe, faStreetView, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import SvgIcon from './componentsGlobal/SvgIcon.vue'
import CoinIcon from './componentsGlobal/CoinIcon.vue'
import ExpIcon from './componentsGlobal/ExpIcon.vue'
import filters from './filters'
import dateFilters from './filters/date'

library.add(
  faEdit,
  faTrash,
  faTimes,
  faCheck,
  faGlobe,
  faStreetView,
  faHandsHelping,
  faSyncAlt,
  faPlusCircle,
  faLock,
  faUnlock,
  faCog,
  faImage,
  faCheckCircle,
  faArrowUp,
  faArrowRight,
  faArrowLeft,
  faArrowDown,
  faSortDown,
  faSortUp,
  faRunning,
  faPlayCircle
)

Vue.config.productionTip = false

Vue.mixin(mixins)

const messages = Object.assign(languages)

Vue.component('fa-icon', FontAwesomeIcon)
Vue.component('svg-icon', SvgIcon)
Vue.component('coin-icon', CoinIcon)
Vue.component('exp-icon', ExpIcon)

Vue.use(Vue2TouchEvents)

Vue.use(VueI18n)
Vue.use(ClientPlugin)
Vue.use(Overlay)
Vue.use(Button)
Vue.use(Badge)
Vue.use(Toast)
Vue.use(Cell)
Vue.use(Loading)
Vue.use(Form)
Vue.use(Field)
Vue.use(Dialog)
Vue.use(Progress)
Vue.use(Empty)
Vue.use(Divider)
Vue.use(Tabs)
Vue.use(Tab)
Vue.use(Radio)
Vue.use(Tag)
Vue.use(Notify)
Vue.use(RadioGroup)
Vue.use(CellGroup)
Vue.use(Icon)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Popup)
Vue.use(TreeSelect)
Vue.use(List)
Vue.use(CountDown)
Vue.use(Stepper)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)

filters.create(Vue)
dateFilters.create(Vue)

Vue.component('game-icon', {
  name: 'global-icon-component',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  template: '<img class="icon_cmn" :src="`/assets/icons/${this.name}.png`" alt="" />'
})

Locale.use('en-US', lang)

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'ru',
  messages
})


new Vue({
  i18n,
  store: Store,
  router: Router,
  render: h => h(App)
}).$mount('#app')
