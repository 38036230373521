<template>
  <van-popup v-model="showPopup">
    <div class="menu-global">
      <div @click.stop="redirect(link.to)" class="pointer" :style="{backgroundImage:`url('/assets/icons/${link.icon}.png')`}" v-for="(link, index) in $t('modules.footer.links')" :key="`menu-item-${index}`">
        {{link.title}}
      </div>
      <template v-if="$t('modules.footer.links').length < 9">
        <div v-for="i in 9 - $t('modules.footer.links').length" :key="`menu-slot-${i}`"></div>
      </template>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'menu-global-component',
  data () {
    return {

    }
  },
  computed: {
    showPopup: {
      get () {
        return this.$store.state.components.popups.menuGlobal.show
      },
      set (value) {
        this.$store.dispatch('components/SHOW_GLOBAL_MENU_POPUP', value)
      }
    }
  },
  methods: {
    redirect (link = null) {
      if (!link) return false
      this.showPopup = false
      return this.$router.push(link)
    }
  }
}
</script>

<style>

</style>