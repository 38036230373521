<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'mainpage',
  data () {
    return {

    }
  },
  created () {
    this.$emit('on-authenticated')
  },
  mounted () {
    this.$store.dispatch('auth/auth').catch(() => {
      return this.$router.replace('/welcome')
    })
  }
}
</script>

<style>

</style>