<template>
  <div>
    <div class="monster_info">
      <div>
        <svg-icon name="stamina" :size="iconSize" style="margin:0 .25rem 0 0"></svg-icon>
        {{` ${$t('modules.parameters.hp')}: ${monster.hp} `}}
      </div>
      <div>
        <svg-icon name="hit" :size="iconSize" style="margin:0 .25rem 0 0"></svg-icon>
        {{` ${$t('modules.parameters.damage')}: `}} <span style="margin:0 0 0 .25rem" :class="monster.damageType==='phys'?'phys':'magic'">{{`${monster.minDamage}~${monster.maxDamage} `}}</span>
      </div>
      <div>
        <svg-icon name="star" :size="iconSize" style="margin:0 .25rem 0 0"></svg-icon>
        {{` ${$t('modules.parameters.exp')}: ${monster.gainExp} `}}
      </div>
      <div v-if="monster.isBoss" class="phys">
        <svg-icon name="boss" :size="iconSize" style="margin:0 .25rem 0 0"></svg-icon>
        {{` ${$t('common.boss')} `}}
      </div>
      <div v-if="monster.isBoss">
        <svg-icon name="coors" :size="iconSize" style="margin:0 .25rem 0 0"></svg-icon>
        {{` ${$t('common.coordinates')}: X: ${monster.coorsX} Y: ${monster.coorsY} `}}
      </div>
      <div>
        <svg-icon name="luck" :size="iconSize" style="margin:0 .25rem 0 0"></svg-icon>
        {{` ${$t('common.rarity')}:  `}} <span style="margin:0 0 0 .25rem" :class="`rarity-${monster.rarity}`">{{ ` ${$t('common.rarityNames')[monster.rarity]}` }}</span>
      </div>
      <div>
        <svg-icon name="phys-def" :size="iconSize" style="margin:0 .25rem 0 0"></svg-icon>
        {{` ${$t('modules.parameters.physDef')}: ${monster.physDef} `}}
      </div>
      <div>
        <svg-icon name="magic-def" :size="iconSize" style="margin:0 .25rem 0 0"></svg-icon>
        {{` ${$t('modules.parameters.magDef')}: ${monster.magicDef} `}}
      </div>
      <div>
        <svg-icon name="star" :size="iconSize" style="margin:0 .25rem 0 0"></svg-icon>
        {{` ${$t('modules.parameters.exp')}: ${monster.gainExp} `}}
      </div>
      <div>
        <svg-icon name="gold" :size="iconSize" style="margin:0 .25rem 0 0"></svg-icon>
        {{` ${$t('common.coins')}: ${monster.gainCoins} `}}
      </div>
      <div v-if="monster.loot.length">
        <svg-icon name="bag" :size="iconSize" style="margin:0 .25rem 0 0"></svg-icon>
        {{` ${$t('common.loot')}: `}}
        <span style="margin: 0 .125rem" v-for="(item, index) in monster.loot" :key="`m-loot-${index}`">
          <span :class="`rarity-${item.rarity}`">{{ `${item.name}`}}</span>
          {{ `(${item.chance}%)` }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'monster-info-component',
  props: {
    monster: {
      type: Object,
      required: true,
      default: () => {}
    },
    size: {
      type: String,
      default: 'normal'
    }
  },
  data () {
    return {}
  },
  computed: {
    iconSize () {
      if (this.size === 'small') {
        return '18px'
      } else if (size === 'large') {
        return '24px'
      } else {
        return '18px'
      }
    }
  },
  methods: {

  }
}
</script>

<style>

</style>