import Vue from 'vue'
import VueRouter from 'vue-router'
import BaseRoutes from './modules/base'
import ForumRoutes from './modules/forum'
import CPanelRoutes from './modules/cpanel'
import UserRoutes from './modules/user'
import store from '../store'
Vue.use(VueRouter)

const routes = BaseRoutes.concat( CPanelRoutes, ForumRoutes, UserRoutes )
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
function isBanned (user) {
  // TODO - implement
  return false
}
router.beforeEach(async (to, from, next) => {
  await store.dispatch('auth/auth').catch(e => {
  })
  const user = store.state.auth.user || null
  if (isBanned(user) && to.path !== '/banpage' && to.path !== '/terms/common') {
    return from.path !== '/banpage' ? router.replace('/banpage') : next(new Error('Navigation canceled'))
  }
  if (to.path === '/' && from.path === '/') return next()
  if (to.path === from.path) return next(new Error('Duplicated navigation'))
  if (to.path.includes('/restore')) {
    return next()
  }
  if (to.meta) {
    if (to.meta.accessRole) {
      if (to.meta.accessRole !== 'guest' && to.meta.accessRole !== 'all' && !to.path.includes('/checkout') && !user) {
        return next({path:`/checkout`, query: { to: to.path, role: to.meta.accessRole }})
      }
      if (Array.isArray(to.meta.accessRole)) {
        if (!to.meta.accessRole.includes(user.role)) {
          return next(new Error('Forbidden access #1'))
        }        
      } else {
        if (to.meta.accessRole === 'all') return next()
        if (to.meta.accessRole === 'guest') return user ? next(false) : next()
        if (to.meta.accessRole === 'usr') return user ? next() : next(false)
      }
    } else return next(new Error('Forbidden access #2'))
  }
  next()
})
router.onError(e => {
  console.log(`Router error: ${e}`)
})
export default router