const state = {
  appTitle: 'Загрузка',
  firstRenderedMap: true,
  mapVisible: false,
  modals: {
    signInForm: {
      show: false
    },
    saveCharacter: {
      show: false
    }    
  },
  positionSyncPending: false
}
const getters = {}
const mutations = {
  setFirstRenderedMap (state, bool) {
    state.firstRenderedMap = bool
  },
  setAppTitle (state, title = 'Загрузка') {
    state.appTitle = title
  },
  setShowSIModal (state, bool) {
    state.modals.signInForm.show =  bool
  },
  setShowSaveCharModal (state, bool) {
    state.modals.saveCharacter.show =  bool
  },
  setMapVisibility (state, bool) {
    state.mapVisible = bool
  },
  setPositionSyncPending (state, bool) {
    state.positionSyncPending = bool
  }
}
const actions = {
  FIRST_RENDERED_MAP ({ commit }, bool = false) {
    commit('setFirstRenderedMap', bool)
  },
  SET_APP_TITLE ({ commit }, title) {
    commit('setAppTitle', title)
  },
  SHOW_SI_MODAL ({ commit }, bool = false) {
    commit('setShowSIModal', bool)
  },
  SHOW_SAVE_CHARACTER_MODAL ({ commit }, bool = false) {
    commit('setShowSaveCharModal', bool)
  },
  SET_MAP_VISIBILITY ({ commit }, bool = false) {
    commit('setMapVisibility', bool)
  },
  POSITION_SYNC ({ commit }, bool = false) {
    commit('setPositionSyncPending', bool)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}