<template>
  <van-overlay :z-index="200" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline">
          <div class="modalTitle van-ellipsis">
            {{ `${$t('modules.controlPanel.questEditTitle')}` }}
          </div>
        </div>
        <div class="infoText">{{ $t('modules.quests.name') }}</div>
        <van-field
          v-model.trim="inputed.name"
          :placeholder="$t('modules.quests.name')"
          type="text"
          ></van-field>
        <div class="infoText">{{ $t('modules.quests.description') }}</div>
        <van-field
          v-model.trim="inputed.description"
          :placeholder="$t('modules.quests.description')"
          type="textarea"
          maxlength="2048"
          show-word-limit
          :rows="1"
          autosize
          ></van-field>
        <div class="infoText">{{ $t('modules.quests.level') }}</div>
        <van-field
          v-model.number="inputed.level"
          :placeholder="$t('modules.quests.level')"
          type="number"
          ></van-field>
          <van-radio-group v-model="inputed.type">
            <van-cell-group :title="$t('modules.quests.type')">
              <van-cell v-for="(type, index) in Object.keys($t('modules.quests.types'))" :key="`q-type-${index}`" :title="$t('modules.quests.types')[type].title" clickable @click="inputed.type = type">
                <template #right-icon>
                  <van-radio :name="type">
                    <template #icon="props">
                      <template v-if="props.checked">
                        <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                      </template>
                    </template>
                  </van-radio>
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
          <van-radio-group v-model="inputed.target">
            <van-cell-group :title="$t('modules.quests.target')">
              <van-cell v-for="target in $t('modules.quests.targets')" :key="`target-${target.value}`" :title="target.title" clickable @click="inputed.target = target.value">
                <template #right-icon>
                  <van-radio :name="target.value">
                    <template #icon="props">
                      <template v-if="props.checked">
                        <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                      </template>
                    </template>
                  </van-radio>
                </template>
              </van-cell>
            </van-cell-group>
          </van-radio-group>
          <div class="infoTextSmall" style="margin:.5rem 0 .5rem 1rem">{{ $t(`modules.quests.${inputed.target === 'item' ? 'targetItemData' : 'targetMonsterData'}`) }}</div>
          <div>
            <template v-if="inputed.targetData.length">
              <van-tag class="mr-05" type="warning" size="large" v-for="(tdata, index) in inputed.targetData" @click="removeTargetItem(index)" :key="`tdata-${tdata.id}-${tdata.quantity}`">{{ `${tdata.id}` }}<template v-if="tdata.quantity > 1">{{ `(${tdata.quantity} ${$t('common.pcs')})` }}</template></van-tag>
            </template>
            <template v-if="addTargetMode">
              <van-field
                class="mt-05"
                :label="$t(`modules.quests.${inputed.target === 'item' ? 'targetItemId' : 'targetMonsterId'}`)"
                v-model.number="inputed.tData.id"
                type="number"
                :placeholder="$t(`modules.quests.${inputed.target === 'item' ? 'targetItemId' : 'targetMonsterId'}`)"
              ></van-field>
              <van-field
                class="mb-05"
                :label="$t(`modules.quests.${inputed.target === 'item' ? 'targetItemQuantity' : 'targetMonsterQuantity'}`)"
                v-model.number="inputed.tData.quantity"
                type="number"
                :placeholder="$t(`modules.quests.${inputed.target === 'item' ? 'targetItemQuantity' : 'targetMonsterQuantity'}`)"
              ></van-field>
            </template>
            <div class="centeredInline">
              <van-button @click="addTargetMode ? addTargetItem() : addTargetMode=true" size="small" type="warning">{{ $t(`modules.quests.${inputed.target === 'item' ? 'addTargetItem' : 'addTargetMonster'}`) }}</van-button>
            </div>
          </div>
          <div class="infoText">{{ $t('modules.quests.rewardInfo') }}</div>
          <van-field
            type="number"
            v-model.number="inputed.reward.gold"
            :label="$t('common.coins')"
            :placeholder="$t('common.coins')"
            ></van-field>
          <van-field
            type="number"
            v-model.number="inputed.reward.exp"
            :label="$t('modules.parameters.exp')"
            :placeholder="$t('modules.parameters.exp')"
            ></van-field>
            <div class="infoTextSmall my-05" style="margin-left:1rem">{{ $t('modules.quests.rewardItems') }}</div>
            <template v-if="inputed.reward.items.length">
              <van-tag class="mr-05" type="warning" size="large" v-for="(ritem, index) in inputed.reward.items" @click="removeRewardItem(index)" :key="`ritem-${ritem.id}-${ritem.quantity}`">{{ `${ritem.id}` }}<template v-if="ritem.quantity > 1">{{ `(${ritem.quantity} ${$('common.pcs')})` }}</template></van-tag>
            </template>
            <template v-if="addRewardItemMode">
              <van-field
                class="mt-05"
                :label="$t(`modules.quests.targetItemId`)"
                v-model.number="inputed.rewardData.id"
                type="number"
                :placeholder="$t(`modules.quests.targetItemId`)"
              ></van-field>
              <van-field
                class="mb-05"
                :label="$t(`modules.quests.rewardItemQuantity`)"
                v-model.number="inputed.rewardData.quantity"
                type="number"
                :placeholder="$t(`modules.quests.rewardItemQuantity`)"
              ></van-field>
            </template>
            <div class="centeredInline">
              <van-button @click="addRewardItemMode ? addRewardItem() : addRewardItemMode=true" size="small" type="warning">{{ $t(`modules.quests.addTargetItem`) }}</van-button>
            </div>
          <div class="centeredInline mt-05">
            <van-button :loading="savePending" :disabled="savePending" @click="save" type="primary" block>{{ $t('common.edit') }}</van-button>
            <van-button class="ml-05" @click="removeItem" type="warning" block>{{ $t('common.remove') }}</van-button>
          </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: 'control-panel-edit-quest-modal',
  props: {
    quest: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      savePending: false,
      addTargetMode: false,
      addRewardItemMode: false,
      inputed: {
        name: '',
        description: '',
        target: 'item',
        type: 'common',
        level: '1',
        targetData: [],
        reward: {
          gold: '0',
          exp: '0',
          items: []
        },
        tData: {
          id: '',
          quantity: '1'
        },
        rewardData: {
          id: '',
          quantity: '1'
        }
      }
    }
  },
  computed: {
    showModal () {
      return this.$store.state.cpanel.modals.editQuest.show
    }
  },
  methods: {
    removeItem () {
      let self = this
      this.$dialog.confirm({
        title: self.$t('modules.quests.removeQuestT'),
        message: self.$t('modules.quests.removeQuestQ'),
        confirmButtonText: self.$t('common.remove'),
        cancelButtonText: self.$t('common.cancel'),
        showCancelButton: true,
        className: 'confirm'
      }).then(() => {
        this.$client.service('quests').remove(this.quest.id).then(() => {
          this.$toast(this.$t('modules.quests.removeOk'))
          return this.closeModal()
        }).catch(() => {
          this.$toast(this.$t('modules.quests.removeErr'))
        })
      }).catch(() => {
        return
      })
    },
    addRewardItem () {
      if (isNaN(this.inputed.rewardData.id) || this.inputed.rewardData.id <= 0) return this.$toast(this.$t('modules.quests.invalidId'))
      if (isNaN(this.inputed.rewardData.quantity) || this.inputed.rewardData.quantity <= 0) return this.$toast(this.$t('modules.quests.invalidQuantity'))
      if (this.inputed.reward.items.some(i => +i.id === +this.inputed.rewardData.id)) return this.$toast(this.$t('modules.quests.duplicateIdErr'))
      this.addRewardItemMode = false
      this.inputed.reward.items.push({
        id: this.inputed.rewardData.id,
        quantity: this.inputed.rewardData.quantity
      })
      this.inputed.rewardData.id = ''
      this.inputed.rewardData.quantity = '1'
    },
    removeRewardItem (index = null) {
      if (isNaN(index) || !this.inputed.reward.items[index] || index < 0) return false
      index ? this.inputed.reward.items.splice(index, 1) : this.inputed.reward.items.shift()
    },
    addTargetItem () {
      if (isNaN(this.inputed.tData.id) || this.inputed.tData.id <= 0) return this.$toast(this.$t('modules.quests.invalidId'))
      if (isNaN(this.inputed.tData.quantity) || this.inputed.tData.quantity <= 0) return this.$toast(this.$t('modules.quests.invalidQuantity'))
      if (this.inputed.targetData.some(i => +i.id === +this.inputed.tData.id)) return this.$toast(this.$t('modules.quests.duplicateIdErr'))
      this.addTargetMode = false
      this.inputed.targetData.push({
        id: this.inputed.tData.id,
        quantity: this.inputed.tData.quantity
      })
      this.inputed.tData.id = ''
      this.inputed.tData.quantity = '1'
    },
    removeTargetItem (index = null) {
      if (isNaN(index) || !this.inputed.targetData[index] || index < 0) return false
      index ? this.inputed.targetData.splice(index, 1) : this.inputed.targetData.shift()
    },
    save () {
      this.checkoutInputs().then(() => {
        this.savePending = true
        this.$client.service('quests').patch(this.quest.id, {
          name: this.inputed.name,
          description: this.inputed.description,
          type: this.inputed.type,
          level: this.inputed.level,
          targetType: this.inputed.target,
          targetData: this.inputed.targetData,
          rewardGold: this.inputed.reward.gold,
          rewardExp: this.inputed.reward.exp,
          rewardItems: this.inputed.reward.items
        }).then(() => {
          this.savePending = false
          this.$toast(this.$t('modules.quests.editOk'))
          this.closeModal()
        }).catch(() => {
          this.$toast(this.$t('modules.quests.editErr'))
        })
      }).catch(e => {
        this.$toast(e)
      })
    },
    checkoutInputs () {
      return new Promise((resolve, reject) => {
        if (this.inputed.name.length < 3 || this.inputed.name.length > 128) return reject(this.$t('modules.quests.nameLengthErr'))
        if (this.inputed.description.length < 10 || this.inputed.description.length > 2048) return reject(this.$t('modules.quests.descrLengthErr'))
        if (!this.inputed.targetData.length) return reject(this.$t('modules.quests.targetLengthErr'))
        if (isNaN(this.inputed.reward.gold) || isNaN(this.inputed.reward.exp) || this.inputed.reward.gold < 0 || this.inputed.reward.exp < 0) return reject(this.$t('modules.quests.rewardErr'))
        if (isNaN(this.inputed.level) || this.inputed.level < 1 || this.inputed.level > 100) return reject(this.$t('modules.quests.rewardErr'))
        return resolve()
      })
    },
    closeModal () {
      this.$store.dispatch('cpanel/SHOW_EDIT_QUEST_MODAL', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    },
  },
  mounted () {
    this.inputed = {
      ...this.inputed,
      ...this.quest
    }
    this.inputed.targetType = this.quest.target
    this.inputed.reward.gold = this.quest.rewardGold
    this.inputed.reward.exp = this.quest.rewardExp
    this.inputed.reward.items = this.quest.rewardItems
  }
}
</script>

<style>

</style>