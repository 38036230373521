<template>
  <div>
    <div class="sticky-btn center" v-if="user.role!=='usr'">
      <van-button type="primary" size="small" @click="showCreateModal" round>
        <div class="centeredInline">
          <fa-icon icon="plus-circle" style="margin-right:.25rem"></fa-icon>
          {{ $t('modules.forum.createSubforum') }}
        </div>
      </van-button>
    </div>
    <div class="centered" v-if="pending">
      <van-loading></van-loading>
    </div>
    <div v-else>
      <div v-if="items.length">
        <van-cell @click="redirect(item)" center clickable v-for="item in items" :key="item.id">
          <template #icon>
            <div class="cell-icon">
              <svg-icon name="talk"></svg-icon>
            </div>
          </template>
          <template #title>
            <fa-icon icon="lock" v-if="item.forAdmins" style="font-size:smaller;margin-right:.25rem"></fa-icon>
            {{item.name}}
          </template>
          <template #label>
            <div class="van-cell-text">
              {{ item.description || $t('modules.forum.noDescription') }}
            </div>
          </template>
          <template #extra v-if="item.hasOwnProperty('childrenCount')">
            <div class="infoTextSmall">
              {{ item.childrenCount }}
            </div>
          </template>
        </van-cell>
        <div class="load-more-manual centeredInline">
          <van-button @click.stop="onLoad" type="primary" round size="small" :loading="loadPending" :disabled="loadPending" v-if="itemsLoaded < itemsTotal">
            <div class="centeredInline">
              <fa-icon icon="sync-alt" style="margin-right:.5rem"></fa-icon>
              {{$t('common.loadMore')}}
            </div>
          </van-button>
        </div>
      </div>
      <div class="centered" v-else>
        <div class="infoText">{{ $t('modules.forum.subforumEmpty') }}</div>
      </div>
    </div>
    <create-subforum-modal v-if="user.role!=='usr'"></create-subforum-modal>
  </div>
</template>

<script>
import CreateSubforumModal from './modals/CreateSubforum.vue'
export default {
  components: {
    CreateSubforumModal
  },
  name: 'forum-index',
  data () {
    return {
      pending: true,
      loadPending: false,
      loadFinished: false,
      items: [],
      itemsLoaded: 0,
      itemsTotal: 0,
      itemCreated: null,
      itemPatched: null,
      itemRemoved: null
    }
  },
  methods: {
    onLoad () {
      if (this.itemsLoaded >= this.itemsTotal) return this.loadPending = false
      this.loadPending = true
      this.$client.service('forum').find({
        query: {
          $skip: this.itemsLoaded,
          parentId: null,
          $sort: {
            createdAt: -1,
            type: 1
          }
        }
      }).then(d => {
        if (d.data.length) {
          this.itemsLoaded+=d.data.length
          this.itemsTotal = d.total
          this.items = this.items.concat(d.data)
          this.loadPending = false
        } else {
          this.loadFinished = true
        }
        this.loadPending = false
      }).catch(e => {
        console.log(e)
        this.loadPending = false
        this.$toast(this.$t('modules.chat.fetchMessagesError'))
      })
    },
    redirect (item = null) {
      if (!item) return false
      if (!item.forAdmins) {
        return this.$router.push(`/forum/subforum/${item.id}`)
      } else {
        // return this.user.role !== 'usr' ? this.$router.push(`/forum/subforum/${item.id}`) : false
        return this.$router.push(`/forum/subforum/${item.id}`)
      }
    },
    showCreateModal () {
      this.$store.dispatch('forum/SHOW_CREATE_SUBFORUM_MODAL', true)
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.forum.title'))
  },
  async mounted () {
    this.items = await this.$client.service('forum').find({
      query: {
        type: 'subforum',
        parentId: null
      }
    }).then(d => {
      this.itemsLoaded = d.data.length
      this.itemsTotal = d.total
      return d.data
    }).catch(e => {
      console.log(e)
      this.$toast(this.$t('modules.forum.errorFetchSubforums'))
      return []
    })
    this.pending = false
    this.itemCreated = data => {
      if (data.type === 'subforum' && !data.parentId) this.items.push(data)
    }
    this.itemPatched = data => {
      if (!data.parentId) {
        const index = this.items.findIndex(s => +s.id === +data.id)
        if (index > -1) this.$set(this.items, index, data)
      }
    }
    this.itemRemoved = data => {
      if (!data.parentId) {
        const index = this.items.findIndex(s => +s.id === +data.id)
        if (index > -1) index ? this.items.splice(index, 1) : this.items.shift()
      }
    }
    this.$client.service('forum').on('created', this.itemCreated)
    this.$client.service('forum').on('updated', this.itemPatched)
    this.$client.service('forum').on('patched', this.itemPatched)
    this.$client.service('forum').on('removed', this.itemRemoved)
  },
  beforeDestroy () {
    this.$client.service('forum').removeListener('created', this.itemCreated)
    this.$client.service('forum').removeListener('updated', this.itemPatched)
    this.$client.service('forum').removeListener('patched', this.itemPatched)
    this.$client.service('forum').removeListener('removed', this.itemRemoved)
    this.itemCreated = this.itemPatched = this.itemRemoved = null
  }
}
</script>

<style>

</style>