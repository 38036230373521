<template>
  <div>
    <div class="centeredInline row">
      <h3>{{ $t('modules.start.introText') }}</h3>
      <div class="start_char_preview">
        <template v-if="selectedGender==='male'">
          <img :src="classesM[selectedClass].image" alt="" />
        </template>
        <template v-else>
          <img :src="classesF[selectedClass].image" alt="" />
        </template>
      </div>
      <div class="centeredInline row start_selectors">
        {{ $t('modules.start.selectGender') }}
        <div>
          <div class="left_arrow" @click="toggleGender"></div>
          <h4 class="centeredInline"><svg-icon :name="selectedGender==='male'?'male':'female'" style="margin-right:.25rem" size="22px"></svg-icon> {{ $t(selectedGender==='male'?'common.male':'common.female') }}</h4>
          <div class="right_arrow" @click="toggleGender"></div>
        </div>
        {{ $t('modules.start.selectClass') }}
        <div>
          <div class="left_arrow" @click="prevClass"></div>
          <h4 class="centeredInline"><svg-icon :name="classIcon[selectedClass]" style="margin-right:.25rem" size="22px"></svg-icon>{{ classesM[selectedClass].name }}</h4>
          <div class="right_arrow" @click="nextClass"></div>
        </div>
        <p class="description">
          {{ $t('modules.start.classDescr')[selectedClass] }}
        </p>
      </div>
      <van-button @click="quickSignup" :loading="suPending" plain class="greetings_btn">{{ $t('modules.start.startGameBtn') }}</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'start-game',
  data () {
    return {
      selectedClass: 0,
      selectedGender: 'male',
      suPending: false
    }
  },
  computed: {
    classIcon: {
      get () {
        return [
          "sword-1",
          "hammer",
          "staff",
          "shuriken",
          "necro-staff"
        ]
      },
      set () {}
    },
    classesM () {
      return [{
        name: this.$t('modules.start.classes')[0],
        image: '/assets/gamedata/anims/characters/warriorM.gif',
        value: 'warrior'
      },{
        name: this.$t('modules.start.classes')[1],
        image: '/assets/gamedata/anims/characters/paladinM.gif',
        value: 'paladin'
      }, {
        name: this.$t('modules.start.classes')[2],
        image: '/assets/gamedata/anims/characters/mageM.gif',
        value: 'mage'
      }, {
        name: this.$t('modules.start.classes')[3],
        image: '/assets/gamedata/anims/characters/assasinM.gif',
        value: 'assasin'
      }, {
        name: this.$t('modules.start.classes')[4],
        image: '/assets/gamedata/anims/characters/necromancerM.gif',
        value: 'necromancer'
      }]
    },
    classesF () {
      return [{
        name: this.$t('modules.start.classes')[0],
        image: '/assets/gamedata/anims/characters/warriorF.gif',
        value: 'warrior'
      },{
        name: this.$t('modules.start.classes')[1],
        image: '/assets/gamedata/anims/characters/paladinF.gif',
        value: 'paladin'
      }, {
        name: this.$t('modules.start.classes')[2],
        image: '/assets/gamedata/anims/characters/mageF.gif',
        value: 'mage'
      }, {
        name: this.$t('modules.start.classes')[3],
        image: '/assets/gamedata/anims/characters/assasinF.gif',
        value: 'assasin'
      }, {
        name: this.$t('modules.start.classes')[4],
        image: '/assets/gamedata/anims/characters/necromancerF.gif',
        value: 'necromancer'
      }]
    } 
  },
  methods: {
    async quickSignup () {
      this.suPending = true
      await this.$client.service('users').create({
        class: this.classesM[this.selectedClass].value,
        gender: this.selectedGender,
        quickstart: true
      }).then(us => {
        this.$store.dispatch('auth/authenticate', {
          email: us.email,
          password: `${us.nickname}-${us.nickname}`
        }).then(() => {
          this.$router.replace('/')
        }).catch(e => {
          console.log('ERROR AUTH AFTER QUICKSU', e)
          this.$toast(this.$t('modules.start.errorAuth'))
        })
      }).catch(e => {
        console.log('CREATE ACC ERROR', e)
        this.$toast(this.$t('modules.start.errorCreateAcc'))
      })
      this.suPending = false
    },
    prevClass () {
      if (this.selectedClass < 1) return false
      this.selectedClass--
    },
    nextClass () {
      if (this.selectedClass === 4) return false
      this.selectedClass++
    },
    toggleGender () {
      this.selectedGender = this.selectedGender === 'male' ? 'female' : 'male'
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.start.title'))
  },
  mounted () {

  }
}
</script>

<style>
@import url(../../assets/css/start.css);
</style>