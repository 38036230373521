<template>
  <van-popup
    v-model="showPopup"
    position="bottom"
    style="height:80vh"
    >
    <div class="centeredInline row chat-common-container" style="height:80vh">
      <h3>
        <fa-icon icon="globe"></fa-icon>
        {{$t('modules.chat')[chatType]}}
      </h3>
      <van-tabs swipeable sticky v-model="chatTypeSync" style="width:100%;height:100%;">
        <van-tab v-for="(type, index) in chatTypes" :title="type.text" :key="index">
          <div class="centeredInline" style="height:60vh" v-if="pending">
            <van-loading></van-loading>
          </div>
          <div v-else>
            <div class="centeredInline" style="height:60vh" v-if="!items.length">
              {{$t('modules.chat.noMessages')}}
            </div>
            <div v-else id="__messagesWrp" class="chat-messages">
              <van-cell class="chat-message" v-for="message in items" :key="message.id">
                <template #title>
                  <div class="chat-author" v-if="!message.isSystem">
                    <template v-if="chatType==='common'">
                      [{{$t(`modules.chat.${message.type}Ss`)}}]
                    </template>
                    <svg-icon size=".5rem" :name="classIcon(message.author.class)"></svg-icon>
                    {{message.author.nickname}}
                  </div>
                </template>
                <template #label>
                  <div class="chat-message" v-if="!message.isSystem">
                    [{{message.createdAt | dateShort}}]
                    {{message.text}}
                    <div v-if="user.role!=='usr'" class="chat-message-actions">
                      <span class="pointer underline" @click="removeMessage(message.id)">{{$t('common.delete')}}</span>
                    </div>
                  </div>
                  <div v-else class="centeredInline" style="color:#fff">
                    {{ message.text }}
                  </div>
                </template>
              </van-cell>
              <div class="load-more-manual centeredInline">
                <van-button @click.stop="onLoad" type="primary" round size="small" :loading="loadPending" :disabled="loadPending" v-if="itemsLoaded < itemsTotal">
                  <div class="centeredInline">
                    <fa-icon icon="sync-alt" style="margin-right:.5rem"></fa-icon>
                    {{$t('common.loadMore')}}
                  </div>
                </van-button>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
      <div class="chat-common-new-mess-wrp">
        <template v-if="!user || user.savedAccount">
        <van-button @click="inputed.type=inputed.type==='global'?'location':'global'" :disabled="chatType==='global'||chatType==='location'" style="flex:0 0 auto;margin:0 .5rem 0 0" type="info" round>
          <fa-icon :icon="inputed.type==='location'?'street-view':'globe'"></fa-icon>
        </van-button>
        <textarea v-model="inputed.message" @input="e => inputed.message = e.target.value" style="height:100%;flex:1 0 auto;margin:0 .5rem 0 0" :placeholder="$t('modules.chat.messageFieldPlch')"></textarea>
        <van-button @click="sendMessage" :loading="sendPending" :disabled="sendPending" style="flex:0 0 auto" type="info" round>{{$t('common.send')}}</van-button>
        </template>
        <template v-else>
          <div class="centeredInline smallText" style="margin:1rem auto;">{{$t('modules.chat.saveAccountError')}}</div>
        </template>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'chat-component',
  data () {
    return {
      pending: true,
      sendPending: false,
      loadPending: false,
      loadFinished: false,
      itemsLoaded: 0,
      itemsTotal: 0,
      items: [],
      inputed: {
        message: '',
        type: 'global'
      },
      messCreated: null,
      messRemoved: null,
      userOnlineHandler: null,
      userOfflineHandler: null
    }
  },
  computed: {
    chatTypeSync: {
      get () {
        switch (this.chatType) {
          case 'global':
            return 1
          case 'location':
            return 2
          default:
            return 0
        }
      },
      set (val = null) {
        if (isNaN(val)) return false
        switch (val) {
          case 1:
            this.inputed.type = 'global'
            return this.chatType = 'global'
          case 2:
            this.inputed.type = 'location'
            return this.chatType = 'location'
          default:
            return this.chatType = 'common'
        }
      }
    },
    chatTypes () {
      return [{
        text: this.$t('modules.chat.commonS')
      }, {
        text: this.$t('modules.chat.globalS')
      }, {
        text: this.$t('modules.chat.locationS')
      }]
    },
    chatType: {
      get () {
        return this.$store.state.components.popups.chat.selectedType
      },
      set (value) {
        this.$store.dispatch('components/SET_SELECTED_CHAT_TYPE', value)
      }
    },
    showPopup: {
      get () {
        return this.$store.state.components.popups.chat.show
      },
      set (value) {
        this.$store.dispatch('components/SHOW_CHAT_POPUP', value)
      }
    }
  },
  methods: {
    onLoad () {
      if (this.itemsLoaded >= this.itemsTotal) return this.loadPending = false
      this.loadPending = true
      this.$client.service('chat').find({
        query: {
          $skip: this.itemsLoaded,
          $sort: {
            createdAt: -1
          },
          ...this.chatType !== 'common' ? {
            type: this.chatType
          } : {},
          ...this.chatType === 'location' ? {
            locId: this.user.mapId
          } : {}
        }
      }).then(d => {
        if (d.data.length) {
          this.itemsLoaded+=d.data.length
          this.itemsTotal = d.total
          this.items = this.items.concat(d.data)
          this.loadPending = false
        } else {
          this.loadFinished = true
        }
        this.loadPending = false
      }).catch(e => {
        console.log(e)
        this.loadPending = false
        this.$toast(this.$t('modules.chat.fetchMessagesError'))
      })
    },
    removeMessage (mid = null) {
      if (!mid) return false
      let self = this
      this.$dialog.confirm({
        title: self.$t('modules.chat.removeMessTitle'),
        message: self.$t('modules.chat.removeMessConf'),
        confirmButtonText: self.$t('common.confirm'),
        cancelButtonText: self.$t('common.cancel'),
        showCancelButton: true,
        className: 'confirm'
      }).then(() => {
        self.$client.service('chat').remove(mid).then(() => {
          self.$toast(self.$t('modules.chat.removeMessOk'))
        }).catch(e => {
          console.log(e)
          self.$toast(self.$t('modules.chat.removeMessError'))
        })
      }).catch(() => {
        return false
      })
    },
    async sendMessage () {
      const mess = this.inputed.message.trim()
      if (mess.length < 2 || mess.length > 2048) return this.$toast(this.$t('modules.chat.messLengthError'))
      this.sendPending = true
      await this.$client.service('chat').create({
        type: this.inputed.type,
        text: this.inputed.message
      }).then(() => {
        this.inputed.message = ''
      }).catch(e => {
        console.log(e)
        this.$toast(this.$t('modules.chat.sendMessError'))
      })
      this.sendPending = false
    },
    async populateMessages () {
      this.pending = true
      this.items = await this.$client.service('chat').find({
        query: {
          $sort: {
            createdAt: -1
          },
          ...this.chatType !== 'common' ? {
            type: this.chatType
          } : {},
          ...this.chatType === 'location' ? {
            locId: this.user.mapId
          } : {}
        }
      }).then(mess => {
        this.itemsLoaded = mess.data.length
        this.itemsTotal = mess.total
        return mess.data
      }).catch(e => {
        console.log(e)
        this.$toast(this.$t('modules.chat.fetchMessagesError'))
        return []
      })
      this.pending = false
    }
  },
  watch: {
    'chatType' () {
      this.populateMessages()
    }
  },
  mounted () {
    this.populateMessages()
    this.inputed.type = 'global'
    this.messCreated = data => {
      if (this.chatType === 'location') {
        if (data.type === 'location' && +data.locId === +this.user.mapId) this.items.unshift(data)
      } else if (this.chatType === 'global') {
        if (data.type === 'global') this.items.unshift(data)
      } else this.items.unshift(data)
    }
    this.messRemoved = data => {
      const index = this.items.findIndex(m => +m.id === +data.id)
      if (index > -1) index ? this.items.splice(index, 1) : this.items.shift()
    }
    this.userOnlineHandler = data => {
      if (!data.realEvent) return false
      this.items.unshift({
        text: `${this.userInfo(data).nickname} ${this.$t('modules.chat.userOnlineMessage')}`,
        isSystem: true
      })
    }
    this.userOfflineHandler = data => {
      if (!data.realEvent) return false
      this.items.unshift({
        text: `${this.userInfo(data).nickname} ${this.$t('modules.chat.userOfflineMessage')}`,
        isSystem: true
      })
    }
    this.$client.service('chat').on('created', this.messCreated)
    this.$client.service('chat').on('removed', this.messRemoved)
    this.$client.service('signals').on('user-online', this.userOnlineHandler)
    this.$client.service('signals').on('user-offline', this.userOfflineHandler)
  },
  beforeDestroy () {
    this.$client.service('chat').removeListener('created', this.messCreated)
    this.$client.service('chat').removeListener('removed', this.messRemoved)
    this.$client.service('signals').removeListener('user-online', this.userOnlineHandler)
    this.$client.service('signals').removeListener('user-offline', this.userOfflineHandler)
    this.messCreated = this.messRemoved = this.userOnlineHandler = this.userOfflineHandler = null
  }
}
</script>

<style>
@import url(../assets/css/chat.css);
</style>