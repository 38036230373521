import client from '../services/api-client'
import Worker from 'worker-loader!../api.worker.js'

let registeredWorker = new Worker()
registeredWorker.postMessage({
  cmd: 'init'
})

export default {
  install (Vue, options) {
    Vue.prototype.$worker = registeredWorker
    Vue.prototype.$client = client
  }
}