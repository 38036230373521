<template>
  <div>
    <div class="cell-label-row">
      {{ item.description }}
    </div>
    <div class="cell-label-row">
      {{ $t('common.rarity') }}:
      <span style="margin-left:.25rem" :style="{color:$t('common.rarityColors')[item.rarity]}">
        {{ $t('common.rarityNames')[item.rarity] }}
      </span>
    </div>
    <template v-if="item.type==='wear'">
      <div class="cell-label-row">
        <svg-icon style="margin-right:.25rem" :name="$t('modules.items.equipTypes')[item.equipType].icon" size=".75rem"></svg-icon>
        {{ `${$t('modules.items.equipTypes')[item.equipType].name}, ${item.equipLevel}${$t('common.levelShort')} ` }}
      </div>
      <div class="cell-label-row">
        {{ $t('modules.items.equipClass') }}:
        <span v-for="className in item.equipClass" :key="`eclass-${className}`">
          <svg-icon :name="$t('modules.items.playerClasses')[className].icon" style="margin:0 .25rem" size=".75rem"></svg-icon>
          {{ $t('modules.items.playerClasses')[className].name }}
        </span>
      </div>
      <div class="cell-label-row">
        {{ $t('common.parameters') }}:
        <span v-for="param in Object.keys($t('modules.parameters.types'))" :key="`eq-params-${param}`">
          <span>
            <svg-icon style="margin:0 .25rem" :name="param" size=".75rem"></svg-icon>
          </span>
          <span class="infoTextSmall">
            {{ item[$t('modules.parameters.types')[param].itemKey] > 0 ? `+${item[$t('modules.parameters.types')[param].itemKey]}` : item[$t('modules.parameters.types')[param].itemKey] }}
          </span>
        </span>
      </div>
    </template>
    <template v-else-if="item.type==='resource'">
      <div class="cell-label-row">
        {{ `${$t('common.resource')}, ${item.professionLevel}${$t('common.levelShort')}, ` }}
        <svg-icon :name="$t('modules.items.professions')[item.professionType].icon" size=".75rem" style="margin:0 .25rem"></svg-icon>
        {{`${$t('modules.items.professions')[item.professionType].name}` }}
      </div>
    </template>
    <template v-else>
      {{ $t('modules.items.names')[item.type] }}
    </template>
    <div v-if="item.canStack" class="cell-label-row">
      {{ $t('modules.controlPanel.itemCanStack') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'item-info-inline-component',
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style>

</style>