<template>
  <main>
    <input type = "file" @change = "onFileSelected" ref = "uploadFileField" class = "__fileField" :accept = "acceptTypes" />
  </main>
</template>
<script>
import axios from 'axios'
import { cdnServerUrl } from '../config'
export default {
  name: 'file-upload',
  props: {
    filetype: {
      type: String,
      default: 'profile-image'
    },
    autoUpload: {
      type: Boolean,
      default: true
    },
    headers: {
      type: Object,
      default: () => { return {} }
    },
    params: {
      type: Object,
      default: () => { return {} }
    },
    trigger: {
      type: String,
      required: true
    },
    sendButton: {
      type: String
    },
    preview: {
      type: String
    },
    authRequired: {
      type: Boolean,
      default: true
    },
    clearPreview: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedFile: false,
      triggerAttempts: 0,
      sendButtonAttempts: 0,
      mimes: {
        video: ['video/mp4'],
        image: ['image/jpeg', 'image/png', 'image/gif', 'image/webp']
      }
    }
  },
  computed: {
    authHeader () {
      return {
        Authorization: `Bearer ${this.$store.state.auth.accessToken || ''}`
      }
    },
    acceptTypes () {
      let type = null
      switch (this.filetype) {
        case 'npc-sprite':
        case 'item-image':
        case 'npc-image':
          type = this.mimes.image
          break
        case 'default':
          type = null
          break
      }
      return type
    },
    uploadUrl () {
      return cdnServerUrl + 'uploads'
    }
  },
  methods: {
    processTriggerBtn () {
      if (!this.trigger) return console.error('Trigger element ID was not specified')
      const el = document.getElementById(this.trigger)
      if (!el) {
        if (this.triggerAttempts < 11) {
          this.triggerAttempts++
          return setTimeout(() => {
            this.processTriggerBtn()
          }, 100)
        } else {
          this.triggerAttempts = 0
          return console.error('Can not find trigger button by ID')
        }
      } else {
        el.onclick = () => {
          this.$refs.uploadFileField.click()
        }
      }
    },
    processSendBtn () {
      if (!this.sendButton) return false
      const el = document.getElementById(this.sendButton)
      if (!el) {
        if (this.sendButtonAttempts < 11) {
          this.sendButtonAttempts++
          return setTimeout(() => {
            this.processSendBtn()
          }, 100)
        } else {
          this.sendButtonAttempts = 0
          return console.error('Can not find send button by ID')
        }
      } else {
        el.onclick = () => {
          this.onUpload()
        }
      }
    },
    clearPreviews () {
      let oldP = document.querySelectorAll('#' + this.preview + '> .__uploadPreview')
      oldP.forEach(node => {
        node.parentNode.removeChild(node)
      })
    },
    onFileSelected (e) {
      if (e.target.files[0].type.startsWith('image/') && this.preview) {
        this.clearPreviews()
        const img = document.createElement("img")
        img.classList.add('__uploadPreview')
        img.style.objectFit = 'cover'
        img.style.height = 'inherit'
        img.style.width = 'inherit'
        img.style.borderRadius = 'inherit'
        img.file = e.target.files[0]
        document.getElementById(this.preview).appendChild(img)
        const reader = new FileReader()
        reader.onload = (function(aImg) {
          return function(i) {
            aImg.src = i.target.result
            }
          })(img)
        reader.readAsDataURL(e.target.files[0])
      }
      this.selectedFile = e.target.files[0]
      if (this.autoUpload) this.onUpload()
      this.$emit('on-selected', this.selectedFile)
    },
    onUpload () {
      const params = this.params === undefined ? {} : this.params
      let h = this.headers === undefined ? {} : this.headers
      h = {
        ...h,
        ...{
          type: this.filetype
        }
      }
      const headers = this.authRequired ? Object.assign(h, this.authHeader) : this.headers
      const formData = new FormData()
      formData.append('files', this.selectedFile, this.selectedFile.name)
      formData.append('type', this.filetype)
      Object.keys(params).map((objectKey, index) => {
        formData.append(objectKey, params[objectKey])
      })
      this.$emit('on-upload')
      axios.post(this.uploadUrl, formData, {
        headers: headers,
        onUploadProgress: (progress) => {
          this.$emit('on-progress', Math.round((progress.loaded / progress.total) * 100))
        }
      }).then(
        (r) => {
          this.$emit('on-success', {
            status: r.status,
            statusText: r.statusText,
            data: r.data
          })
          if (this.clearPreview) this.clearPreviews()
        }).catch(e => {
        this.$emit('on-error', e)
      })
    }
  },
  mounted () {
    if (this.trigger) this.processTriggerBtn()
    if (this.sendButton) this.processSendBtn()
  }
}
</script>

<style scoped>
.__fileField {
  display: none;
}
</style>
