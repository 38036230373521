<template>
  <van-overlay :z-index="11" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="modalTitle centeredInline">{{ quest.name }}</div>
        <div class="tiny-text centeredInline">{{ $t(`modules.quests.${isQuestDone(quest)?'questDone':'questPending'}`) }}</div>
        <p style="margin:0">
          {{ quest.description }}
        </p>
        <van-divider dashed>{{ $t('common.information') }}</van-divider>
        {{ `${$t('modules.quests.type')}: ${$t('modules.quests.types')[quest.type].title}` }}<br>
        {{ `${$t('modules.quests.level')}: ${quest.level} ${$t('common.levelShort')}` }}<br>
        {{ `${$t('modules.quests.questAcceptedFrom')}: ` }}
        <van-loading style="display:inline-block" size="1rem" v-if="npcPending"></van-loading>
        <template v-else>
          <span class="pointer underline" @click="$store.dispatch('components/SET_NPC_INFO', { npc, show: true })" v-if="npc.name" style="color:goldenrod">{{ npc.name }}</span>
          <span v-else>{{ $t('common.unknown') }}</span>
        </template>
        <van-divider dashed>{{ $t('modules.quests.target') }}</van-divider>
          <svg-icon size="1rem" :name="quest.targetType==='monster'?'sword-1':'bag'"></svg-icon>
          {{ $t(`modules.quests.${quest.targetType==='item'?'needGatherItems':'needKill'}`) }}:
          <span v-for="(qi, qindex) in quest.targetData" :key="`q-td-t-${qi.id}-${qindex}`">
            <svg-icon v-if="quest.targetType==='item'" :name="$t('modules.items.categoriesIcons')[qi.type]" size=".8rem"></svg-icon>
            <span @click="$store.dispatch(quest.targetType==='item'?'components/SET_ITEM_INFO':'components/SET_MONSTER_INFO', { item: quest.targetType==='item'?qi:null, monster: quest.targetType==='monster'?qi:null, show: true })" class="underline pointer" :class="`rarity-${qi.rarity}`">
              {{ qi.name }}
            </span>
            <span style="font-size:small" v-if="qi.quantity > 1">
              <template v-if="quest.targetType === 'monster'">
                <span :style="{color:qi.quantity > actEntry.data[actEntry.data.map(a => +a.id).indexOf(+qi.id)].killed?'#e06647':'#68aa1a'}" v-if="actEntry.data.map(a => +a.id).indexOf(+qi.id) > -1">
                  {{ actEntry.data[actEntry.data.map(a => +a.id).indexOf(+qi.id)].killed }}
                </span>
                /
              </template>
              <template v-if="quest.targetType === 'item'">
                <span :style="{color:qi.quantity > rackItemsData.get(qi.id).stackCount?'#e06647':'#68aa1a'}" v-if="rackItemsData.has(qi.id)">
                  {{ rackItemsData.get(qi.id).stackCount }}
                </span>
                /
              </template>
              {{ `${qi.quantity}` }}
            </span>
            {{`${quest.targetData.length>qindex+1?' ,':''}`}}
          </span>
        <van-divider dashed>{{ $t('modules.quests.reward') }}</van-divider>
          <ul class="ml-05 mb-05">
            <li>
              {{ `${$t('modules.parameters.exp')}: +${quest.rewardExp}` }} <exp-icon size="1rem" style="margin:0"></exp-icon>
            </li>
            <li>
              {{ `${$t('common.coins')}: +${quest.rewardGold}` }} <coin-icon size="1rem" style="margin:0"></coin-icon>
            </li>
            <li v-if="quest.rewardItems.length">
              {{ `${$t('modules.quests.items')}: ` }}
              <span v-for="(ri, rindex) in quest.rewardItems" :key="`q-td-t-${ri.id}-${rindex}`"><svg-icon class="mr-05" :name="$t('modules.items.categoriesIcons')[ri.type]" size=".8rem"></svg-icon><span :class="`rarity-${ri.rarity}`" @click="$store.dispatch('components/SET_ITEM_INFO', { item: ri, show: true })" class="underline pointer">{{ ri.name }}</span>{{ `${ri.quantity>1?` x${ri.quantity}`:''}` }}{{`${quest.rewardItems.length>rindex+1?' ,':''}`}}</span>
            </li>
          </ul>
        <div class="centeredInline row">
          <div class="tiny-text centeredInline">{{ $t(`modules.quests.${isQuestDone(quest)?'questDone':'questPending'}`) }}</div>
          <van-button class="mt-05" @click="cancelQuest" size="small" type="warning">{{ $t('modules.quests.cancelQuest') }}</van-button>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: 'quests-quest-info-modal',
  props: {
    quest: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      npcPending: true,
      npc: null,
      actEntry: null,
      rackItemsData: new Map()
    }
  },
  computed: {
    showModal () {
      return this.$store.state.quests.modals.questInfo.show
    }
  },
  methods: {
    cancelQuest () {
      let self = this
      this.$dialog.confirm({
        title: self.$t('modules.quests.cancelQuest'),
        message: `${self.isQuestDone(self.quest)?`${self.$t('modules.quests.questDone')}.`:''} ${self.$t('modules.quests.cancelQ')}`,
        confirmButtonText: self.$t('common.confirm'),
        cancelButtonText: self.$t('common.cancel'),
        showCancelButton: true,
        className: 'confirm'
      }).then(() => {
        self.$client.service('acts').remove(null, {
          query: {
            cancelQuest: true,
            questId: self.quest.id
          }
        }).then(() => {
          self.$toast(self.$t('modules.quests.cancelDone'))
          this.closeModal()
        }).catch(() => {
          self.$toast(self.$t('modules.quests.cancelErr'))
        })
      }).catch(() => {
        return false
      })
    },
    async checkRackItems () {
      if (this.quest.targetType !== 'item') return false
      await Promise.all(this.quest.targetData.map(td => {
        const itemPosition = this.rackItems.findIndex(rItem => +rItem.itemId === +td.id)
        if (itemPosition > -1) {
          this.rackItemsData.set(td.id, this.rackItems[itemPosition])
        }
      }))
    },
    closeModal () {
      this.$store.dispatch('quests/SHOW_QUEST_INFO', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    }
  },
  async mounted () {
    if (this.quest.targetType === 'monster') {
      const index = this.uActions.monstersData.findIndex(monster => +monster.questId === +this.quest.id)
      if (index > -1) this.actEntry = this.uActions.monstersData[index]
    } else if (this.quest.targetType === 'item') {
      this.checkRackItems()
    }
    let questGiverId = this.getQuestGiverId(this.quest.id)
    if (!isNaN(questGiverId)) {
      this.npc = await this.$client.service('npc').get(questGiverId).catch(() => null)
      this.npcPending = false
    } else this.npcPending = false
  }
}
</script>

<style>

</style>