import client from '../../services/api-client'

const state = {
  entry: null
}
const getters = {}
const mutations = {
  setEntry (state, entry) {
    state.entry = entry
  }
}
const actions = {
  SET_ENTRY ({ commit }, entry = null) {
    if (!entry) return false
    commit('setEntry', entry)
  },
  RESOLVE_ENTRY ({ dispatch }, entryId = null) {
    if (!entryId) return false
    return new Promise((resolve, reject) => {
      client.service('settings').get(entryId).then(entry => {
        dispatch('SET_ENTRY', entry)
        resolve(entry)
      }).catch(e => {
        reject(e)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}