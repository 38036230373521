const state = {
  modals: {
    createSubforum: {
      show: false
    },
    editSubforum: {
      show: false
    },
    createThread: {
      show: false
    }
  }
}
const getters = {}
const mutations = {
  setShowCreateSubforumModal (state, bool) {
    state.modals.createSubforum.show = bool
  },
  setShowEditSubforumModal (state, bool) {
    state.modals.editSubforum.show = bool
  },
  setShowCreateThreadModal (state, bool) {
    state.modals.createThread.show = bool
  }
}
const actions = {
  SHOW_CREATE_SUBFORUM_MODAL ({ commit }, bool = false) {
    commit('setShowCreateSubforumModal', bool)
  },
  SHOW_EDIT_SUBFORUM_MODAL ({ commit }, bool = false) {
    commit('setShowEditSubforumModal', bool)
  },
  SHOW_CREATE_THREAD_MODAL ({ commit }, bool = false) {
    commit('setShowCreateThreadModal', bool)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}