<template>
  <van-overlay :z-index="200" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline row">
          <div class="modalTitle van-ellipsis">{{ `${$t('modules.forum.threadCreateTitle')}` }}</div>
          <van-form style="width:100%" @submit="onSubmit" ref="form">
            <div class="infoText">{{$t('modules.forum.threadName')}}</div>
            <van-field
              type="text"
              v-model.trim="inputed.name"
              name="name"
              :placeholder="$t('modules.forum.threadName')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <div class="infoText">{{$t('modules.forum.threadMessage')}}</div>
            <van-field
              type="textarea"
              v-model.trim="inputed.message"
              name="message"
              maxlength="2048"
              show-word-limit
              :placeholder="$t('modules.chat.messageFieldPlch')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <div style="margin: 16px;">
              <van-button :loading="savePending" :disabled="savePending" round plain block class="greetings_btn" native-type="submit">
                {{ $t('common.save') }}
              </van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'forum-create-thread-modal',
  props: {
    parent: {
      type: Object,
      default: () => {},
      required: true
    },
    parentId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      savePending: false,
      inputed: {
        name: '',
        message: ''
      }
    }
  },
  computed: {
    ...mapState({
      showModal: state => state.forum.modals.createThread.show
    })
  },
  methods: {
    closeModal () {
      this.$store.dispatch('forum/SHOW_CREATE_THREAD_MODAL', false)
      this.inputed.name = ''
      this.inputed.message = ''
      this.savePending = false
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    },
    async onSubmit () {
      if (this.inputed.message.length < 12 || this.inputed.message.length > 2048) return this.$toast(this.$t('modules.forum.postLengthError'))
      if (this.inputed.name.length < 5 || this.inputed.name.length > 128) return this.$toast(this.$t('modules.forum.subforumNameLengthError'))
      const checkout = await this.$refs.form.validate()
      if (!checkout) {
        this.savePending = true
        this.$client.service('forum').create({
          type: 'thread',
          parentId: this.parentId,
          name: this.inputed.name,
          description: null,
          adminsOnly: this.parent.adminsOnly || false,
          canCreateThreads: false
        }).then(thread => {
          this.$client.service('forum-messages').create({
            text: this.inputed.message,
            threadId: thread.id,
            isHeadPost: true
          }).then(() => {
            this.$toast(this.$t('modules.forum.threadCreateOk'))
            this.savePending = false
            this.closeModal()
            this.$router.push(`/forum/thread/${thread.id}`)
          }).catch(e => {
            console.log('Error create thread message', e)
            this.$toast(this.$t('modules.forum.threadCreateError'))
            this.savePending = false
          })
        }).catch(e => {
          console.log('Error create thread', e)
          this.$toast(this.$t('modules.forum.threadCreateError'))
          this.savePending = false
        })
      }
    }
  },
  mounted () {
  }
}
</script>

<style>

</style>