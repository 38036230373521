<template>
  <div>
    <div class="centered" v-if="pending">
      <van-loading></van-loading>
    </div>
    <div class="centeredInline row" v-else>
      <h4>{{ $t('modules.controlPanel.initialMapI') }}</h4>
        <van-form @submit="onSubmit" ref="form">
          <div class="smallText" style="margin:1rem 0;">
            1. {{ $t('modules.controlPanel.initialMapI') }}
          </div>
          <van-field
            type="text"
            v-model="inputed.initialMapNumber"
            name="title"
            :label="$t('common.number')"
            :placeholder="$t('modules.controlPanel.initialMapNumber')"
            :rules="[{ required: true, message: $t('common.requiredField') }]"
          />
          <div class="smallText" style="margin:1rem 0;">
            2. {{ $t('modules.controlPanel.initialMapCoors') }}
          </div>
          <van-field
            type="number"
            v-model="inputed.initialCoorsX"
            name="coorsX"
            label="X"
            :placeholder="$t('common.coorsX')"
            :rules="[{ required: true, message: $t('common.requiredField') }]"
          />
          <van-field
            v-model="inputed.initialCoorsY"
            type="number"
            name="coorsY"
            label="Y"
            :placeholder="$t('common.coorsX')"
            :rules="[{ required: true, message: $t('common.requiredField') }]"
          />
          <div style="margin: 16px;">
            <van-button :loading="savePending" :disabled="savePending" round plain block class="greetings_btn" native-type="submit">
              {{ $t('common.save') }}
            </van-button>
          </div>
        </van-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'control-panel-app_settings',
  data () {
    return {
      pending: true,
      savePending: false,
      config: null,
      settPatched: null,
      inputed: {
        initialMapNumber: '',
        initialCoorsX: '',
        initialCoorsY: ''
      }
    }
  },
  methods: {
    async onSubmit () {
      const checkout = await this.$refs.form.validate()
      if (!checkout) {
        this.savePending = true
        await this.$client.service('app-settings').patch(1, {
          initialMap: this.inputed.initialMapNumber,
          initialCoorsX: this.inputed.initialCoorsX,
          initialCoorsY: this.inputed.initialCoorsY
        }).then(() => {
          this.$toast(this.$t('common.saveOk'))
        }).catch(e => {
          console.log('ERROR SAVING DATA', e)
          this.$toast(this.$t('common.saveError'))
        })
        this.savePending = false
      }
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.controlPanel.appSettingsTitle'))
  },
  async mounted () {
    this.config = await this.$client.service('app-settings').get(1).then(c => {
      this.inputed.initialMapNumber = c.initial_coors.map
      this.inputed.initialCoorsX = c.initial_coors.x
      this.inputed.initialCoorsY = c.initial_coors.y
      return c
    }).catch(e => {
      console.log(e)
      this.$toast(this.$t('common.fetchDataError'))
      return null
    })
    this.pending = false
    this.settPatched = data => {
      if (+data.id === 1) {
        this.inputed.initialMapNumber = data.initialMap
        this.inputed.initialCoorsX = data.initialCoorsX
        this.inputed.initialCoorsY = data.initialCoorsY
      }
    }
    this.$client.service('app-settings').on('updated', this.settPatched)
    this.$client.service('app-settings').on('patched', this.settPatched)
  },
  beforeRemoved () {
    this.$client.service('app-settings').removeListener('updated', this.settPatched)
    this.$client.service('app-settings').removeListener('patched', this.settPatched)
    this.settPatched = null
  }
}
</script>

<style>

</style>