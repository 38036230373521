import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import auth from './modules/auth'
import cpanel from './modules/cpanel'
import components from './modules/components'
import settings from './modules/settings'
import forum from './modules/forum'
import combat from './modules/combat'
import rack from './modules/rack'
import uactions from './modules/uactions'
import quests from './modules/quests'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    auth,
    cpanel,
    components,
    settings,
    forum,
    combat,
    rack,
    uactions,
    quests
  }
})
export default store