<template>
    <van-overlay :z-index="50" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline row">
          <div class="modalTitle van-ellipsis">{{ `${$t('modules.character.saveCharacterTitle')}` }}</div>
          <h3 v-if="userinfo"><svg-icon :name="classIcon(user.class)" size="1rem"></svg-icon> {{ `${userinfo.nickname}, ${$t(`common.${userinfo.class}`)}, ${user.level} ${$t('common.levelShort')}` }}</h3>
          <div class="smallText" style="margin:0 0 1rem 0;">{{ $t('modules.character.saveCharacterDescr') }}</div>
          <van-form @submit="onSubmit" ref="form">
            <van-field
              type="text"
              v-model.trim="inputed.nickname"
              name="nickname"
              :label="$t('common.nickname')"
              :placeholder="$t('common.nickname')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <van-field
              type="email"
              v-model.trim="inputed.email"
              name="email"
              :label="$t('common.email')"
              :placeholder="$t('common.email')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <van-field
              type="password"
              v-model.trim="inputed.password"
              name="password"
              :label="$t('common.password')"
              :placeholder="$t('common.password')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
            <van-field
              type="password"
              v-model.trim="inputed.password2"
              name="password2"
              :label="$t('modules.greetings.repeatPassword')"
              :placeholder="$t('modules.greetings.repeatPassword')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
            <div style="margin: 16px;">
              <van-button :loading="savePending" :disabled="savePending" round plain block class="greetings_btn" native-type="submit">
                {{ $t('common.save') }}
              </van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </van-overlay>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'save-character-modal',
  data () {
    return {
      savePending: false,
      userinfo: null,
      inputed: {
        nickname: '',
        email: '',
        password: '',
        password2: ''
      }
    }
  },
  computed: {
    ...mapState({
      showModal: state => state.common.modals.saveCharacter.show
    })
  },
  methods: {
    closeModal () {
      this.$store.dispatch('common/SHOW_SAVE_CHARACTER_MODAL', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    },
    async onSubmit () {
      const checkout = await this.$refs.form.validate()
      let nickname = this.inputed.nickname.trim()
      if (this.password !== this.password2) return this.$toast(this.$t('common.errorOccured'))
      if (nickname.length < 3 || nickname.length > 14) return this.$toast(this.$t('modules.character.charNicknameLengthError'))      
      if (!checkout) {
        await this.$client.service('authentication/saveAccount').create({
          nickname: nickname,
          email: this.inputed.email,
          password: this.inputed.password
        }).then(() => {
          this.$toast(this.$t('modules.character.saveCharacterOk'))
        }).catch(e => {
          this.$toast(this.$t(e.message==='ERROR_SAVING_CHARACTER:DB_ERROR'?'modules.greetings.emailOrNicknameError':'modules.character.saveCharacterError'))
        })
        this.closeModal()
      }
    }
  },
  mounted () {
    this.userinfo = this.userInfo()
  }
}
</script>

<style>

</style>