import { fromUnixTime, formatDistanceToNow ,lightFormat, format, isToday, isYesterday, isThisYear, parseISO, addSeconds } from 'date-fns'
import { locale } from '../config'
import ru from 'date-fns/locale/ru'
import en from 'date-fns/locale/en-US'
const locales = {
  en: en,
  ru: ru
}
export default {
  filters: {
    date (value) {
      value = fromUnixTime(value) || value
      let formatString = isToday(value) ? 'H:mm' : isYesterday(value) ? ', H:mm' : isThisYear(value) ? 'H:mm, d MMMM' : 'H:mm, d MMMM yyyy'
      let formatted = format(value, formatString, {
        locale: locales[locale]
      })
      return isYesterday(value) ? 'вчера' + formatted : isToday(value) ? 'сегодня в ' + formatted : formatted
    },
    dateJS (value) {
      value = parseISO(value) || value
      let formatString = isToday(value) ? 'H:mm' : isYesterday(value) ? ', H:mm' : isThisYear(value) ? 'H:mm, d MMMM' : 'H:mm, d MMMM yyyy'
      let formatted = format(value, formatString, {
        locale: locales[locale]
      })
      return isYesterday(value) ? 'вчера' + formatted : isToday(value) ? 'сегодня в ' + formatted : formatted
    },
    dateShort (value) {
      value = parseISO(value) || value
      let formatString = isToday(value) ? 'H:mm' : isYesterday(value) ? 'd MMMM' : isThisYear(value) ? 'd MMMM' : 'H:mm, d MMMM yyyy'
      let formatted = format(value, formatString, {
        locale: locales[locale]
      })
      return isYesterday(value) ? formatted : isToday(value) ? formatted : formatted
    },
    dateOnly (value) {
      value = parseISO(value) || value
      let formatString = 'd MMMM yyyy'
      return format(value, formatString, { locale: ru })
    },
    countdown (value) {
      return lightFormat(addSeconds(new Date(0), value + (+new Date().getTimezoneOffset()) * 60), 'HH:mm:ss')
    },
    getAge (dateString) {
      var today = new Date()
      var birthDate = new Date(dateString)
      var age = today.getFullYear() - birthDate.getFullYear()
      var m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--
      return age
    },
    ago (value) {
      value = fromUnixTime(value) || value
      return formatDistanceToNow(value, {
        locale: locales[locale]
      })
    },
    secToTime (sec = 0) {
      return sec >= 3600 ? new Date(sec * 1000).toISOString().substring(11, 19) : new Date(sec * 1000).toISOString().substring(14, 19)
    }
  },
  create (Vue) {
    Object.keys(this.filters).forEach(function (filter, _) {
      Vue.filter(filter, this.filters[filter])
    }.bind(this))
  }
}
