<template>
  <div>
    <div class="sticky-btn centeredInline">
      <van-button round size="small" type="primary" @click="$store.dispatch('cpanel/SHOW_ADD_QUEST_MODAL', true)">
        <div class="centeredInline">
          <fa-icon icon="plus-circle" style="margin-right:.5rem"></fa-icon>
          {{ $t('common.add') }}
        </div>
      </van-button>
    </div>
    <div class="centered" v-if="pending">
      <van-loading></van-loading>
    </div>
    <div v-else>
      <div class="centered" v-if="!items.length">
        <div class="infoText">{{ $t('modules.controlPanel.questsEmpty') }}</div>
      </div>
      <template v-else>
        <van-cell @click="openEditModal(item)" clickable v-for="item in items" :key="`q-item-${item.id}`">
          <template #icon>
            <div class="centeredInline infoText" style="margin:0 .5rem">
              {{ `ID ${item.id}` }}
            </div>
          </template>
          <template #title>
            <b>{{ `${item.name} (${item.level} ${$t('common.levelShort')})` }}</b><br>
            {{ `${$t('modules.quests.description')}: ${item.description}` }}<br>
            {{ `${$t('modules.quests.type')}: ${$t('modules.quests.types')[item.type].title}` }}<br>
            {{ `${$t(`modules.quests.${item.targetType==='item'?'needGatherItems':'needKill'}`)}: ` }}
            <span v-for="(tdata, index) in item.targetData" :key="`tdata-it-${tdata.id}-${index}`"><span :class="`rarity-${tdata.rarity}`">{{ `${tdata.name} `}}</span>{{`x${tdata.quantity}` }}<span class="mr-05" v-if="index+1<item.targetData.length">, </span></span>
            <br>
            {{ `${$t('modules.quests.rewardInfo')}: +${item.rewardGold}` }} <coin-icon size="0.8rem" style="margin-left:0"></coin-icon>
            {{ `+${item.rewardExp}` }}<exp-icon size="0.8rem" style="margin-left:.25rem"></exp-icon>
            <template v-if="item.rewardItems.length"><br>
              {{ `${$t('modules.quests.rewardItems')}: ` }}
              <span v-for="(rit, index) in item.rewardItems" :key="`rit-it-${rit.id}-${index}`"><span :class="`rarity-${rit.rarity}`">{{ `${rit.name} `}}</span><span class="mr-05" v-if="index+1<item.rewardItems.length">, </span></span>
            </template>
          </template>
        </van-cell>
        <div class="load-more-manual centeredInline">
          <van-button @click.stop="onLoad" type="primary" round size="small" :loading="loadPending" :disabled="loadPending" v-if="itemsLoaded < itemsTotal">
            <div class="centeredInline">
              <fa-icon icon="sync-alt" style="margin-right:.5rem"></fa-icon>
              {{$t('common.loadMore')}}
            </div>
          </van-button>
        </div>
      </template>
    </div>
    <add-quest :key="`a-q-${modalKey}`" @rerender="modalKey++"></add-quest>
    <edit-quest v-if="selectedItem" :quest="selectedItem" @rerender="selectedItem=null"></edit-quest>
  </div>
</template>

<script>
import AddQuest from './modals/AddQuest.vue'
import EditQuest from './modals/EditQuest.vue'
export default {
  name: 'cpanel-quests-index',
  components: {
    AddQuest,
    EditQuest
  },
  data () {
    return {
      pending: true,
      loadPending: false,
      loadFinished: false,
      selectedItem: null,
      modalKey: 0,
      items: [],
      itemsTotal: 0,
      itemsLoaded: 0,
      itemCreated: null,
      itemPatched: null,
      itemRemoved: null
    }
  },
  methods: {
    openEditModal (quest = null) {
      if (!quest) return false
      this.selectedItem = quest
      this.$nextTick(() => {
        this.$store.dispatch('cpanel/SHOW_EDIT_QUEST_MODAL', true)
      })
    },
    onLoad () {
      if (this.itemsLoaded >= this.itemsTotal) return this.loadPending = false
      this.loadPending = true
      this.$client.service('quests').find({
        query: {
          $skip: this.itemsLoaded,
          $sort: {
            createdAt: -1
          }
        }
      }).then(d => {
        if (d.data.length) {
          this.itemsLoaded+=d.data.length
          this.itemsTotal = d.total
          this.items = this.items.concat(d.data)
          this.loadPending = false
        } else {
          this.loadFinished = true
        }
        this.loadPending = false
      }).catch(() => {
        this.loadPending = false
        this.$toast(this.$t('modules.fetchDataError'))
      })
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.controlPanel.manageQuests'))
  },
  async mounted () {
    this.items = await this.$client.service('quests').find({
      query: {
        $sort: {
          createdAt: -1
        }
      }
    }).then(q => {
      this.itemsLoaded = q.data.length
      this.itemsLoaded = q.total
      return q.data
    }).catch(e => {
      console.log(e)
      this.$toast(this.$t('common.fetchDataError'))
      return []
    })
    this.pending = false
    this.itemCreated = data => {
      this.items.push(data)
    }
    this.itemPatched = data => {
      let index = this.items.findIndex(i => +i.id === +data.id)
      if (index > -1) this.$set(this.items, index, data)
    }
    this.itemRemoved = data => {
      let index = this.items.findIndex(i => +i.id === +data.id)
      if (index > -1) index ? this.items.splice(index, 1) : this.items.shift()
    }
    let qService = this.$client.service('quests')
    qService.on('created', this.itemCreated)
    qService.on('updated', this.itemPatched)
    qService.on('patched', this.itemPatched)
    qService.on('removed', this.itemRemoved)
  },
  beforeDestroy () {
    let qService = this.$client.service('quests')
    qService.removeListener('created', this.itemCreated)
    qService.removeListener('updated', this.itemPatched)
    qService.removeListener('patched', this.itemPatched)
    qService.removeListener('removed', this.itemRemoved)
    this.itemCreated = this.itemPatched = this.itemRemoved = null
  }
}
</script>

<style>

</style>