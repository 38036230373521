const state = {
  items: [],
  size: 50,
  modals: {
    itemInfo: {
      show: false
    }
  }
}
const getters = {}
const mutations = {
  setShowItemInfo (state, bool) {
    state.modals.itemInfo.show = bool
  },
  setItems (state, items) {
    state.items = items
  },
  setSize (state, size) {
    state.size = size
  },
  addItem (state, item) {
    const index = state.items.findIndex(i => +i.id === +item.id)
    if (index > -1) {
      state.items[index] = item
    } else {
      state.items = [
        ...state.items,
        ...[item]
      ]
    }
  },
  setItem (state, item) {
    const index = state.items.findIndex(i => +i.id === +item.id)
    if (index > -1) {
      state.items[index] = item
    }
  },
  removeItem (state, id) {
    const index = state.items.findIndex(i => +i.id === +id)
    if (index > -1) index ? state.items.splice(index, 1) : state.items.shift()
  }
}
const actions = {
  SHOW_ITEM_INFO ({ commit }, bool = false) {
    commit('setShowItemInfo', bool)
  },
  SET_ITEMS ({ commit }, items = []) {
    commit('setItems', items)
  },
  SET_SIZE({ commit }, size = 50) {
    commit('setSize', size)
  },
  ADD_ITEM ({ commit }, item = null) {
    if (!item) return false
    commit('addItem', item)
  },
  SET_ITEM ({ commit }, item = null) {
    if (!item) return false
    commit('setItem', item)
  },
  REMOVE_ITEM ({ commit }, id = null) {
    if (!id) return false
    commit('removeItem', id)
  },
  FLUSH_RACK ({ commit }) {
    commit('setSize', 50)
    commit('setItems', [])
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}