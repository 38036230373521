<template>
  <div>
      <div class="sticky-btn center" v-if="selectedLoc">
        <van-button size="small" type="warning" @click="selectedLoc = null" round style="margin:0 .5rem 0 0">{{ $t('common.backToSelection') }}</van-button>
        <van-button type="primary" size="small" round @click="$store.dispatch('cpanel/SHOW_ADD_MONSTER_MODAL', true)">
          <div class="centeredInline">
            <fa-icon icon="plus-circle" style="margin-right:.25rem"></fa-icon>
            {{ $t('common.add') }}
          </div>
        </van-button>
      </div>
    <div class="centeredInline row" v-if="!selectedLoc">
      <div class="infoText">
        {{ `1. ${$t('modules.controlPanel.selectLocation')}` }}
      </div>
      <map-visualizer-inline @loc-selected="locSelected"></map-visualizer-inline>
    </div>
    <template v-else>
      <div class="centered" v-if="pending">
        <van-loading></van-loading>
      </div>
      <div v-else>
        <div v-if="monsters.length">
          <van-cell @click="editMonster(monster)" size="large" clickable v-for="monster in monsters" :key="monster.id">
            <template #title>
              <span style="font-size:120%">
                {{ `${monster.name} (ID ${monster.id})` }}
              </span>
            </template>
            <template #icon>
              <div class="cell-icon">
                <img :src="cdnPath(monster.image)" alt="" v-if="monster.image" />
                <svg-icon name="monster-common" v-else></svg-icon>
              </div>
            </template>
            <template #label>
              <monster-info size="small" :monster="monster"></monster-info>
            </template>
          </van-cell>
          <div class="load-more-manual centeredInline">
            <van-button @click.stop="onLoad" type="primary" round size="small" :loading="loadPending" :disabled="loadPending" v-if="itemsLoaded < itemsTotal">
              <div class="centeredInline">
                <fa-icon icon="sync-alt" style="margin-right:.5rem"></fa-icon>
                {{$t('common.loadMore')}}
              </div>
            </van-button>
          </div>
        </div>
        <div class="centered" v-else>
          <div class="infoText">
            {{ $t('modules.controlPanel.emptyMonstersList') }}
          </div>
        </div>
      </div>
      <add-monster-modal :key="`amm-${addModalKey}`" @rerender="rerenderAddModal" v-if="selectedLoc" :loc-id="selectedLoc"></add-monster-modal>
      <edit-monster-modal v-if="selectedMonster" :monster="selectedMonster" :key="`emmk-${emmk}`" @rerender="selectedMonster=null"></edit-monster-modal>
    </template>
  </div>
</template>
<script>
import MonsterInfo from '../../../components/MonsterInfo.vue'
import MapVisualizerInline from '../../../components/MapVisualizerInline.vue'
import AddMonsterModal from './modals/AddMonster.vue'
import EditMonsterModal from './modals/EditMonster.vue'
export default {
  name: 'cpanel-manage-monsters',
  components: {
    MapVisualizerInline,
    AddMonsterModal,
    MonsterInfo,
    EditMonsterModal
  },
  data () {
    return {
      pending: true,
      loadPending: false,
      loadFinished: false,
      selectedMonster: null,
      emmk: 0,
      monsters: [],
      itemsTotal: 0,
      itemsLoaded: 0,
      itemCreated: null,
      itemPatched: null,
      itemRemoved: null,
      selectedLoc: null,
      addModalKey: 0
    }
  },
  methods: {
    editMonster (monster = null) {
      if (!monster) return false
      this.selectedMonster = monster
      this.$nextTick(() => {
        this.$store.dispatch('cpanel/SHOW_EDIT_MONSTER_MODAL', true)
      })
    },
    onLoad () {
      if (this.itemsLoaded >= this.itemsTotal) return this.loadPending = false
      this.loadPending = true
      this.$client.service('monsters').find({
        query: {
          $skip: this.itemsLoaded,
          $sort: {
            createdAt: -1,
          },
          locationId: this.selectedLoc
        }
      }).then(d => {
        if (d.data.length) {
          this.itemsLoaded+=d.data.length
          this.itemsTotal = d.total
          this.items = this.items.concat(d.data)
          this.loadPending = false
        } else {
          this.loadFinished = true
        }
        this.loadPending = false
      }).catch(e => {
        console.log(e)
        this.loadPending = false
        this.$toast(this.$t('common.fetchDataError'))
      })
    },
    async fetchMonstersList () {
      this.monsters = await this.$client.service('monsters').find({
        query: {
          $sort: {
            createdAt: -1,
          },
          locationId: this.selectedLoc
        }
      }).then(m => {
        this.itemsLoaded = m.data.length
        this.itemsTotal = m.total
        return m.data
      }).catch(e => {
        console.log(e)
        this.$toast(this.$t('common.fetchDataError'))
        return []
      })
      this.pending = false
    },
    locSelected (locId = null) {
      if (!locId || isNaN(locId)) return console.error('Error selecting location')
      this.selectedLoc = locId
    },
    rerenderAddModal () {
      this.addModalKey++
    }
  },
  watch: {
    'selectedLoc' () {
      if (this.selectedLoc) {
        this.fetchMonstersList()
      } else {
        this.pending = true
        this.monsters = []
      }
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.controlPanel.manageMonstersTitle'))
  },
  mounted () {
    this.itemCreated = data => {
      if (this.selectedLoc && +data.locationId === +this.selectedLoc) {
        this.monsters.unshift(data)
      }
    }
    this.itemPatched = data => {
      if (this.selectedLoc && +data.locationId === +this.selectedLoc) {
        const index = this.monsters.findIndex(m => +m.id === +data.id)
        if (index > -1) {
          this.$set(this.monsters, index, data)
        }
      }
    }
    this.itemRemoved = data => {
      if (this.selectedLoc && +data.locationId === +this.selectedLoc) {
        const index = this.monsters.findIndex(m => +m.id === +data.id)
        if (index > -1) {
          index ? this.monsters.splice(index, 1) : this.monsters.shift()
        }
      }
    }
    this.$client.service('monsters').on('created', this.itemCreated)
    this.$client.service('monsters').on('updated', this.itemPatched)
    this.$client.service('monsters').on('patched', this.itemPatched)
    this.$client.service('monsters').on('removed', this.itemRemoved)
  },
  beforeDestroy () {
    this.$client.service('monsters').removeListener('created', this.itemCreated)
    this.$client.service('monsters').removeListener('updated', this.itemPatched)
    this.$client.service('monsters').removeListener('patched', this.itemPatched)
    this.$client.service('monsters').removeListener('removed', this.itemRemoved)
    this.itemCreated = this.itemRemoved = this.itemPatched = null
  }
}
</script>

<style>

</style>