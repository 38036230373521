import Mainpage from '../../views/base/Mainpage.vue'
import Greetings from '../../views/base/Greeting.vue'
import NotFound from '../../views/base/NotFound.vue'
import StartIndex from '../../views/start/Index.vue'
import CharacterIndex from '../../views/character/Index.vue'
import RackIndex from '../../views/rack/Index.vue'
import SettingsIndex from '../../views/settings/Index.vue'
export default [{
  path: '/',
  component: Mainpage,
  meta: {
    accessRole: 'all'
  }
}, {
  path: '/welcome',
  component: Greetings,
  meta: {
    accessRole: 'guest'
  }
}, {
  path: '/start',
  component: StartIndex,
  meta: {
    accessRole: 'guest'
  }
}, {
  path: '/character',
  component: CharacterIndex,
  meta: {
    accessRole: 'usr'
  }
}, {
  path: '/rack',
  component: RackIndex,
  meta: {
    accessRole: 'usr'
  }
}, {
  path: '/settings',
  component: SettingsIndex,
  meta: {
    accessRole: 'usr'
  }
}, {
  path: '/checkout',
  name: 'checkout',
  component: () => import(/* webpackChunkName: "checkout" */ '../../views/base/Checkout.vue'),
  meta: {
    accessRole: 'all'
  }
}, {
  path: '*',
  component: NotFound,
  meta: {
    accessRole: 'all'
  }
}]