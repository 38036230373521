import store from '../../store'
import client from '../../services/api-client'
import locMapping from '../scenes/map/mapping.json'
import { cdnServerUrl } from '../../config'

let lastResolvedScene = null
const npcSpriteMapping = {
  up: {
    leftFoot: 9,
    standing: 10,
    rightFoot: 11
  },
  right: {
    leftFoot: 6,
    standing: 7,
    rightFoot: 8
  },
  down: {
    leftFoot: 0,
    standing: 1,
    rightFoot: 2
  },
  left: {
    leftFoot: 3,
    standing: 4,
    rightFoot: 5
  }
}
const npcSpriteParam = {
  size: {
    frameWidth: 48,
    frameHeight: 48
  }
}
function resolveCdn(path = null) {
  if (!path) return false
  return `${cdnServerUrl}${path}`
}
function resolveScene () {
  const user = store.state.auth.user
  let userMapId = user.mapId || null
  if (!userMapId) return null
  if (lastResolvedScene && +lastResolvedScene.id === +userMapId) return lastResolvedScene
  let index = locMapping.maps.findIndex(sc => +user.mapId === +sc.id)
  if (index > -1) {
    lastResolvedScene = locMapping.maps[index]
    return locMapping.maps[index]
  } else return null
}
function resolveSceneById (id = null) {
  if (!id) throw new Error('CAN_NOT_RESOLVE_SCENE_BY_ID:ID_MISSING')
  if (lastResolvedScene && +lastResolvedScene.id === +id) return lastResolvedScene
  const index = locMapping.maps.findIndex(m => +m.id === +id)
  if (index > -1) {
    lastResolvedScene = locMapping.maps[index]
    return locMapping.maps[index]
  } else throw new Error('CAN_NOT_RESOLVE_SCENE_BY_ID:LOCATION_NOT_FOUND')
}
function resolveSpawnPoint (currentLocation = null, targetLocation = null) {
  const user = store.state.auth.user
  // console.log('resolve spawn 1', {x:user.coorsX,y:user.coorsY})
  if (!user.id) throw new Error('CAN_NOT_RESOLVE_LOC_SPAWN_POINT:NOT_AUTHENTICATED')
  if (!currentLocation || !targetLocation) throw new Error('CAN_NOT_RESOLVE_LOC_SPAWN_POINT:PARAMS_MISSING')
  if (currentLocation.id.toString().length === 4) {
    if (!targetLocation.hasOwnProperty('spawnFromCity')) throw new Error('ERROR_RESOLVING_LOCATION_SPAWN:CAN_NOT_FIND_SPAWN_FROMCITY_PROPERTY')
    return targetLocation.spawnFromCity
  } else if (currentLocation.id.toString().length === 5) {
    if (!currentLocation.hasOwnProperty('spawnPointExit')) throw new Error('ERROR_RESOLVING_LOCATION_SPAWN:CAN_NOT_FIND_SPAWN_POINT_EXIT_PROPERTY')
    return currentLocation.spawnPointExit
  } else if (targetLocation.id.toString().length === 4) {
    if (!targetLocation.hasOwnProperty('spawnPoint')) throw new Error('ERROR_RESOLVING_LOCATION_SPAWN:CAN_NOT_FIND_SPAWNPOINT_PROPERTY')
    return targetLocation.spawnPoint
  } else return getLocationSpawnPoint(targetLocation.id, {
    x: user.coorsX,
    y: user.coorsY
  })
}
function resolveLayerTilesets (allTiles = []) {
  if (!Array.isArray(allTiles) || !allTiles.length) {
    console.error('Error resolve layers tilesets')
    return []
  }
  const currScene = resolveScene()
  if (!currScene) {
    console.error('Error getting current scene')
    return []
  }
  let result = []
  currScene.layerTiles.forEach((layer, index) => {
    result[index] = []
    layer.forEach(tileId => {
      result[index].push(allTiles[tileId])
    })
  })
  return result
}
function getGameContainerSize () {
  const width = document.body.clientWidth
  const height = document.body.clientHeight
  return {
    /*
    width: Math.floor(width - (1.5 * parseFloat(getComputedStyle(document.documentElement).fontSize))),
    height: Math.floor(height - (18 * parseFloat(getComputedStyle(document.documentElement).fontSize)))
    */
   width,
   height
  }
}
function getTeleportInfo (key = null) {
  if (!key) throw new Error('ERROR_GETTING_TELEPORT_INFO:TELEPORT_KEY_MISSING')
  let result = null
  if (key.startsWith('LOCATION-')) {
    result = {
      locationId: key.replace('LOCATION-', ''),
      type: 'location',
      ...resolveSceneById(key.replace('LOCATION-', ''))
    }
  } else if (key.startsWith('TOWN-')) {
    let index = locMapping.cities.findIndex(c => c.key === key)
    if (index > -1) {
      result = {
        locationId: locMapping.cities[index].id,
        type: 'city',
        ...resolveSceneById(locMapping.cities[index].id)
      }
    } else console.error('ERROR_GETTING_TELEPORT_INFO:TOWN_ID_NOT_FOUND')
  } else if (key.startsWith('BUILDING-')) {
    let index = locMapping.buildings.findIndex(b => b.key === key)
    if (index > -1) {
      result = {
        locationId: locMapping.buildings[index].id,
        type: 'city',
        ...resolveSceneById(locMapping.buildings[index].id)
      }
    } else console.error('ERROR_GETTING_TELEPORT_INFO:BUILDING_ID_NOT_FOUND')
  } else console.error('ERROR_GETTING_TELEPORT_INFO:INVALID_TELEPORT_ID')
  return result
}
function getLocationDirection (locId = null) {
  if (!locId) throw new Error('ERROR_GETTING_LOCATION_DIRECTION:LOCID_MISSING')
  const user = store.state.auth.user
  if (!user.id) throw new Error('ERROR_GETTING_LOCATION_DIRECTION:NOT_AUTHENTICATED')
  let result = null
  if ((+user.mapId + 1) === +locId) {
    result = 'right'
  } else if ((+user.mapId - 1) === +locId) {
    result = 'left'
  } else if ((+user.mapId + 10) === +locId) {
    result = 'down'
  } else if ((+user.mapId - 10) === +locId) {
    result = 'up'
  } else throw new Error('ERROR_GETTING_LOCATION_DIRECTION:CAN_NOT_CANCULATE')
  return result
}
function getLocationSpawnPoint (locId = null, currentPosition = null) {
  if (!locId || !currentPosition) throw new Error('ERROR_GETTING_LOCATION_SPAWN:PARAMS_MISSING')
  const user = store.state.auth.user
  if (!user.mapId) throw new Error('ERROR_GETTING_LOCATION_SPAWN:USER_MAPID_IS_NULL')
  if (user.mapId.toString().length > 3) {
    let newScene = resolveSceneById(locId)
    if (!newScene.hasOwnProperty('spawnFromCity')) throw new Error('ERROR_GETTING_LOCATION_SPAWN:CAN_NOT_FIND_SPAWN_FROMCITY_PROPERTY')
    return newScene.spawnFromCity
  } else {
    const direction = getLocationDirection(locId)
    // console.log(`getlocationspawn: ${direction}`)
    if (direction === 'right') {
      return {
        x: 2,
        y: currentPosition.y
      }
    } else if (direction === 'down') {
      return {
        x: currentPosition.x,
        y: 2
      }
    } else if (direction === 'left') {
      return {
        x: 47,
        y: currentPosition.y
      }
    } else if (direction === 'up') {
      return {
        x: currentPosition.x,
        y: 47
      }
    } else throw new Error('ERROR_GETTING_LOCATION_SPAWN:UNEXPECTED_DIRECTION')
  }
}
function getOnlineList (locId = null) {
  return client.service('users').find({
    query: {
      online: true,
      ...locId ? {
        location: +locId
      } : {}
    }
  })
}
function getNpcList (locId = null) {
  return client.service('npc').find({
    query: {
      mapId: locId
    }
  })
}
function getCharacterSpritesResources () {
  return [{
    name: 'warrior-m',
    path: '/assets/gamedata/sprites/characters/warriorM.png',
    sizeData: {
      frameWidth: 48,
      frameHeight: 48
    }
  }, {
    name: 'warrior-f',
    path: '/assets/gamedata/sprites/characters/warriorF.png',
    sizeData: {
      frameWidth: 48,
      frameHeight: 48
    }
  }, {
    name: 'paladin-m',
    path: '/assets/gamedata/sprites/characters/paladinM.png',
    sizeData: {
      frameWidth: 48,
      frameHeight: 48
    }
  }, {
    name: 'paladin-f',
    path: '/assets/gamedata/sprites/characters/paladinF.png',
    sizeData: {
      frameWidth: 48,
      frameHeight: 48
    }
  }, {
    name: 'mage-m',
    path: '/assets/gamedata/sprites/characters/mageM.png',
    sizeData: {
      frameWidth: 48,
      frameHeight: 48
    }
  }, {
    name: 'mage-f',
    path: '/assets/gamedata/sprites/characters/mageF.png',
    sizeData: {
      frameWidth: 48,
      frameHeight: 48
    }
  },{
    name: 'assasin-m',
    path: '/assets/gamedata/sprites/characters/assasinM.png',
    sizeData: {
      frameWidth: 48,
      frameHeight: 48
    }
  }, {
    name: 'assasin-f',
    path: '/assets/gamedata/sprites/characters/assasinF.png',
    sizeData: {
      frameWidth: 48,
      frameHeight: 48
    }
  }, {
    name: 'necromancer-m',
    path: '/assets/gamedata/sprites/characters/necromancerM.png',
    sizeData: {
      frameWidth: 48,
      frameHeight: 48
    }
  }, {
    name: 'necromancer-f',
    path: '/assets/gamedata/sprites/characters/necromancerF.png',
    sizeData: {
      frameWidth: 48,
      frameHeight: 48
    }
  }]
}

export {
  resolveCdn,
  npcSpriteMapping,
  npcSpriteParam,
  resolveScene,
  resolveSceneById,
  resolveLayerTilesets,
  getGameContainerSize,
  getTeleportInfo,
  getLocationSpawnPoint,
  resolveSpawnPoint,
  getOnlineList,
  getNpcList,
  getCharacterSpritesResources
}