<template>
  <div>
    <template v-if="showMinimap">
      <div v-show="getMapImage()" class="ib-map-overlay centeredInline pointer" :style="getMapImage()" @click="$store.dispatch('components/SHOW_NAVIGATION_MAP_POPUP', true)">
        <template v-if="!getMapImage()">
          <svg-icon name="map" size="42px"></svg-icon>
        </template>
          <div class="map-visualizer-dot" v-if="user.mapId.toString().length <= 3" style="width:4px;height:4px" :style="{
            ...calculateDotPosition()
          }"></div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'map-overlay-component',
  data () {
    return {

    }
  },
  computed: {
    showMinimap () {
      return !this.user.inCombat && this.uSettings && this.uSettings.showMinimap === 'show' && !this.user.isDead
    }
  },
  methods: {
    calculateDotPosition () {
      const xPerc = 100 / 50
      const yPerc = 100 / 50
      return {
        left: `${Math.round(xPerc * this.user.coorsX)}%`,
        top: `${Math.round(yPerc * this.user.coorsY)}%`
      }
    },
    getMapImage () {
      if (!this.user || !this.user.mapId || this.user.mapId.toString().length > 3) return false
      return {
        backgroundImage: `url(/assets/mapvisualizer/maps/${this.user.mapId}.png)`
      }
    }
  },
  mounted () {
  }
}
</script>

<style>
@import url(../assets/css/infoblocks.css);
</style>