import Index from '../../views/forum/Index.vue'
import Subforum from '../../views/forum/SubForum.vue'
import Thread from '../../views/forum/Thread.vue'

export default [{
  path: '/forum',
  name: 'forum-index',
  component: Index,
  meta: {
    accessRole: 'usr'
  }
}, {
  path: '/forum/subforum/:subforumId(\\d+)',
  name: 'forum-subforum',
  component: Subforum,
  meta: {
    accessRole: 'usr'
  }
}, {
  path: '/forum/thread/:threadId(\\d+)',
  name: 'forum-thread',
  component: Thread,
  meta: {
    accessRole: 'usr'
  }
}]