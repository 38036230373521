import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'
import EventDispatcher from '../game/utils/EventDispatcher'
import { apiServerUrl } from '../config/index'
//import { socket } from './connection'
import localforage from 'localforage'
localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'tcome',
  storeName: 'authdata',
  description: 'authdata for mmorpg client'
})
// import store from '../store'
const EventDispatcherInstance = EventDispatcher.getInstance()
function handleVisibilityChange() {
  if (!document.hidden) {
    EventDispatcherInstance.emit('restartScene')
  }
}
document.addEventListener("visibilitychange", handleVisibilityChange, false)
const socket = io(apiServerUrl, {
  transports: ['websocket'],
  path: '/ws-transport/'
})

const feathersClient = feathers()
  .configure(socketio(socket, {
    reconnection: true
  }))
  .configure(auth({
    storage: localforage,
    storageKey: 'authdata'
  }))

feathersClient.io.on('disconnect', (reason) => {
  // connection error
})

feathersClient.io.on('connect', () => {
  EventDispatcherInstance.emit('restartScene')
})
export default feathersClient
