<template>
  <van-overlay :z-index="200" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline modalTitle">{{ $t('modules.controlPanel.players.manageCoinsExp') }}</div>
        <div class="centeredInline" style="margin:15vh auto" v-if="pending">
          <van-loading />
        </div>
        <div v-else>
          <div class="infoText my-1">
            {{ `${$t('modules.controlPanel.players.current.gold')}: ${abbrNum(coins)}` }}
          </div>
          <van-field type="number" v-model.number="inputed.coins" :placeholder="$t('common.coins')" />
          <van-button :loading="coinsPending" class="mt-05" block size="small" type="warning" @click="updateCoins">{{ $t('common.edit') }}</van-button>
          <div class="infoText my-1">
            {{ `${$t('modules.controlPanel.players.current.exp')}: ${abbrNum(exp)}` }}
          </div>
          <van-field type="number" v-model.number="inputed.exp" :placeholder="$t('modules.parameters.exp')" />
          <van-button :loading="expPending" class="mt-05" block size="small" type="warning" @click="updateExp">{{ $t('common.edit') }}</van-button>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  props: {
    uid: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    pending: true,
    coinsPending: false,
    expPending: false,
    coins: 0,
    exp: 0,
    inputed: {
      exp: '',
      coins: ''
    }
  }),
  computed: {
    showModal () {
      return this.$store.state.cpanel.modals.coinsExp.show
    }
  },
  methods: {
    updateCoins () {
      if (!this.inputed.coins) return false
      this.coinsPending = true
      this.$client.service('setdata').create({
        updateCoins: true,
        playerId: this.uid,
        coins: this.inputed.coins
      }).then(r => {
        this.coinsPending = false
        this.$toast(this.$t('modules.controlPanel.players.balanceUpdateOk'))
        this.$emit('setcoins', r.coins)
        this.closeModal()
      }).catch(() => {
        this.coinsPending = false
        this.$toast(this.$t('modules.controlPanel.players.balanceUpdateError'))
      })
    },
    updateExp () {
      if (!this.inputed.exp) return false
      this.expPending = true
      this.$client.service('setdata').create({
        updateExp: true,
        playerId: this.uid,
        exp: this.inputed.exp
      }).then(r => {
        this.expPending = false
        this.$toast(this.$t('modules.controlPanel.players.balanceUpdateOk'))
        this.$emit('setexp', r.exp)
        this.closeModal()
      }).catch(() => {
        this.expPending = false
        this.$toast(this.$t('modules.controlPanel.players.balanceUpdateError'))
      })
    },
    closeModal () {
      this.$store.dispatch('cpanel/SHOW_MANAGE_PLAYER_COINS_EXP_MODAL', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    },
  },
  mounted () {
    this.$client.service('getdata').find({
      query: {
        coinsExp: true,
        uid: this.uid
      }
    }).then(d => {
      this.coins = this.inputed.coins = d.coins
      this.exp = this.inputed.exp = d.exp
      this.$nextTick(() => {
        this.pending = false
      })
    }).catch(() => {
      this.$toast(this.$t('modules.controlPanel.players.getBalanceError'))
      this.pending = false
    })
  }
}
</script>