<template>
  <div>
    <div class="centered" v-if="pending">
      <van-loading></van-loading>
    </div>
    <div class="centeredInline row" v-else>
      <div class="floating-btn center"></div>
      <van-button type="primary" size="small" style="margin:0 0 1rem 0" round @click="$store.dispatch('cpanel/SHOW_MAP_VISUALIZER', true)">{{ $t('modules.controlPanel.mapVisualizerBtn') }}</van-button>
      <van-cell center @click="editMap(map)" :title="`${$t('modules.controlPanel.mapNumber')} - ${map.mapId}`" v-for="(map, index) in maps" :key="index" clickable size="large">
        <template #icon>
          <div class="cell-icon">
            <svg-icon name="map"></svg-icon>
          </div>
        </template>
        <template #label>
          {{ `${$t('modules.controlPanel.mapTitle')}: ${map.title}` }}
        </template>
      </van-cell>
      <edit-map @save="updateLocs" v-if="selectedMap" :data="selectedMap" @rerender="selectedMap=null"></edit-map>
      <map-visualizer @selected="editMapVsl"></map-visualizer>
    </div>
  </div>
</template>
<script>
import MapVisualizer from './modals/MapVisualizer.vue'
import EditMap from './modals/EditMap.vue'
export default {
  name: 'control-panel-maps-settings',
  components: {
    EditMap,
    MapVisualizer
  },
  data () {
    return {
      selectedMap: null,
      pending: true,
      maps: [],
      mapsPatched: null
    }
  },
  methods: {
    assembleLocsData () {
      let titles = this.maps.reduce((obj, item) => Object.assign(obj, { [item.mapId]: item.title }), {})
      return {
        titles: titles
      }
    },
    parseData (data = null) {
      if (!data) return false
      return Object.keys(data.titles).map((key) => {
        return {
            mapId: Number(key),
            title: data.titles[key]
          }
        })
    },
    updateLocs (loc = null) {
      if (!loc) return false
      const index = this.maps.findIndex(m => +m.mapId === +loc.mapId)
      if (index > -1) {
        if (this.maps[index].title !== loc.title) {
          this.$set(this.maps, index, loc)
          this.$client.service('app-settings').patch(2, {
            ...this.assembleLocsData()
          }).then(() => {
            this.$toast(this.$t('modules.controlPanel.locsDataRefreshed'))
          }).catch(e => {
            console.log(e)
            this.$toast(this.$t('common.updateDataError'))
          })
        }
      }
    },
    editMapVsl (index) {
      if (this.maps[index]) {
        this.editMap(this.maps[index])
      } else {
        const listIndex = this.maps.findIndex(m => +m.mapId === +index)
        if (listIndex > -1) {
          this.editMap(this.maps[listIndex])
        } else return false
      }
    },
    editMap (map = null) {
      if (!map) return false
      this.selectedMap = map
      this.$nextTick(() => {
        this.$store.dispatch('cpanel/SHOW_EDIT_MAP_DATA', true)
      })
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.controlPanel.mapDataTitle'))
  },
  async mounted () {
    this.maps = await this.$client.service('app-settings').get(2).then(m => this.parseData(m)).catch(e => {
      this.$toast(this.$t('common.dataFetchError'))
    })
    this.pending = false
    this.mapsPatched = data => {
      if (+data.id === 2) {
        this.maps = this.parseData(data)
      }
    }
    this.$client.service('app-settings').on('patched', this.mapsPatched)
    this.$client.service('app-settings').on('updated', this.mapsPatched)
  },
  beforeDestroy () {
    this.$client.service('app-settings').on('patched', this.mapsPatched)
    this.$client.service('app-settings').on('updated', this.mapsPatched)
    this.mapsPatched = null
  }
}
</script>

<style>

</style>