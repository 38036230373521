let instance
export default {
  filters: {
    lowercase (text) {
      return text.toLowerCase()
    },
    uppercase (text) {
      return text.toUpperCase()
    }
  },
  create (Vue) {
    instance = Vue
    Object.keys(this.filters).forEach(function (filter, k) {
      Vue.filter(filter, this.filters[filter])
    }.bind(this))
  }
}