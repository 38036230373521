<template>
  <div>
    <div class="sticky-btn centeredInline">
      <van-button round size="small" type="primary" @click="$store.dispatch('cpanel/SHOW_ADD_ITEM_MODAL', true)">
        <div class="centeredInline">
          <fa-icon icon="plus-circle" style="margin-right:.5rem"></fa-icon>
          {{ $t('common.add') }}
        </div>
      </van-button>
    </div>
    <div class="centered" v-if="pending">
      <van-loading></van-loading>
    </div>
    <div v-else>
      <div v-if="items.length">
        <van-cell @click="openEditModal(item)" v-for="item in items" clickable :key="item.id">
          <template #icon>
            <div class="cell-icon">
              <img :src="cdnPath(item.image)" alt="" />
            </div>
          </template>
          <template #title>
            <span :style="{color:$t('common.rarityColors')[item.rarity]}">
              {{ `${item.name} (ID: ${item.id})` }}
            </span>
          </template>
          <template #label>
            <item-info-inline :item="item"></item-info-inline>
          </template>
        </van-cell>
        <div class="load-more-manual centeredInline">
          <van-button @click.stop="onLoad" type="primary" round size="small" :loading="loadPending" :disabled="loadPending" v-if="itemsLoaded < itemsTotal">
            <div class="centeredInline">
              <fa-icon icon="sync-alt" style="margin-right:.5rem"></fa-icon>
              {{$t('common.loadMore')}}
            </div>
          </van-button>
        </div>
      </div>
      <div class="centered" v-else>
        <div class="infoText">{{ $t('modules.controlPanel.noItems') }}</div>
      </div>
    </div>
    <add-item :type="category" :key="`am-${addModalKey}`" @rerender="addModalKey++"></add-item>
    <edit-item v-if="selectedItem" :item="selectedItem" @closed="selectedItem=null"></edit-item>
  </div>
</template>

<script>
import ItemInfoInline from '../../../components/ItemInfoInline.vue'
import AddItem from './modals/AddItem.vue'
import EditItem from './modals/EditItem.vue'
export default {
  name: 'control-panel-manage-items-categories',
  components: {
    AddItem,
    ItemInfoInline,
    EditItem
  },
  data () {
    return {
      pending: true,
      loadPending: false,
      loadFinished: false,
      items: [],
      itemsLoaded: 0,
      itemsTotal: 0,
      itemCreated: null,
      itemPatched: null,
      itemRemoved: null,
      addModalKey: 0,
      selectedItem: null
    }
  },
  computed: {
    category () {
      return this.$route.params.category || null
    }
  },
  methods: {
    onLoad () {
      if (this.itemsLoaded >= this.itemsTotal) return this.loadPending = false
      this.loadPending = true
      this.$client.service('items').find({
        query: {
          $skip: this.itemsLoaded,
          $sort: {
            createdAt: -1
          },
          type: this.category
        }
      }).then(d => {
        if (d.data.length) {
          this.itemsLoaded+=d.data.length
          this.itemsTotal = d.total
          this.items = this.items.concat(d.data)
          this.loadPending = false
        } else {
          this.loadFinished = true
        }
        this.loadPending = false
      }).catch(e => {
        console.log(e)
        this.loadPending = false
        this.$toast(this.$t('modules.controlPanel.itemsFetchError'))
      })
    },
    openEditModal (item = null) {
      if (!item) return false
      this.selectedItem = item
      this.$nextTick(() => {
        this.$store.dispatch('cpanel/SHOW_EDIT_ITEM_MODAL', true)
      })
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', `${this.$t('modules.controlPanel.manageItemsTitle')} - ${this.$t('modules.items.names')[this.category]}`)
  },
  async mounted () {
    if (!this.category || !['wear', 'quest', 'resource', 'loot', 'consumable'].includes(this.category)) return this.$router.go(-1)
    this.items = await this.$client.service('items').find({
      query: {
        $sort: {
          createdAt: -1
        },
        type: this.category
      }
    }).then(i => {
      this.itemsLoaded = i.data.length
      this.itemsTotal = i.total
      return i.data
    }).catch(e => {
      this.$toast(this.$t('modules.controlPanel.itemsFetchError'))
      return []
    })
    this.pending = false
    this.itemCreated = data => {
      if (data.type === this.category) this.items.unshift(data)
    }
    this.itemPatched = data => {
      if (data.type !== this.category) return false
      const index = this.items.findIndex(i => +i.id === +data.id)
      if (index > -1) this.$set(this.items, index, data)
    }
    this.itemRemoved = data => {
      if (data.type !== this.category) return false
      const index = this.items.findIndex(i => +i.id === +data.id)
      if (index > -1) index ? this.items.splice(index, 1) : this.items.shift()
    }
    this.$client.service('items').on('created', this.itemCreated)
    this.$client.service('items').on('updated', this.itemPatched)
    this.$client.service('items').on('patched', this.itemPatched)
    this.$client.service('items').on('removed', this.itemRemoved)
  },
  beforeDestroy () {
    this.$client.service('items').removeListener('created', this.itemCreated)
    this.$client.service('items').removeListener('updated', this.itemPatched)
    this.$client.service('items').removeListener('patched', this.itemPatched)
    this.$client.service('items').removeListener('removed', this.itemRemoved)
    this.itemCreated = this.itemPatched = this.itemRemoved = null
  }
}
</script>

<style>

</style>