<template>
  <div>
    <div class="sticky-btn center">
      <van-button type="primary" size="small" round @click="$store.dispatch('cpanel/SHOW_ADD_NPC_MODAL', true)">
        <div class="centeredInline">
          <fa-icon icon="plus-circle" style="margin-right:.25rem"></fa-icon>
          {{ $t('modules.controlPanel.addNpcBtn') }}
        </div>
      </van-button>
    </div>
    <div class="centered" v-if="pending">
      <van-loading></van-loading>
    </div>
    <div v-else>
      <div v-if="items.length">
        <van-cell @click="openEditModal(npc)" clickable v-for="npc in items" :key="npc.id">
          <template #icon>
            <div class="cell-icon">
              <img :src="cdnPath(npc.imagePath)" alt="" />
            </div>
          </template>
          <template #title>
            {{ npc.name }}
            <span class="tiny-text">
              {{ `(${getLocName(npc.mapId)})` }}
            </span>
          </template>
          <template #label>
            {{ `${$t('common.coordinates')}: x ${npc.coorsX}, y ${npc.coorsY}` }}<br>
            {{ `${$t('modules.controlPanel.npcDialogIdOnOpen')}: ${npc.dialogOnOpenId}` }}<br>
          </template>
          <template #extra>
            <div class="cell-sprite-preview">
              <img :src="cdnPath(npc.spritePath)" alt="" />
            </div>
          </template>
        </van-cell>
        <div class="load-more-manual centeredInline">
          <van-button @click.stop="onLoad" type="primary" round size="small" :loading="loadPending" :disabled="loadPending" v-if="itemsLoaded < itemsTotal">
            <div class="centeredInline">
              <fa-icon icon="sync-alt" style="margin-right:.5rem"></fa-icon>
              {{$t('common.loadMore')}}
            </div>
          </van-button>
        </div>
      </div>
      <div class="centered" v-else>
        <div class="infoText">
          {{ $t('common.emptyList') }}
        </div>
      </div>
    </div>
    <add-npc-modal :key="`add-npcm-${modalKey}`" @rerender="modalKey++"></add-npc-modal>
    <edit-npc-modal v-if="selectedNpc" :npc="selectedNpc" :key="`edit-npcm-${editModalKey}`" @rerender="editModalKey++"></edit-npc-modal>
  </div>
</template>

<script>
import AddNpcModal from './modals/AddNpc.vue'
import EditNpcModal from './modals/EditNpc.vue'
export default {
  name: 'control-panel-manage-npc-index',
  components: {
    AddNpcModal,
    EditNpcModal
  },
  data () {
    return {
      pending: true,
      loadPending: false,
      loadFinished: false,
      items: [],
      selectedNpc: null,
      modalKey: 0,
      editModalKey: 0,
      itemsLoaded: 0,
      itemsTotal: 0,
      itemCreated: null,
      itemPatched: null,
      itemRemoved: null
    }
  },
  methods: {
    onLoad () {
      if (this.itemsLoaded >= this.itemsTotal) return this.loadPending = false
      this.loadPending = true
      this.$client.service('npc').find({
        query: {
          $skip: this.itemsLoaded,
          $sort: {
            createdAt: -1
          }
        }
      }).then(d => {
        if (d.data.length) {
          this.itemsLoaded+=d.data.length
          this.itemsTotal = d.total
          this.items = this.items.concat(d.data)
          this.loadPending = false
        } else {
          this.loadFinished = true
        }
        this.loadPending = false
      }).catch(e => {
        console.log(e)
        this.loadPending = false
        this.$toast(this.$t('modules.controlPanel.fetchNpcError'))
      })
    },
    openEditModal (npc = null) {
      if (!npc) return false
      this.selectedNpc = npc
      this.$nextTick(() => {
        this.$store.dispatch('cpanel/SHOW_EDIT_NPC_MODAL', true)
      })
    },
    getLocName (id = null) {
      if (!id) return false
      let cities = this.$t('modules.mapVisualizer.cities')
      let buildings = this.$t('modules.mapVisualizer.buildings')
      if (id.toString().length === 4) {
        let index = cities.findIndex(c => +c.id === +id)
        return index > -1 ? cities[index].name : 'City not found'
      } else if (id.toString().length === 5) {
        let index = buildings.findIndex(b => +b.id === +id)
        return index > -1 ? buildings[index].name : 'Building not found'
      } else return `Loc #${id}`
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.controlPanel.manageNpc'))
  },
  async mounted () {
    this.items = await this.$client.service('npc').find({
      query: {
        $sort: {
          createdAt: -1
        }
      }
    }).then(n => {
      this.itemsLoaded = n.data.length
      this.itemsTotal = n.total
      return n.data
    }).catch(e => {
      console.log(e)
      this.$toast(this.$t('modules.controlPanel.fetchNpcError'))
      return []
    })
    this.pending = false
    this.itemCreated = data => {
      this.items.unshift(data)
    }
    this.itemPatched = data => {
      const index = this.items.findIndex(i => +i.id === +data.id)
      if (index > -1) this.$set(this.items, index, data)
    }
    this.itemRemoved = data => {
      const index = this.items.findIndex(i => +i.id === +data.id)
      if (index > -1) index ? this.items.splice(index, 1) : this.items.shift()
    }
    this.$client.service('npc').on('created', this.itemCreated)
    this.$client.service('npc').on('patched', this.itemPatched)
    this.$client.service('npc').on('updated', this.itemPatched)
    this.$client.service('npc').on('removed', this.itemRemoved)
  },
  beforeDestroy () {
    this.$client.service('npc').removeListener('created', this.itemCreated)
    this.$client.service('npc').removeListener('patched', this.itemPatched)
    this.$client.service('npc').removeListener('updated', this.itemPatched)
    this.$client.service('npc').removeListener('removed', this.itemRemoved)
    this.itemCreated = this.itemPatched = this.itemRemoved = null
  }
}
</script>

<style>

</style>