import Index from '../../views/cpanel/Index.vue'
import AppSettings from '../../views/cpanel/settings/AppSettings.vue'
import MapsSettings from '../../views/cpanel/settings/MapsSettings.vue'
import InitParamsSettings from '../../views/cpanel/initParams/Index.vue'
import ManageMonstersIndex from '../../views/cpanel/monsters/Index.vue'
import ManageNpcIndex from '../../views/cpanel/npc/Index.vue'
import ManageItemsIndex from '../../views/cpanel/items/Index.vue'
import ManageItemsCategory from '../../views/cpanel/items/Category.vue'
import ManageDialoguesIndex from '../../views/cpanel/dialogues/Index.vue'
import ManageQuestsIndex from '../../views/cpanel/quests/Index.vue'
import ManagePlayersIndex from '../../views/cpanel/players/Index.vue'


export default [{
  path: '/control-panel',
  component: Index,
  meta: {
    accessRole: ['mod', 'adm', 'sv']
  }
}, {
  path: '/control-panel/app-settings',
  component: AppSettings,
  meta: {
    accessRole: ['sv']
  }
}, {
  path: '/control-panel/maps-settings',
  component: MapsSettings,
  meta: {
    accessRole: ['adm', 'sv']
  }
}, {
  path: '/control-panel/init-params-settings',
  component: InitParamsSettings,
  meta: {
    accessRole: ['sv']
  }
}, {
  path: '/control-panel/manage-monsters',
  component: ManageMonstersIndex,
  meta: {
    accessRole: ['adm', 'sv']
  }
}, {
  path: '/control-panel/manage-dialogues',
  component: ManageDialoguesIndex,
  meta: {
    accessRole: ['adm', 'sv']
  }
}, {
  path: '/control-panel/manage-quests',
  component: ManageQuestsIndex,
  meta: {
    accessRole: ['adm', 'sv']
  }
}, {
  path: '/control-panel/manage-players',
  component: ManagePlayersIndex,
  meta: {
    accessRole: ['adm', 'sv']
  }
}, {
  path: '/control-panel/manage-npc',
  component: ManageNpcIndex,
  meta: {
    accessRole: ['sv']
  }
}, {
  path: '/control-panel/manage-items',
  component: ManageItemsIndex,
  meta: {
    accessRole: ['sv']
  }
}, {
  path: '/control-panel/manage-items/:category',
  component: ManageItemsCategory,
  meta: {
    accessRole: ['sv']
  }
}]