<template>
  <div class="centeredInline row">
    <div class="site_logo">
      <img src="/assets/UI/images/logo.png" alt="" />
    </div>
    <div class="greetings_container header">
      {{ $t('modules.greetings.title') }}
    </div>
    <p>
      {{ $t('modules.greetings.description') }}
    </p>
    <div class="container greetings_auth_btns centeredInline row">
      <van-button @click="$router.push('/start')" :loading="signupPending" plain class="greetings_btn" style="margin:.5rem 0 0 0;">{{ $t('modules.greetings.buttons')[0] }}</van-button>
      <div class="textSmall" style="margin:.5rem auto">
        {{ $t('modules.greetings.registeredQ') }}
      </div>
      <van-button @click="$store.dispatch('common/SHOW_SI_MODAL', true)" plain class="greetings_btn">{{ $t('modules.greetings.buttons')[1] }}</van-button>
    </div>
    <sign-in-form></sign-in-form>
  </div>
</template>
<script>
import signInForm from './modals/SIForm.vue'
export default {
  name: 'greeting-page',
  components: {
    signInForm
  },
  data () {
    return {
      showOverlay: false,
      signupPending: false
    }
  },
  methods: {
    quickSignup () {
      this.signupPending = true
      this.$client.service('users').create({
        quickstart: true
      }).then(r => {
        console.log('OK', r)
        this.$store.dispatch('auth/authenticate', {
          email: r.email,
          password: `${r.nickname}-${r.nickname}`
        }).then(() => {
          this.$router.replace('/')
          this.signupPending = false
          this.$emit('on-authenticated')
        }).catch(e => {
          this.$toast(this.$t('common.errorOccured'))
          console.log('AUTH_ERROR', e)
          this.signupPending = false
        })
      }).catch(e => {
        this.$toast(this.$t('common.errorOccured'))
        console.log('ERROR', e)
        this.signupPending = false
      })
    }
  },
  created () {
    this.setAppTitle(this.$t('modules.greetings.title'))
  },
  mounted () {

  }
}
</script>

<style>
@import url(../../assets/css/greetings.css);
</style>