<template>
  <van-overlay :z-index="200" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline row">
          <div class="modalTitle van-ellipsis">{{ `${$t('modules.forum.createSubforum')}` }}</div>
          <van-form style="width:100%" @submit="onSubmit" ref="form">
            <div class="infoText">{{$t('modules.forum.subforumName')}}</div>
            <van-field
              type="text"
              v-model.trim="inputed.name"
              name="name"
              :placeholder="$t('modules.forum.subforumName')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <div class="infoText">{{$t('modules.forum.subforumDescr')}}</div>
            <van-field
              type="textarea"
              v-model.trim="inputed.description"
              name="description"
              :placeholder="$t('modules.forum.subforumDescr')"
            />
            <van-cell clickable @click="inputed.canCreateThreads=!inputed.canCreateThreads" :title="$t('modules.forum.canCreateThreads')">
              <template #extra>
              <van-checkbox v-model="inputed.canCreateThreads">
                <template #icon="props">
                  <template v-if="props.checked">
                    <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                  </template>
                </template>
              </van-checkbox>
              </template>
            </van-cell>
            <van-cell clickable @click="inputed.adminsOnly=!inputed.adminsOnly" :title="$t('modules.forum.adminsOnly')">
              <template #extra>
              <van-checkbox v-model="inputed.adminsOnly">
                <template #icon="props">
                  <template v-if="props.checked">
                    <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                  </template>
                </template>
              </van-checkbox>
              </template>
            </van-cell>
            <div style="margin: 16px;">
              <van-button :loading="savePending" :disabled="savePending" round plain block class="greetings_btn" native-type="submit">
                {{ $t('common.create') }}
              </van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'forum-create-subforum-modal',
  props: {
    parentId: {
      type: Number
    }
  },
  data () {
    return {
      savePending: false,
      inputed: {
        name: '',
        description: '',
        canCreateThreads: true,
        adminsOnly: false
      }
    }
  },
  computed: {
    ...mapState({
      showModal: state => state.forum.modals.createSubforum.show
    })
  },
  methods: {
    closeModal () {
      this.$store.dispatch('forum/SHOW_CREATE_SUBFORUM_MODAL', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    },
    async onSubmit () {
      const checkout = await this.$refs.form.validate()
      if (this.inputed.name.length < 5 || this.inputed.name.length > 128) return this.$toast(this.$t('modules.forum.subforumNameLengthError'))
      if (this.inputed.description.length > 0 && this.inputed.description.length > 512) return this.$toast(this.$t('modules.forum.subforumDescrLengthError'))
      if (!checkout) {
        this.savePending = true
        this.$client.service('forum').create({
          name: this.inputed.name,
          description: this.inputed.description,
          type: 'subforum',
          canCreateThreads: this.inputed.canCreateThreads,
          forAdmins: this.inputed.adminsOnly,
          ... this.parentId ? {
            parentId: this.parentId
          } : {}
        }).then(() => {
          this.$toast(this.$t('modules.forum.subforumCreateOk'))
          this.inputed.name = ''
          this.inputed.description = ''
          this.closeModal()
        }).catch(e => {
          console.log(e)
          this.$toast(this.$t('modules.forum.subforumCreateError'))
        })
        this.savePending = false
      } else this.$t('common.fieldsError')
    }
  },
  mounted () {
  }
}
</script>

<style>

</style>