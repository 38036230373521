<template>
    <van-overlay :z-index="50" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline row">
          <div class="modalTitle van-ellipsis">{{ `${item.name}` }}</div>
          <div class="infoTextSmall" style="margin-top:-.75rem">{{ $t('modules.items.names')[item.type] }}</div>
          <div class="rack-ii-img">
            <img :src="cdnPath(item.image)" alt="" />
          </div>
          <div class="centeredInline" style="margin:0 0 1rem 0;">
            {{ item.description }}
          </div>
          <div class="rack-ii" style="width:100%;">
            <van-cell clickable :title="$t('modules.rack.stackCount')" class="rack-ii-item odd">
              <template #extra>
                {{ `${item.stackCount}` }}
              </template>
            </van-cell>
            <van-cell clickable :title="$t('common.rarity')" class="rack-ii-item even">
              <template #extra>
                <span :class="`rarity-${item.rarity}`">
                  {{ $t('common.rarityNames')[item.rarity] }}
                </span>
              </template>
            </van-cell>
            <van-cell clickable :title="$t('modules.rack.sellCost')" class="rack-ii-item odd">
              <template #extra>
                <span class="centeredInline" style="padding-right:0">
                  {{ `${item.sellPrice}` }}
                  <coin-icon size="1rem" class="ml-05" style="margin-right:0"></coin-icon>
                </span>
              </template>
            </van-cell>
          </div>
          <div class="centeredInline" style="margin-top:1rem;width:100%">
            <van-button :loading="dropPending" :disabled="dropPending" @click="item.stackCount > 1 ? dropMode=true : dropItem()" v-if="!dropMode" type="warning" block>{{ $t('modules.rack.dropItem') }}</van-button>
            <van-cell v-if="dropMode">
              <template #title>
                <div style="display:flex;align-items:center;justify-content:space-between;">
                  <van-stepper :max="item.stackCount" v-model="inputed.dropCount"></van-stepper>
                </div>
              </template>
              <template #extra>
                <div class="centeredInline">
                  <van-button @click="inputed.dropCount=item.stackCount" type="primary" size="small">{{ $t('common.all') }}</van-button>
                  <van-button size="small" @click="dropItem()" type="warning" style="margin:0 .25rem 0 .25rem">{{ $t('modules.rack.dropItem') }}</van-button>
                  <van-button size="small" type="primary" @click="dropMode=false"><fa-icon style="margin:0 .25rem" icon="times"></fa-icon></van-button>
                </div>
              </template>
            </van-cell>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'rack-item-info-modal',
  props: {
    item: {
      type: Object,
      require: true,
      default: () => {}
    }
  },
  data () {
    return {
      dropPending: false,
      dropMode: false,
      inputed: {
        dropCount: 1
      }
    }
  },
  computed: {
    ...mapState({
      showModal: state => state.rack.modals.itemInfo.show
    })
  },
  methods: {
    dropItem () {
      if (this.inputed.dropCount > this.item.stackCount) this.inputed.dropCount = this.item.stackCount
      let self = this
      let confirmTitle = `${self.$t('modules.rack.dropItemTitle')}`
      if (self.inputed.dropCount > 1) confirmTitle += ` (${self.inputed.dropCount} ${self.$t('common.pcs')})`
      this.$dialog.confirm({
        title: confirmTitle,
        message: self.$t('modules.rack.dropItemQ'),
        confirmButtonText: self.$t('modules.rack.dropItem'),
        cancelButtonText: self.$t('common.cancel'),
        showCancelButton: true,
        className: 'confirm'
      }).then(() => {
        self.dropMode = false
        self.dropPending = true
        self.$client.service('rack').patch(self.user.rackId, {
          dropItem: true,
          itemId: self.item.id,
          count: self.inputed.dropCount
        }).then(() => {
          self.dropPending = false
          self.$toast(self.$t('modules.rack.dropItemOk'))
          self.closeModal()
        }).catch(() => {
          self.$toast(self.$t('modules.rack.dropItemError'))
        })
      }).catch(() => {
        this.dropMode = false
      })
    },
    closeModal () {
      this.$store.dispatch('rack/SHOW_ITEM_INFO', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    }
  }
}
</script>

<style>

</style>