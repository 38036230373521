<template>
  <div>
    <div class="ib-userinfo">
      <div class="ib-userinfo-wrapper">
        <div class="ib-userinfo-lvl">
          {{`${user.level}`}}
        </div>
        <div class="ib-userinfo-class-icon centeredInline">
          <div class="ib-userinfo-dmg-text" :style="{opacity:showDmgText?1:0,color:dmgText>1?'green':'red'}">{{`${dmgText>1?'+':''}${dmgText}`}}</div>
          <img style="margin-left:-.25rem;opacity:.8" :src="avatarImage" alt="" />
        </div>
        <div class="ib-userinfo-name-bars">
          <div class="ib-userinfo-name">
            {{`${userInfo().nickname}`}}
          </div>
          <div class="ib-userinfo-bars">
            <div class="bar hp_bar">
              <div class="bar-fill red" :style="`width:${hpBarWidth}%`"></div>
            </div>
            <div class="bar mana_bar" style="margin:.125rem 0">
              <div class="bar-fill blue" :style="`width:${mpBarWidth}%`"></div>
            </div>
            <div class="bar exp_bar" style="margin-bottom:.125rem">
              <div class="bar-fill yellow" :style="`width:${user.levelProgress * 100}%`"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ping-indicator></ping-indicator>
  </div>
</template>

<script>
import PingIndicator from './PingIndicator.vue'
export default {
  name: 'bars-main-component',
  components: {
    PingIndicator
  },
  data () {
    return {
      showDmgText: false,
      dmgText: 0,
      userPatched: null
    }
  },
  computed: {
    avatarImage () {
      return `/assets/gamedata/images/characters/${this.user.class}${this.user.gender==='male'?'M':'F'}.png`
    },
    hpBarWidth () {
      return Math.round(this.user.currHp / (this.user.maxHp / 100))
    },
    mpBarWidth () {
      return Math.round(this.user.currMp / (this.user.maxMp / 100))
    }
  },
  methods: {
    showDmg (dmg = 0) {
      this.dmgText = dmg
      this.showDmgText = true
      setTimeout(() => {
        this.showDmgText = false
      }, 1000)
    }
  },
  mounted () {
    this.userPatched = data => {
      if (+data.id === +this.user.id && +data.currHp !== +this.user.currHp) this.showDmg(data.currHp - this.user.currHp)
    }
    this.$client.service('userdata').on('patched', this.userPatched)
    this.$client.service('userdata').on('updated', this.userPatched)
  },
  beforeDestroy () {
    this.$client.service('userdata').removeListener('patched', this.userPatched)
    this.$client.service('userdata').removeListener('updated', this.userPatched)
    this.userPatched = null
  }
}
</script>

<style>
@import url(../assets/css/infoblocks.css);
</style>