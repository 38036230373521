import client from '../../services/api-client'
const state = {
  pending: false,
  user: null,
  accessToken: null
}
const getters = {}
const mutations = {
  setPending (state, bool) {
    state.pending = bool
  },
  setUser (state, user) {
    const usr = typeof user.user === 'object' ? user.user : null
    const accessToken = typeof user.accessToken === 'string' ? user.accessToken : null
    state.user = usr
    state.accessToken = accessToken
  },
  updateUserSimple (state, user) {
    state.user = user
  },
  updateUser (state, user) {
    const usr = typeof user.user === 'object' ? user.user : null
    const accessToken = typeof user.accessToken === 'string' ? user.accessToken : null
    state.user = {
      ...state.user,
      ...usr
    }
    if (accessToken) state.accessToken = accessToken
  },
  clearUser (state) {
    state.user = false
    state.accessToken = false
  }
}
const actions = {
  auth ({ commit }) {
    commit('setPending', true)
    return new Promise((resolve, reject) => {
      client.authentication.reAuthenticate(true).then(async () => {
        let user = await client.get('authentication')
        commit('setUser', user || null)
        commit('setPending', false)
        resolve(true)
      }).catch(e => {
        commit('setPending', false)
        reject(e)
      })
    })
  },
  authenticate ({ commit }, data) {
    commit('setPending', true)
    data.strategy = data.strategy || 'local'
    data.email = data.email || ''
    data.password = data.password || ''
    return new Promise((resolve, reject) => {
      client.authenticate({
        strategy: data.strategy,
        email: data.email,
        password: data.password
      }).then(async () => {
        let user = await client.get('authentication')
        commit('setUser', user || null)
        commit('setPending', false)
        resolve(true)
      }).catch(e => {
        commit('setPending', false)
        reject(e)
      })
    })
  },
  UPDATE_USER ({ commit }, usr) {
    commit('updateUser', {
      user: usr
    })
  },
  UPDATE_USER_SIMPLE ({ state, commit }, usr = null) {
    if (!usr) return false
    commit('updateUserSimple', usr)
  },
  LOGOUT ({ commit }) {
    return new Promise((resolve, reject) => {
        client.logout().then(() => {
          commit('clearUser')
          resolve('Logout ok')
        }).catch(e => {
          reject(`LOGOUT_ERROR: ${e.message}`)
        })
    })
  },
  SHOW_SIGNIN_FORM_MODAL ({ commit }, bool) {
    commit('signInModalConf', {
      show: bool
    })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
