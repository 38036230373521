<template>
    <van-overlay :z-index="50" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline row">
          <div class="modalTitle van-ellipsis">{{ `${$t('modules.controlPanel.createDialogueTitle')}` }}</div>
        </div>
        <div>
          <div class="infoText">
            {{ $t('modules.controlPanel.dialogueStartPhrase') }}
          </div>
          <van-field
            style="margin:.5rem 0"
            type="textarea"
            maxlength="2048"
            rows="1"
            autosize
            :show-word-limit="true"
            v-model="inputed.mainPhrase"
            :placeholder="$t('common.inputText')"
            ></van-field>
          <div class="infoText" style="margin-bottom:.5rem">
            {{ $t('modules.controlPanel.dialoguePhrasesOnOpen') }}
          </div>
          <div class="centeredInline" v-if="!inputed.phrasesOnOpen.length">
            {{ $t('modules.controlPanel.dialogueNoPhrases') }}
          </div>
          <template v-else>
            <van-tag class="pointer" style="margin:0 .125rem" v-for="(phrase, index) in inputed.phrasesOnOpen" :key="`phrase-${phrase}-${index}`" size="large" type="warning" @click.stop="removeOnOpenPhrase(index)">
              {{ `${phrase}` }}
            </van-tag>
          </template>
          <template v-if="phraseOnOpenAddMode">
            <van-field
              style="margin-top:.5rem"
              label="ID"
              type="text"
              v-model="inputed.phrasesOnOpenId"
              :placeholder="$t('common.inputText')"
              ></van-field>
          </template>
          <div class="centeredInline">
            <van-button style="margin-top:.5rem" @click="phraseOnOpenAddMode ? addOnOpenPhrase() : phraseOnOpenAddMode = true" type="warning" size="small">{{ $t('common.add') }}</van-button>
          </div>
          <div class="infoText">
            {{ $t('modules.controlPanel.dialoguePhrases') }}
          </div>
          <div v-for="(phrase, index) in inputed.additionPhrases" :key="`a-phrase-${index}`" style="border:1px solid #2C4B5C;border-radius:6px;padding:.5rem">
            <div style="margin-top:.5rem" class="centeredInline infoTextSmall">
              <span class="underline">{{ `ID: ${phrase.id}` }}</span>
              <van-button style="margin-left:.5rem" v-if="index > 0" @click="removeAdditionPhrase(index)" type="warning" size="small">{{ $t('modules.controlPanel.dialogueRemovePhrase') }}</van-button>
            </div>
            <div class="infoTextSmall">{{ $t('modules.controlPanel.dialogueBtnText') }}</div>
            <van-field
              style="margin:.5rem 0"
              type="text"
              maxlength="512"
              :placeholder="$t('common.inputText')"
              v-model="inputed.additionPhrases[index].btnText"
              ></van-field>
            <div class="infoTextSmall">{{ $t('modules.controlPanel.dialoguePhraseText') }}</div>
            <van-field
              style="margin:.5rem 0"
              type="textarea"
              maxlength="2048"
              rows="1"
              autosize
              :show-word-limit="true"
              v-model="inputed.additionPhrases[index].text"
              :placeholder="$t('common.inputText')"
              ></van-field>
            <div class="infoTextSmall">{{ $t('modules.controlPanel.dialogueVariants') }}</div>
            <div>
              <div class="centeredInline" v-if="!inputed.additionPhrases[index].phrases.length">
                {{ $t('modules.controlPanel.dialogueNoVariants') }}
              </div>
              <template v-else>
                <van-tag class="pointer" style="margin:0 .125rem" v-for="(phr, phri) in inputed.additionPhrases[index].phrases" :key="`a-phrase-${phr}-${phri}`" size="large" type="warning" @click.stop="removeAdditionPhrasePhrase(index, phri)">
                  {{ `${phr}` }}
                </van-tag>
              </template>
              <template v-if="additionPhrasePhraseAddMode">
              <van-field
                style="margin-top:.5rem"
                label="ID"
                type="text"
                v-model="inputed.additionPhrasesPhraseId"
                :placeholder="$t('common.inputText')"
                ></van-field>
              </template>
            <div class="centeredInline">
              <van-button style="margin-top:.5rem" @click="additionPhrasePhraseAddMode ? addAdditionPhrasePhrase(index) : additionPhrasePhraseAddMode = true" type="warning" size="small">{{ $t('common.add') }}</van-button>
            </div>
            </div>
            <van-radio-group v-model="inputed.additionPhrases[index].function">
              <van-cell-group :title="$t('modules.controlPanel.dialoguePhraseFunc')">
                <van-cell v-for="func in $t('modules.controlPanel.dialogueFuncs')" :key="`phrase-func-${func.value}`" :title="func.text" clickable @click="inputed.additionPhrases[index].function = func.value">
                  <template #right-icon>
                    <van-radio :name="func.value">
                      <template #icon="props">
                        <template v-if="props.checked">
                          <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                        </template>
                      </template>
                    </van-radio>
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
            <template v-if="inputed.additionPhrases[index].function === 'next-phrase'">
              <van-field
                :label="$t('modules.controlPanel.dialogueToPhrase')"
                style="margin:.5rem 0"
                type="text"
                v-model="inputed.additionPhrases[index].toPhrase"
                :placeholder="$t('common.inputText')"
                ></van-field>
            </template>
            <template v-if="inputed.additionPhrases[index].function === 'accept-quest'">
              <div class="infoTextSmall ml-05 mt-05">{{ $t('modules.controlPanel.dialogueQuests') }}</div>
              <template v-if="inputed.quests.length">
                <van-tag clickable @click="removeQuest(qi)" size="large" type="warning" class="mr-05" v-for="(q, qi) in inputed.quests" :key="`a-q-${q}-${qi}`">
                  {{ q }}
                </van-tag>
              </template>
              <template v-if="addQuestMode">
                <van-field
                  :label="$t('modules.controlPanel.dialogueQuestId')"
                  style="margin:.5rem 0"
                  type="number"
                  v-model.number="inputed.questIdToAdd"
                  :placeholder="$t('common.inputText')"
                  ></van-field>
              </template>
              <div class="centeredInline">
                <van-button style="margin-top:.5rem" @click="addQuestMode ? addQuest() : addQuestMode = true" type="warning" size="small">{{ $t('common.add') }}</van-button>
              </div>
            </template>
          </div>
          <div class="centeredInline row" style="margin-top:.5rem">
            <van-button @click="addAdditionPhrase" type="warning" size="small">{{ $t('modules.controlPanel.dialogueAddPhrase') }}</van-button>
            <van-button @click="save" :loading="savePending" :disabled="savePending" type="primary" style="margin-top:.75rem" block>{{ $t('common.save') }}</van-button>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'cpanel-add-dialogue-modal',
  data () {
    return {
      savePending: false,
      phraseOnOpenAddMode: false,
      addQuestMode: false,
      additionPhrasePhraseAddMode: false,
      inputed: {
        mainPhrase: '',
        phrasesOnOpen: [],
        phrasesOnOpenId: 'phrase-',
        additionPhrasesPhraseId: 'phrase-',
        questIdToAdd: '',
        quests: [],
        additionPhrases: [{
          id: 'phrase-1',
          phrases: [],
          text: '',
          function: '',
          btnText: '',
          toPhrase: 'phrase-'
        }]
      }
    }
  },
  computed: {
    ...mapState({
      showModal: state => state.cpanel.modals.addDialogue.show
    })
  },
  methods: {
    addQuest () {
      if (isNaN(this.inputed.questIdToAdd)) return false
      if (this.inputed.quests.some(q => +q === +this.inputed.questIdToAdd)) return this.$toast(this.$t('modules.quests.duplicateIdErr'))
      this.addQuestMode = false
      this.inputed.quests.push(this.inputed.questIdToAdd)
      this.inputed.questIdToAdd = ''
    },
    removeQuest (index = null) {
      if (isNaN(index) || index < 0 || !this.inputed.quests[index]) return false
      index ? this.inputed.quests.splice(index, 1) : this.inputed.quests.shift()
    },
    removeAdditionPhrasePhrase (phraseIndex = null, index = null) {
      if (isNaN(phraseIndex) || isNaN(index)) return false
      if (!this.inputed.additionPhrases[phraseIndex]) return false
      index ? this.inputed.additionPhrases[phraseIndex].phrases.splice(index, 1) : this.inputed.additionPhrases[phraseIndex].phrases.shift()
    },
    removeOnOpenPhrase (index = null) {
      if (isNaN(index)) return false
      index ? this.inputed.phrasesOnOpen.splice(index, 1) : this.inputed.phrasesOnOpen.shift()
    },
    addAdditionPhrasePhrase (index = null) {
      if (isNaN(index) || !this.inputed.additionPhrases[index] || !this.inputed.additionPhrasesPhraseId.length) return false
      this.additionPhrasePhraseAddMode = false
      this.inputed.additionPhrases[index].phrases.push(this.inputed.additionPhrasesPhraseId)
      this.inputed.additionPhrasesPhraseId = 'phrase-'
    },
    addOnOpenPhrase () {
      if (!this.inputed.phrasesOnOpenId.length) return false
      this.phraseOnOpenAddMode = false
      this.inputed.phrasesOnOpen.push(this.inputed.phrasesOnOpenId)
      this.inputed.phrasesOnOpenId = 'phrase-'
    },
    save () {
      this.checkDataBeforeSave().then(r => {
        this.savePending = true
        this.$client.service('dialogues').create(r).then(() => {
          this.savePending = false
          this.$toast(this.$t('modules.controlPanel.dialogueSaveOk'))
          this.closeModal()
        }).catch(() => {
          this.savePending = false
          this.$toast(this.$t('modules.controlPanel.dialogueSaveErr'))
        })
      }).catch(e => {
        this.$toast(e)
      })
    },
    async checkDataBeforeSave () {
      return new Promise((resolve, reject) => {
        if (this.inputed.mainPhrase.length < 5 || this.inputed.mainPhrase.length > 2048) return reject(this.$t('modules.controlPanel.dialoguePhraseLengthErr'))
        if (!this.inputed.phrasesOnOpen.length) return reject(this.$t('modules.controlPanel.dialogueInitPhrasesLengthErr'))
        if (!this.inputed.additionPhrases.length) return reject(this.$t('modules.controlPanel.dialogueAdditionPhrasesLengthErr'))
        if (this.inputed.additionPhrases.some(phrase => isNaN(phrase.id.replace('phrase-', '')))) return reject(this.$t('modules.controlPanel.dialoguePhraseIDErr'))
        if (this.inputed.additionPhrases.some(phrase => !['close-dialogue', 'accept-quest'].includes(phrase.function) && !phrase.phrases.length)) return reject(this.$t('modules.controlPanel.dialoguePhrasePhrasesLengthErr'))
        if (this.inputed.additionPhrases.some(phrase => phrase.function !== 'close-dialogue' && (phrase.text.length < 5 || phrase.text.length > 2048))) return reject(this.$t('modules.controlPanel.dialogueAdditionPhraseLengthErr'))
        if (this.inputed.additionPhrases.some(phrase => phrase.function === 'accept-quest') && !this.inputed.quests.length) return reject(this.$t('modules.controlPanel.dialogueQuestsLengthErr'))
        if (this.inputed.additionPhrases.some(phrase => phrase.function === 'next-phrase' && (!phrase.toPhrase.includes('phrase-') || isNaN(phrase.toPhrase.replace('phrase-', '')) || phrase.toPhrase === phrase.id))) return reject(this.$t('modules.controlPanel.dialogueToPhraseLinkErr'))
        return resolve({
          textOnOpen: this.inputed.mainPhrase,
          phrasesOnOpen: this.inputed.phrasesOnOpen,
          additionPhrases: this.inputed.additionPhrases,
          quests: this.inputed.quests
        })
      })
    },
    removeAdditionPhrase (index = null) {
      if (isNaN(index)) return false
      index ? this.inputed.additionPhrases.splice(index, 1) : this.inputed.additionPhrases.shift()
    },
    addAdditionPhrase () {
      this.inputed.additionPhrases = [
        ...this.inputed.additionPhrases,
        ...[{
          id: `phrase-${this.inputed.additionPhrases.length+1}`,
          text: '',
          phrases: [],
          btnText: '',
          function: '',
          toPhrase: 'phrase-'
        }]
      ]
    },
    closeModal () {
      this.$store.dispatch('cpanel/SHOW_ADD_DIALOGUE_MODAL', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    }
  }
}
</script>

<style>

</style>