const state = {
  ping: 25,
  modals: {
    itemInfo: {
      show: false,
      item: null
    },
    monsterInfo: {
      show: false,
      monster: null
    },
    npcInfo: {
      npc: null,
      show: false
    }
  },
  popups: {
    navigationMap: {
      show: false
    },
    chat: {
      show: false,
      selectedType: 'common'
    },
    menuGlobal: {
      show: false
    },
    npcDialogue: {
      show: false
    },
    fightOverlay: {
      show: false
    },
    victory: {
      show: false
    },
    defeat: {
      show: false
    }
  }
}
const getters = {}
const mutations = {
  setShowNavigationMapPopup (state, bool = false) {
    state.popups.navigationMap.show = bool
  },
  setShowChatPopup (state, bool = false) {
    state.popups.chat.show = bool
  },
  setShowMenuGlobalPopup (state, bool = false) {
    state.popups.menuGlobal.show = bool
  },
  setSelectedChatType (state, type) {
    state.popups.chat.selectedType = type
  },
  setShowNpcDialoguePopup (state, bool) {
    state.popups.npcDialogue.show = bool
  },
  setShowVictoryPopup (state, bool) {
    state.popups.victory.show = bool
  },
  setShowDefeatPopup (state, bool) {
    state.popups.defeat.show = bool
  },
  setShowFightOverlay (state, bool) {
    state.popups.fightOverlay.show = bool
  },
  setPing (state, ms) {
    state.ping = ms
  },
  setItemInfo (state, data = { item: null, show: false }) {
    state.modals.itemInfo.item = data.item
    setTimeout(() => {
      state.modals.itemInfo.show = data.show
    }, 100)
  },
  setMonsterInfo (state, data = { monster: null, show: false }) {
    state.modals.monsterInfo.monster = data.monster
    setTimeout(() => {
      state.modals.monsterInfo.show = data.show
    }, 100)
  },
  setNpcInfo (state, data = { npc: null, show: false }) {
    state.modals.npcInfo.npc = data.npc
    setTimeout(() => {
      state.modals.npcInfo.show = data.show
    }, 100)
  }
}
const actions = {
  SHOW_NAVIGATION_MAP_POPUP ({ commit }, bool = false) {
    commit('setShowNavigationMapPopup', bool)
  },
  SHOW_CHAT_POPUP ({ commit }, bool = false) {
    commit('setShowChatPopup', bool)
  },
  SHOW_VICTORY_POPUP ({ commit }, bool = false) {
    commit('setShowVictoryPopup', bool)
  },
  SHOW_DEFEAT_POPUP ({ commit }, bool = false) {
    commit('setShowDefeatPopup', bool)
  },
  SHOW_GLOBAL_MENU_POPUP ({ commit }, bool = false) {
    commit('setShowMenuGlobalPopup', bool)
  },
  SHOW_NPC_DIALOGUE_POPUP ({ commit }, bool = false) {
    commit('setShowNpcDialoguePopup', bool)
  },
  SET_SELECTED_CHAT_TYPE ({ commit }, type = null) {
    if (!type || !['common', 'global', 'location'].includes(type)) return false
    commit('setSelectedChatType', type)
  },
  SET_PING ({ commit }, ms = 50) {
    commit('setPing', ms)
  },
  SET_SHOW_FIGHT_OVERLAY ({ commit }, show = false) {
    commit('setShowFightOverlay', show)
  },
  SET_ITEM_INFO ({ commit }, data = { item: null, show: false }) {
    if (data.show && !data.item) return false
    commit('setItemInfo', data)
  },
  SET_MONSTER_INFO ({ commit }, data = { monster: null, show: false }) {
    if (data.show && !data.monster) return false
    commit('setMonsterInfo', data)
  },
  SET_NPC_INFO ({ commit }, data = { npc: null, show: false }) {
    if (data.show && !data.npc) return false
    commit('setNpcInfo', data)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}