<template>
  <div class="ib-network-latency" :class="this.pingClassName">
    {{ `${ms} ms` }}
    <van-loading size="8px" v-show="$store.state.common.positionSyncPending" :color="pingClassName==='high'?'#fff':'#000'" style="margin:0 0 0 .5rem;"></van-loading>
  </div>
</template>

<script>
export default {
  name: 'network-latency-component',
  data () {
    return {
    }
  },
  computed: {
    ms () {
      return this.$store.state.components.ping
    },
    pingClassName () {
      if (+this.ms < 200) {
        return 'low'
      } else if (+this.ms >= 200 && +this.ms < 500) {
        return 'middle'
      } else return 'high'
    }
  }
}
</script>

<style>

</style>