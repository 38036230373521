const state = {
  modals: {
    questInfo: {
      show: false
    }
  }
}
const getters = {}
const mutations = {
  setShowQuestInfo (state, bool) {
    state.modals.questInfo.show = bool
  }
}
const actions = {
  SHOW_QUEST_INFO ({ commit }, bool = false) {
    commit('setShowQuestInfo', bool)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}