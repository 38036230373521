<template>
  <van-overlay :z-index="11" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="modalTitle centeredInline">{{ item.name }}</div>
        <div class="centeredInline tinyText mb-05">
          {{ $t('modules.items.names')[item.type] }}
        </div>
        <div class="centeredInline">
          <div class="image-upload mt-05" style="padding:1rem">
            <img :src="cdnPath(item.image)" alt="" />
          </div>
        </div>
        <p>{{ item.description }}</p>
        <van-divider dashed>{{ $t('common.information') }}</van-divider>
        <ul>
          <li>{{ `${$t('common.rarity')}: `}}<span :class="`rarity-${item.rarity}`">{{`${$t('common.rarityNames')[item.rarity]}` }}</span></li>
          <li>{{ `${$t('modules.items.type')}: ${$t('modules.items.names')[item.type]}` }}</li>
          <li>{{ `${$t('modules.items.sellPrice')}: ${item.sellPrice} `}} <coin-icon size="1rem"></coin-icon></li>
          <li v-if="item.type==='wear'">{{ `${$t('common.level')}: ${item.equipLevel} ${$t('common.levelShort')}` }}</li>
          <template v-if="item.type==='wear'">
            <li>
              {{ `${$t('modules.items.equipClass')}: `}}
                <span class="ml-05" v-for="(eqClass, index) in item.equipClass" :key="`eq-class-${eqClass}`">
                  <svg-icon class="" :name="$t('modules.items.playerClasses')[eqClass].icon" size=".8rem"></svg-icon>
                  {{`${$t('modules.items.playerClasses')[eqClass].name }` }}
                  <template v-if="index+1 !== item.equipClass.length">
                    ,
                  </template>
                </span>
            </li>
            <li>
              {{ `${$t('modules.items.equipSlot')}: `}}
              <svg-icon class="ml-05" :name="$t('modules.items.equipTypes')[item.equipType].icon" size=".8rem"></svg-icon>
              {{`${$t('modules.items.equipTypes')[item.equipType].name}` }}
            </li>
            <li>
              <van-divider dashed>{{ $t('common.parameters') }}</van-divider>
              <ul>
                <li v-for="param in Object.keys($t('modules.parameters.types'))" :key="`eq-params-${param}`">
                  <svg-icon style="margin:0 .25rem" :name="$t('modules.parameters.types')[param].icon" size=".75rem"></svg-icon>
                  {{ $t('modules.parameters.types')[param].name }}
                  {{ item[$t('modules.parameters.types')[param].itemKey] > 0 ? `+${item[$t('modules.parameters.types')[param].itemKey]}` : item[$t('modules.parameters.types')[param].itemKey] }}
                </li>
                <li v-if="item.critChance">
                  <svg-icon style="margin:0 .25rem" name="critical" size=".75rem"></svg-icon>
                  {{ `${$t('modules.parameters.critChance')}: +${item.critChance}%` }}
                </li>
              </ul>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: 'global-component-item-info',
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
    }
  },
  computed: {
    showModal () {
      return this.$store.state.components.modals.itemInfo.show
    }
  },
  methods: {
    cancelQuest () {
      this.closeModal()
    },
    closeModal () {
      this.$store.dispatch('components/SET_ITEM_INFO', { item: this.item, show: false })
      setTimeout(() => {
        this.$emit('rerender')
        this.$store.dispatch('components/SET_ITEM_INFO', { item: null, show: false })
      }, 300)
    }
  }
}
</script>

<style>

</style>