import client from '../../services/api-client'
const state = {
  entryId: null,
  questsAccepted: [],
  questsDone: [],
  questsDoneDaily: [],
  monstersData: [],
  questsInfo: [],
  lastSync: null
}
const getters = {}
const mutations = {
  setEntry (state, entry) {
    state.entryId = entry.id || null
    state.questsAccepted = entry.questsAccepted || []
    state.questsDone = entry.questsDone || []
    state.questsDoneDaily = entry.questsDoneDaily || []
    state.monstersData = entry.questsMonstersData || []
    state.questsInfo = entry.questsInfo || []
    state.lastSync = entry.updatedAt
  }
}
const actions = {
  RESOLVE_ENTRY ({ commit }, id = null) {
    return new Promise((resolve, reject) => {
      if (!id || isNaN(id)) return reject('ID not defined')
      client.service('uactions').get(id).then(entry => {
        commit('setEntry', entry)
        return resolve(entry)
      }).catch(e => {
        return reject('Error resolving uactions entry')
      })
    })
  },
  UPDATE_ENTRY ({ commit }, entry = null) {
    if (!entry || !entry.id || !entry.questsAccepted || !entry.questsDoneDaily || !entry.questsDone) return false
    commit('setEntry', entry)
  },
  FLUSH_ENTRY ({ commit }) {
    commit('setEntry', null)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}