<template>
  <div>
    <template v-if="selectedPlayer">
      <add-items-to-rack-modal :key="`ai-m-${addItemModalKey}`" @rerender="addItemModalKey++" :uid="selectedPlayer.id" />
      <manage-coins-exp-modal @setcoins="setCoins" @setexp="setExp" :key="`amce-m-${manageCEModalKey}`" @rerender="manageCEModalKey++" :uid="selectedPlayer.id" />
      <manage-rack-modal @setsize="setRackSize" :key="`mr-m-${manageRackModalKey}`" @rerender="manageRackModalKey++" :uid="selectedPlayer.id" />
      <manage-quests-modal v-if="renderQuestsModal" :key="`mq-m-${manageQuestsModalKey}`" @rerender="manageQuestsModalKey++" :uid="selectedPlayer.id" />
      <div class="centeredInline row">
        <div class="infoText" style="line-height:0rem;margin-bottom:.5rem">{{ selectedPlayer.nickname }}</div>
        <div class="infoTextSmall">{{ `${$t(`common.${selectedPlayer.class}`)}, ${selectedPlayer.level} ${$t('common.levelShort')}` }}</div>
      </div>
        {{ `${$t('common.coins')}: ${abbrNum(selectedPlayer.gold)}, ${$t('modules.parameters.exp')}: ${abbrNum(selectedPlayer.exp)}, ${$t('modules.rack.title')}: ${selectedPlayer.rack ? `${selectedPlayer.rack.items.length} / ${selectedPlayer.rack.size}` : `? / ?`}` }}
      <div class="centeredInline">
        <van-button @click="selectedPlayer=null" block type="warning" size="small" style="margin:1rem auto">{{ $t('common.cancel') }}</van-button>
      </div>
      <van-cell @click="$store.dispatch('cpanel/SHOW_ADD_ITEMS_TO_RACK_MODAL', true)" clickable :title="$t('modules.controlPanel.players.addItems')">
        <template #icon>
          <div class="cell-icon">
            <fa-icon icon="cog"></fa-icon>
          </div>
        </template>
      </van-cell>
      <van-cell @click="$store.dispatch('cpanel/SHOW_MANAGE_PLAYER_RACK_MODAL', true)" clickable :title="$t('modules.controlPanel.players.manageItems')">
        <template #icon>
          <div class="cell-icon">
            <fa-icon icon="cog"></fa-icon>
          </div>
        </template>
      </van-cell>
      <van-cell @click="showQuestsModal(true)" clickable :title="$t('modules.controlPanel.players.manageQuests')">
        <template #icon>
          <div class="cell-icon">
            <fa-icon icon="cog"></fa-icon>
          </div>
        </template>
      </van-cell>
      <van-cell @click="$store.dispatch('cpanel/SHOW_MANAGE_PLAYER_COINS_EXP_MODAL', true)" clickable :title="$t('modules.controlPanel.players.manageGoldExp')">
        <template #icon>
          <div class="cell-icon">
            <fa-icon icon="cog"></fa-icon>
          </div>
        </template>
      </van-cell>
    </template>
    <template v-else>
      <div class="infoText">
        {{ $t('modules.controlPanel.players.uidPlaceholder') }}
      </div>
      <van-field @keyup.enter="findPlayer" type="number" v-model="inputed.uid" :placeholder="$t('modules.controlPanel.players.uidPlaceholder')" />
      <div class="centeredInline">
        <van-button :disabled="!inputed.uid" :loading="pending" @click="findPlayer" type="warning" style="margin-top:1rem">{{ $t('common.find') }}</van-button>
      </div>
    </template>
  </div>
</template>

<script>
import AddItemsToRackModal from './modals/AddItemsToRack.vue'
import ManageRackModal from './modals/ManageRack.vue'
import ManageQuestsModal from './modals/ManageQuests.vue'
import ManageCoinsExpModal from './modals/ManageCoinsExp.vue'
export default {
  components: {
    AddItemsToRackModal,
    ManageRackModal,
    ManageQuestsModal,
    ManageCoinsExpModal
  },
  data: () => ({
    selectedPlayer: null,
    pending: false,
    addItemModalKey: 0,
    manageRackModalKey: 0,
    manageQuestsModalKey: 0,
    manageCEModalKey: 0,
    renderQuestsModal: false,
    userPatched: null,
    inputed: {
      uid: ''
    }
  }),
  methods: {
    setCoins (coins = null) {
      if (!coins) return false
      this.selectedPlayer.gold = coins
    },
    setExp (exp = null) {
      if (!exp) return false
      this.selectedPlayer.exp = exp
    },
    showQuestsModal (show = false) {
      this.$nextTick(() => {
        this.renderQuestsModal = show
      })
      this.$store.dispatch('cpanel/SHOW_MANAGE_PLAYER_QUESTS_MODAL', show)
    },
    setRackSize (size = null) {
      if (!size) return false
      this.selectedPlayer.rack.size = size
    },
    findPlayer () {
      if (!this.inputed.uid) return false
      this.pending = true
      this.$client.service('getdata').find({
        query: {
          getUserdata: true,
          uid: this.inputed.uid
        }
      }).then(u => {
        this.selectedPlayer = u
        this.$nextTick(() => {
          this.pending = false
          this.inputed.uid = ''
        })
      }).catch(() => {
        this.$toast(this.$t('modules.controlPanel.players.notFound'))
        this.pending = false
      })
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.controlPanel.managePlayers'))
  },
  mounted () {
    this.userPatched = data => {
      if (this.selectedPlayer && +data.id === +this.selectedPlayer.id) {
        this.selectedPlayer = {
          ...this.selectedPlayer,
          ...data
        }
      }
    }
  }
}
</script>
