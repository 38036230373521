<template>
    <van-overlay :z-index="50" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline row">
          <div class="modalTitle van-ellipsis">{{ `${$t('modules.dialogue.testTitle')}` }}</div>
        </div>
        <div class="centeredInline infoText">
          <template v-if="!currPhraseId.length">
            {{ formatted.mainPhrase }}
          </template>
          <template v-else>
            {{ formatted.phrases[currPhraseId].text }}
          </template>
        </div>
        <van-cell @click="clickVariant(variant)" clickable v-for="(variant, index) in currPhraseId.length ? formatted.phrases[currPhraseId].phrases : formatted.initPhrases" :key="`d-i-phr-${index}`">
          <template #title>
            {{ formatted.phrases[variant].btnText }}
          </template>
        </van-cell>
      </div>
    </div>
  </van-overlay>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'cpanel-test-dialogue-modal',
  props: {
    dialogue: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      currPhraseId: '',
      formatted: {
        mainPhrase: null,
        initPhrases: [],
        phrases: {}
      }
    }
  },
  computed: {
    ...mapState({
      showModal: state => state.cpanel.modals.testDialogue.show
    })
  },
  methods: {
    clickVariant (variant = null) {
      if (!variant) return false
      let clickedVariant = this.formatted.phrases[variant]
      if (clickedVariant.function.length) {
        if (clickedVariant.function === 'close-dialogue') this.closeModal()
        return this.$toast(`${this.$t('modules.controlPanel.dialoguePhraseFunc')}: ${clickedVariant.function}`)
      }
      this.currPhraseId = variant
    },
    async formatDialogue () {
      this.formatted.mainPhrase = this.dialogue.textOnOpen
      this.formatted.initPhrases = this.dialogue.phrasesOnOpen
      await Promise.all(this.dialogue.additionPhrases.map(phrase => {
        this.formatted.phrases[phrase.id] = phrase
      }))
    },
    closeModal () {
      this.$store.dispatch('cpanel/SHOW_TEST_DIALOGUE_MODAL', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    }
  },
  mounted () {
    this.formatDialogue()
  }
}
</script>

<style>

</style>