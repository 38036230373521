<template>
  <div>
    <div class="centered" v-if="!userinfo || !user">
      <van-loading></van-loading>
    </div>
    <div v-else>
      <div class="centeredInline">
        <h3>{{ `${userinfo.nickname}, ${$t(`common.${userinfo.class}`)}, ${user.level} ${$t('common.levelShort')}` }}</h3>
      </div>
      <div class="centeredInline underline pointer" v-if="!user.savedAccount" @click="$store.dispatch('common/SHOW_SAVE_CHARACTER_MODAL', true)">
        <svg-icon name="star" size="16px"></svg-icon> {{ $t('modules.character.saveCharacter') }}
      </div>
      <div class="char_eq_preview">
        <div>
          <div><svg-icon name="left-hand" color="#ffffff40"></svg-icon></div>
          <div><svg-icon name="right-hand" color="#ffffff40"></svg-icon></div>
          <div><svg-icon name="helm" color="#ffffff40"></svg-icon></div>
          <div><svg-icon name="chest-armor" color="#ffffff40"></svg-icon></div>
          <div><svg-icon name="gloves" color="#ffffff40"></svg-icon></div>
        </div>
        <div>
          <div class="char_img_preview">
            <img :src="classImg" alt="" />
          </div>
          <div>
            <svg-icon name="artifact" color="#ffffff40"></svg-icon>
          </div>
        </div>
        <div>
          <div><svg-icon name="cloak" color="#ffffff40"></svg-icon></div>
          <div><svg-icon name="ring" color="#ffffff40"></svg-icon></div>
          <div><svg-icon name="necklace" color="#ffffff40"></svg-icon></div>
          <div><svg-icon name="boots" color="#ffffff40"></svg-icon></div>
          <div><svg-icon name="amulet" color="#ffffff40"></svg-icon></div>
        </div>
      </div>
      <div class="centeredInline infoText">
        {{ $t('modules.character.statsInfoDesc') }}
      </div>
      <van-cell clickable>
        <template #title>
          <svg-icon name="stamina" size="16px"></svg-icon>
          {{ $t('common.stamina') }}
        </template>
        <template #extra>
          {{ user.stamina }}
        </template>
      </van-cell>
      <van-cell clickable>
        <template #title>
          <svg-icon name="strength" size="16px"></svg-icon>
          {{ $t('common.strength') }}
        </template>
        <template #extra>
          {{ user.strength }}
        </template>
      </van-cell>
      <van-cell clickable>
        <template #title>
          <svg-icon name="dexterity" size="16px"></svg-icon>
          {{ $t('common.dexterity') }}
        </template>
        <template #extra>
          {{ user.dexterity }}
        </template>
      </van-cell>
      <van-cell clickable>
        <template #title>
          <svg-icon name="intelligence" size="16px"></svg-icon>
          {{ $t('common.intelligence') }}
        </template>
        <template #extra>
          {{ user.intelligence }}
        </template>
      </van-cell>
      <van-cell clickable>
        <template #title>
          <svg-icon name="exp" size="16px"></svg-icon>
          {{ $t('modules.parameters.exp') }}
        </template>
        <template #extra>
          {{ abbrNum(user.exp) }}
        </template>
      </van-cell>
      <van-cell clickable>
        <template #title>
          <svg-icon name="gold" size="16px"></svg-icon>
          {{ $t('common.coins') }}
        </template>
        <template #extra>
          {{ abbrNum(user.gold) }}
        </template>
      </van-cell>
      <div class="centeredInline infoText">
        {{ $t('modules.character.careerInfoDesc') }}
      </div>
      <van-cell clickable v-for="(career, index) in Object.keys(userinfo.careerInfo)" :key="index">
        <template #title>
          <svg-icon :name="career" size="16px"></svg-icon>
          {{ `${$t(`common.${career}`)}` }}
        </template>
        <template #extra>
          {{ userinfo.careerInfo[career].exp }}
        </template>
      </van-cell>
      <div class="centeredInline mt-05">
        <span class="underline pointer" style="color:salmon" @click="logout">
          {{$t('common.logout')}}
        </span>
        <span style="margin:0 0 0 .75rem" class="underline pointer" @click="$router.push('/control-panel')" v-if="user.role!=='usr'">
          {{$t('modules.controlPanel.title')}}
        </span>
      </div>
    </div>
    <save-character-modal v-if="!user.savedAccount"></save-character-modal>
  </div>
</template>

<script>
import SaveCharacterModal from './modals/SaveCharacter.vue'
export default {
  name: 'character-info-index',
  components: {
    SaveCharacterModal
  },
  data () {
    return {
      userinfo: null
    }
  },
  computed: {
    classImg () {
      return `/assets/gamedata/anims/characters/${this.user.class}${this.user.gender==='male'?'M':'F'}.gif`
    }
  },
  methods: {
    logout () {
      let self = this
      this.$dialog.confirm({
        title: self.$t('common.logout'),
        message: self.$t('modules.logout.logoutQ'),
        confirmButtonText: self.$t('common.logout'),
        cancelButtonText: self.$t('common.cancel'),
        showCancelButton: true,
        className: 'confirm'
      }).then(() => {
        this.$store.dispatch('auth/LOGOUT').then(() => {
          this.$worker.postMessage({cmd:'logout'})
          this.$router.replace('/welcome')
          document.location.reload()
        }).catch(e => {
          console.log('LOGOUT ERROR', e)
          this.$toast(this.$t('common.errorOccured'))
        })
      }).catch(() => {
        // 
      })
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.character.title'))
  },
  mounted () {
    this.userinfo = this.userInfo()
    this.$store.dispatch('common/SET_APP_TITLE', this.userinfo.nickname)
  }
}
</script>

<style>
@import url(../../assets/css/character.css);
</style>