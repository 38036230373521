<template>
  <svg-icon name="gold" :color="color" :size="size"></svg-icon>
</template>

<script>
export default {
  name: 'svg-icon-global',
  props: {
    size: {
      type: String,
      default: "32px"
    },
    color: {
      type: String,
      default: "#ffb703"
    }
  }
}
</script>

<style>

</style>