<template>
  <div>
    <van-cell-group style="margin-bottom:.5rem" :title="$t('modules.stats.title')">
      <van-cell :title="$t('modules.stats.savedPlayers')">
        <template #extra>
          <van-loading v-if="pending.players.saved"></van-loading>
          <template v-else>{{ total.players.saved }}</template>
        </template>
      </van-cell>
      <van-cell :title="$t('modules.stats.notSavedPlayers')">
        <template #extra>
          <van-loading v-if="pending.players.notSaved"></van-loading>
          <template v-else>{{ total.players.notSaved }}</template>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell v-if="['sv'].includes(user.role)" to="/control-panel/app-settings" center :title="$t('modules.controlPanel.appSettingsTitle')" size="large" clickable>
      <template #icon>
        <div class="cell-icon">
          <fa-icon icon="cog"></fa-icon>
        </div>
      </template>
      <template #label>
        {{ $t('modules.controlPanel.appSettingsDescr') }}
      </template>
    </van-cell>
    <van-cell v-if="['adm', 'sv'].includes(user.role)" to="/control-panel/maps-settings" center :title="$t('modules.controlPanel.mapDataTitle')" size="large" clickable>
      <template #icon>
        <div class="cell-icon">
          <fa-icon icon="cog"></fa-icon>
        </div>
      </template>
      <template #label>
        {{ $t('modules.controlPanel.mapDataDescr') }}
      </template>
    </van-cell>
    <van-cell v-if="['sv'].includes(user.role)" to="/control-panel/init-params-settings" center :title="$t('modules.controlPanel.initParamsTitle')" size="large" clickable>
      <template #icon>
        <div class="cell-icon">
          <fa-icon icon="cog"></fa-icon>
        </div>
      </template>
      <template #label>
        {{ $t('modules.controlPanel.initParamsDesc') }}
      </template>
    </van-cell>
    <van-cell v-if="['adm', 'sv'].includes(user.role)" to="/control-panel/manage-quests" center :title="$t('modules.controlPanel.manageQuests')" size="large" clickable>
      <template #icon>
        <div class="cell-icon">
          <fa-icon icon="cog"></fa-icon>
        </div>
      </template>
      <template #label>
        {{ $t('modules.controlPanel.manageQuestsDesc') }}
      </template>
      <template #extra>
        <van-loading v-if="pending.quests"></van-loading>
        <template v-else>{{ total.quests }}</template>
      </template>
    </van-cell>
    <van-cell v-if="['adm', 'sv'].includes(user.role)" to="/control-panel/manage-monsters" center :title="$t('modules.controlPanel.manageMonstersTitle')" size="large" clickable>
      <template #icon>
        <div class="cell-icon">
          <fa-icon icon="cog"></fa-icon>
        </div>
      </template>
      <template #label>
        {{ $t('modules.controlPanel.manageMonstersDesc') }}
      </template>
      <template #extra>
        <van-loading v-if="pending.monsters"></van-loading>
        <template v-else>{{ total.monsters }}</template>
      </template>
    </van-cell>
    <van-cell v-if="['adm', 'sv'].includes(user.role)" to="/control-panel/manage-dialogues" center :title="$t('modules.controlPanel.manageDialogues')" size="large" clickable>
      <template #icon>
        <div class="cell-icon">
          <fa-icon icon="cog"></fa-icon>
        </div>
      </template>
      <template #label>
        {{ $t('modules.controlPanel.manageDialoguesDescr') }}
      </template>
      <template #extra>
        <van-loading v-if="pending.dialogues"></van-loading>
        <template v-else>{{ total.dialogues }}</template>
      </template>
    </van-cell>
    <van-cell v-if="['adm', 'sv'].includes(user.role)" to="/control-panel/manage-players" center :title="$t('modules.controlPanel.managePlayers')" size="large" clickable>
      <template #icon>
        <div class="cell-icon">
          <fa-icon icon="cog"></fa-icon>
        </div>
      </template>
      <template #label>
        {{ $t('modules.controlPanel.managePlayersDesc') }}
      </template>
    </van-cell>
    <van-cell v-if="['sv'].includes(user.role)" to="/control-panel/manage-npc" center :title="$t('modules.controlPanel.manageNpc')" size="large" clickable>
      <template #icon>
        <div class="cell-icon">
          <fa-icon icon="cog"></fa-icon>
        </div>
      </template>
      <template #label>
        {{ $t('modules.controlPanel.manageNpcDesc') }}
      </template>
      <template #extra>
        <van-loading v-if="pending.npcs"></van-loading>
        <template v-else>{{ total.npcs }}</template>
      </template>
    </van-cell>
    <van-cell v-if="['sv'].includes(user.role)" to="/control-panel/manage-items" center :title="$t('modules.controlPanel.manageItemsTitle')" size="large" clickable>
      <template #icon>
        <div class="cell-icon">
          <fa-icon icon="cog"></fa-icon>
        </div>
      </template>
      <template #label>
        {{ $t('modules.controlPanel.manageItemsDesc') }}
      </template>
      <template #extra>
        <van-loading v-if="pending.items"></van-loading>
        <template v-else>{{ total.items }}</template>
      </template>
    </van-cell>
  </div>
</template>

<script>
export default {
  name: 'control-panel-index',
  data () {
    return {
      pending: {
        quest: true,
        monsters: true,
        dialogues: true,
        npcs: true,
        items: true,
        players: {
          saved: true,
          notSaved: true
        }
      },
      total: {
        quest: 0,
        monsters: 0,
        dialogues: 0,
        npcs: 0,
        items: 0,
        players: {
          saved: 0,
          notSaved: 0
        }
      }
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.controlPanel.title'))
  },
  async mounted () {
    this.total.players.saved = await this.$client.service('users').find({
      query: {
        $limit: 0,
        savedAccount: true
      }
    }).then(sp => sp.total).catch(() => 0)
    this.pending.players.saved = false
    this.total.players.notSaved = await this.$client.service('users').find({
      query: {
        $limit: 0,
        savedAccount: false
      }
    }).then(nsp => nsp.total).catch(() => 0)
    this.pending.players.notSaved = false
    this.total.quests = await this.$client.service('quests').find({
      query: {
        $limit: 0
      }
    }).then(q => q.total).catch(() => 0)
    this.pending.quests = false
    this.total.monsters = await this.$client.service('monsters').find({
      query: {
        $limit: 0
      }
    }).then(m => m.total).catch(() => 0)
    this.pending.monsters = false
    this.total.dialogues = await this.$client.service('dialogues').find({
      query: {
        $limit: 0
      }
    }).then(d => d.total).catch(() => 0)
    this.pending.dialogues = false
    this.total.npcs = await this.$client.service('npc').find({
      query: {
        $limit: 0
      }
    }).then(n => n.total).catch(() => 0)
    this.pending.npcs = false
    this.total.items = await this.$client.service('items').find({
      query: {
        $limit: 0
      }
    }).then(i => i.total).catch(() => 0)
    this.pending.items = false
  }
}
</script>

<style>

</style>