<template>
  <div>
    <template v-if="sceneInfo">
    <div class="map_main" ref="__map">
      <div class="map_main-wrp">
        <div class="ib-options">
          <div v-if="showTopPlayerAction" @click.stop="user.inCombat?escapeCombat():safeAreaTeleport()">
            <fa-icon :icon="user.inCombat?'running':'hands-helping'"></fa-icon>
          </div>
          <div @click.stop="showMap" v-if="!user.inCombat">
            <fa-icon icon="globe"></fa-icon>
          </div>
        </div>
        <div :id="containerId" v-if="downloaded" class="game_render_container">
          <div class="infoText">
            {{ $t('common.mapLoading') }}
          </div>
        </div>
        <div class="placeholder centered infoText" v-else>
          {{ $t('common.mapLoading') }}
        </div>
        <div class="map-nav-buttons">
          <div v-touch:end="onMouseUp('left')" v-touch:start="onMouseDown('left')"></div>
          <div>
            <div v-touch:end="onMouseUp('up')" v-touch:start="onMouseDown('up')"></div>
            <div v-touch:end="onMouseUp('down')" v-touch:start="onMouseDown('down')"></div>
          </div>
          <div v-touch:end="onMouseUp('right')" v-touch:start="onMouseDown('right')"></div>
        </div>
      </div>
    </div>
    <navigation-map></navigation-map>
    <map-overlay></map-overlay>
    </template>
    <div class="centeredInline map_main" v-else>
      {{ $t('common.resolveSceneError') }}
    </div>
    <div ref="showSlot">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import throttle from 'lodash-es/throttle'
import EventDispatcher from '../game/utils/EventDispatcher'
import { getGameContainerSize } from '../game/utils'
import NavigationMap from '../components/NavigationMap.vue'
import ChatOverlay from './MapChatOverlay.vue'
import MapOverlay from './MapOverlay.vue'
export default {
  name: 'map-component',
  components: {
    NavigationMap,
    ChatOverlay,
    MapOverlay
  },
  data () {
    return {
      downloaded: false,
      gameInstance: null,
      containerId: 'game-container',
      pressedKey: null,
      sceneInfo: null,
      currentMap: null,
      mapsData: [],
      mapsPatched: null,
      eventEmitter: null,
      userOnline: null,
      userOffline: null,
      movementStoppedHandler: null,
      onButtonsDown: null,
      resizeHandler: null
    }
  },
  computed: {
    showTopPlayerAction () {
      if (this.user.inCombat) {
        return this.combat ? this.combat.canEscape : false
      } else return true
    },
    combat () {
      return this.$store.state.combat.entry
    },
    ...mapState({
      firstRenderMap: state => state.common.firstRenderedMap,
      mapVisible: state => state.common.mapVisible,
      positionSyncPending: state => state.common.positionSyncPending
    })
  },
  methods: {
    escapeCombat () {
      let self = this
      this.$dialog.confirm({
        title: self.$t('modules.combat.escapeCombatTitle'),
        message: `${self.$t('modules.combat.escapeCombatQ', { chance: self.combat.escapeChance * 100 })}`,
        confirmButtonText: self.$t('common.confirm'),
        cancelButtonText: self.$t('common.cancel'),
        showCancelButton: true,
        className: 'confirm'
      }).then(() => {
        self.$client.service('combat').patch(self.user.combatId, {
          escapeCombat: true
        }).then(r => {
          if (r.result === 'ok') {
            self.$toast(self.$t('modules.combat.escapeCombatOk'))
          } else self.$toast(self.$t('modules.combat.escapeCombatError'))
        }).catch(e => {
          console.log(e)
          self.$toast(self.$t('modules.combat.escapeCombatError'))
        })
      }).catch(() => {
        return
      })
    },
    onMouseUp (param = null) {
      const _this = this
      return function(direction, event) {
        if (!param || !['up', 'down', 'left', 'right'].includes(param)) return false
        _this.pressedKey = _this.pressedKey === param ? null : _this.pressedKey
      }
    },
    onMouseDown (param = null) {
      const _this = this
      return function (direction, event) {
        if (!param || !['up', 'down', 'left', 'right'].includes(param) || _this.positionSyncPending) return false
        _this.pressedKey = param
        _this.move(_this.pressedKey)
      }
    },
    move: throttle(function (direction = null) {
      if (this.positionSyncPending) return false
      if (!direction || this.user.inCombat || this.user.isDead) return false
      let currPos = {
        x: this.user.coorsX,
        y: this.user.coorsY
      }
      let newPos = { x: null, y: null }
      switch (direction) {
        case 'up':
          newPos.x = +currPos.x
          newPos.y = +currPos.y - 1
          break
        case 'right':
          newPos.x = +currPos.x + 1
          newPos.y = +currPos.y
          break
        case 'down':
          newPos.x = +currPos.x
          newPos.y = +currPos.y + 1
          break
        case 'left':
          newPos.x = +currPos.x - 1
          newPos.y = +currPos.y
          break
        default:
          return false
      }
      // console.log(`curr: x-${currPos.x}, y-${currPos.y}, newPos: x-${newPos.x}, y-${newPos.y}`)
      this.eventEmitter.emit('moveTo', {
        direction: direction,
        playerId: 'current-player',
        coordinates: newPos
      })
      if (this.pressedKey) throttle(function () {
        this.move(this.pressedKey)
      }, 500)
    }, 500),
    showMap () {
      this.$store.dispatch('components/SHOW_NAVIGATION_MAP_POPUP', true)
    },
    safeAreaTeleport () {
      let self = this
      this.$dialog.confirm({
        title: null,
        message: self.$t('modules.character.mapStuckQ'),
        confirmButtonText: self.$t('common.confirm'),
        cancelButtonText: self.$t('common.cancel'),
        showCancelButton: true,
        className: 'confirm'
      }).then(() => {
        self.$client.service('userdata').patch(self.user.id, {
          safeAreaTeleport: true
        }).then(() => {
          self.$toast(self.$t('modules.character.safeAreaTeleportOk'))
          self.eventEmitter.emit('restartScene')
        }).catch(e => {
          console.log('Error teleporting to safe location', e)
          self.$toast(self.$t('modules.character.safeAreaTeleportError'))
        })
      }).catch(() => {
        // 
      })
    },
    parseMapsData (data = null) {
      if (!data) return false
      return Object.keys(data.titles).map((key) => {
        return {
          mapId: Number(key),
          title: data.titles[key]
        }
      })
    }
  },
  watch: {
    'mapVisible' () {
      if (this.user && this.user.mapId && this.currentMap && this.mapVisible) {
        this.$store.dispatch('common/SET_APP_TITLE', this.currentMap.title)
      }
    },
    'user' () {
      if (this.currentMap && this.user.mapId !== this.currentMap.id) {
        const index = this.mapsData.findIndex(m => m.mapId === this.user.mapId)
        if (index > -1) {
          this.currentMap = this.mapsData[index]
          if (this.$route.path === '/') this.$store.dispatch('common/SET_APP_TITLE', this.currentMap.title)
        }
      }
    }
  },
  async mounted() {
    this.resizeHandler = () => {
      const newSize = getGameContainerSize()
      this.$refs['__map'].style.width = `${newSize.width}px`
      this.$refs['__map'].style.height = `${newSize.height}px`
    }
    this.sceneInfo = this.resolveScene()
    this.eventEmitter = EventDispatcher.getInstance()
    if (!this.uSettings && this.user.settingsId) {
      await this.$store.dispatch('settings/RESOLVE_ENTRY', this.user.settingsId).catch(e => {
        console.log('error resolving settings entry', e)
      })
    }
    if (this.user.inCombat && this.user.combatId) {
      await this.$store.dispatch('combat/RESOLVE_COMBAT').catch(e => {
        console.log('Error resolving combat entry', e)
      })
    }
    if (this.sceneInfo) {
      const game = await import(/* webpackChunkName: "game-screen" */ `@/game/scenes/map/index.js`)
      this.downloaded = true
      this.$nextTick(() => {
        this.gameInstance = game.launch(this.containerId, this.uSettings ? this.uSettings.gameFps : 60)
        this.$refs['__map'].style.height = `${getGameContainerSize().height}px`
      })
    }
    if (this.user && this.user.mapId) {
      this.mapsData = await this.$client.service('app-settings').get(2).then(m => {
        this.currentMap = {
          mapId: this.user.mapId,
          title: m.titles[this.user.mapId]
        }
        if (this.$route.path === '/') this.$store.dispatch('common/SET_APP_TITLE', m.titles[this.user.mapId])
        return this.parseMapsData(m)
      }).catch(e => {
        console.log(e)
        this.$toast(this.$t('common.resolveSceneError'))
      })
    }
    this.mapsPatched = data => {
      if (+data.id === 2) {
        this.mapsData = this.parseMapsData(data)
        const index = this.mapsData.findIndex(m => m.id === this.user.mapId)
        if (index > -1) {
          this.currentMap = this.mapsData[index]
          if (this.$route.path === '/') this.$store.dispatch('common/SET_APP_TITLE', this.currentMap.title)
        }
      }
    }
    this.userOnline = data => {
      if (+data.mapId === +this.currentMap.mapId) {
        this.eventEmitter.emit('player-online', data)
      }
    }
    this.userOffline = data => {
      if (+data.mapId === +this.currentMap.mapId) {
        this.eventEmitter.emit('player-offline', data)
      }
    }
    this.onButtonsDown = e => {
      if (this.positionSyncPending) return false
      if (e.keyCode === 38 /* up */ || e.keyCode === 87 /* w */){
        this.pressedKey = 'up'
      }
      if (e.keyCode === 39 /* right */ || e.keyCode === 68 /* d */){
        this.pressedKey = 'right'
      }
      if (e.keyCode === 40 /* down */ || e.keyCode === 83 /* s */){
        this.pressedKey = 'down'
      }
      if (e.keyCode === 37 /* left */ || e.keyCode === 65 /* a */){
        this.pressedKey = 'left'
      }
      this.move(this.pressedKey)
    }
    this.onButtonsUp = e => {
      if (e.keyCode === 38 /* up */ || e.keyCode === 87 /* w */){
        this.pressedKey = this.pressedKey === 'up' ? null : this.pressedKey
      }
      if (e.keyCode === 39 /* right */ || e.keyCode === 68 /* d */){
        this.pressedKey = this.pressedKey === 'right' ? null : this.pressedKey
      }
      if (e.keyCode === 40 /* down */ || e.keyCode === 83 /* s */){
        this.pressedKey = this.pressedKey === 'down' ? null : this.pressedKey
      }
      if (e.keyCode === 37 /* left */ || e.keyCode === 65 /* a */){
        this.pressedKey = this.pressedKey === 'left' ? null : this.pressedKey
      }
    }
    this.movementStoppedHandler = data => {
      if (data === 'current-player' && this.pressedKey) this.move(this.pressedKey)
    }
    this.eventEmitter.on('movementStopped', this.movementStoppedHandler)
    document.addEventListener('keyup', this.onButtonsUp)
    document.addEventListener('keydown', this.onButtonsDown)
    window.addEventListener('resize', this.resizeHandler)
    this.$client.service('app-settings').on('patched', this.mapsPatched)
    this.$client.service('app-settings').on('updated', this.mapsPatched)
    this.$client.service('signals').on('user-online', this.userOnline)
    this.$client.service('signals').on('user-offline', this.userOffline)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeHandler)
    this.$client.service('app-settings').removeListener('patched', this.mapsPatched)
    this.$client.service('app-settings').removeListener('updated', this.mapsPatched)
    this.$client.service('signals').removeListener('user-online', this.userOnline)
    this.$client.service('signals').removeListener('user-offline', this.userOffline)
    document.removeEventListener('keyup', this.onButtonsUp)
    document.removeEventListener('keydown', this.onButtonsDown)
    this.eventEmitter.on('movementStopped', this.movementStoppedHandler)
    this.mapsPatched = this.userOffline = this.userOnline = this.movementStoppedHandler = this.onButtonsUp = this.onButtonsDown = this.movementStoppedHandler = this.resizeHandler = null
  },
  destroyed() {
    if (this.gameInstance) this.gameInstance.destroy(false)
  }
}
</script>

<style>

</style>