<template>
  <van-popup
    v-model="showPopup"
    position="bottom">
    <div class="centeredInline row">
      <h3>
        <fa-icon icon="globe"></fa-icon>
        {{$t('common.map')}} ({{user.mapId}})
        <div style="font-size:50%">{{`${$t('common.coordinates')}: x: ${user.coorsX}, y: ${user.coorsY}`}}</div>
      </h3>
      <div class="map-visualizer" style="margin:0 0 1rem 0;">
        <div v-for="num in locsNum" :key="num" :style="{
          backgroundImage: `url(/assets/mapvisualizer/maps/${num}.png)`,
          transform: user.mapId === num ? 'scale(2)' : 'none',
          boxShadow: user.mapId === num ? '0px 0px 0px 1px #b63a3a' : 'none',
          zIndex: user.mapId === num ? 5 : 1
        }">
        <div class="map-visualizer-dot" v-if="user.mapId === num" :style="{
          ...calculateDotPosition()
        }">
        </div>
        <div class="ringring" v-if="user.mapId === num" :style="{
          ...calculatePulsatingPosition()
        }"></div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'map-navigation-inline-component',
  data () {
    return {
      locsNum: 100
    }
  },
  computed: {
      showPopup: {
        get () {
          return this.$store.state.components.popups.navigationMap.show
        },
        set (value) {
          this.$store.dispatch('components/SHOW_NAVIGATION_MAP_POPUP', value)
        }
      }
  },
  methods: {
    calculateDotPosition () {
      const xPerc = 100 / 50
      const yPerc = 100 / 50
      return {
        left: `${Math.round(xPerc * this.user.coorsX)}%`,
        top: `${Math.round(yPerc * this.user.coorsY)}%`
      }
    },
    calculatePulsatingPosition () {
      const xPerc = 100 / 50
      const yPerc = 100 / 50
      return {
        left: `calc(${Math.round(xPerc * this.user.coorsX)}% - 3.5px)`,
        top: `calc(${Math.round(yPerc * this.user.coorsY)}% - 3.5px)`
      }
    }
  }
}
</script>

<style>

</style>