import { mapState } from 'vuex'
import locMapping from '../game/scenes/map/mapping.json'
import { cdnServerUrl, locale } from '../config'
export default {
  data () {
    return {
      classIcons: {
        warrior: "sword-1",
        paladin: "hammer",
        assasin: "shuriken",
        mage: "staff",
        necromancer: "necro-staff"
      }
    }
  },
  computed: {
    clientLocale () {
      return locale
    },
    ...mapState({
      user: state => state.auth.user,
      combat: state => state.combat.entry,
      uSettings: state => state.settings.entry,
      uActions: state => state.uactions,
      rackItems: state => state.rack.items
    })
  },
  methods: {
    isQuestAvailable (qid = null) {
      let uactionsState = this.$store.state.uactions
      if (!qid || isNaN(qid) || !uactionsState.entryId) return false
      return !uactionsState.questsAccepted.includes(qid) && !uactionsState.questsDone.includes(qid) && !uactionsState.questsDoneDaily.includes(qid)
    },
    isQuestDone (quest = null) {
      if (quest && quest.targetData) {
        if (quest.targetType === 'item') {
          if (!this.rackItems.length) return false
          const checkItems = quest.targetData.every(item => {
            if (!this.rackItems.some(rItem => +rItem.itemId === +item.id)) {
              return false
            } else {
              const itemPosition = this.rackItems.findIndex(rItem => +rItem.itemId === +item.id)
              if (itemPosition > -1) {
                return this.rackItems[itemPosition].stackCount >= item.quantity
              } else return false
            }
          })
          return checkItems
        } else if (quest.targetType === 'monster') {
          let mdi = this.uActions.monstersData.findIndex(md => +md.questId === +quest.id)
          if (mdi > -1) {
            return !this.uActions.monstersData[mdi].data.some(mde => mde.needed > mde.killed)
          } else return false
        } else return false
      }
    },
    getQuestGiverId (questId = null) {
      if (isNaN(questId)) return false
      let qgi = this.uActions.questsInfo.findIndex(qg => +qg.questId === +questId)
      if (qgi > -1) {
        return this.uActions.questsInfo[qgi].acceptedFrom.id
      } else return false
    },
    questTargetProgress (quest = null) {
      if (!quest) return null
      return {
        
      }
    },
    cdnPath (url) {
      return cdnServerUrl + url
    },
    resolveLevel (user = null) {
      return 1
    },
    resolveScene (sid = null) {
      if (!sid && !this.user.mapId) return null
      if (sid && isNaN(sid)) return null
      let index = locMapping.maps.findIndex(sc => (+sid || +this.user.mapId) === +sc.id)
      if (index > -1) {
        return locMapping.maps[index]
      } else return null
    },
    userInfo (user = null) {
      user = user || this.user
      if (!user) return null
      user.nickname = user.savedAccount ? user.nickname : `${this.$t('common.defaultNickname')}_#${user.id}`
      return user
    },
    setAppTitle (title = null) {
      if (!title) return false
      return this.$store.dispatch('common/SET_APP_TITLE', title)
    },
    classIcon (className = null) {
      if (!className) return false
      if (!this.classIcons.hasOwnProperty(className)) return console.error('Class icon not found')
      return this.classIcons[className]
    },
    abbrNum (number, decPlaces = 2) {
      decPlaces = Math.pow(10,decPlaces)
      var abbrev = [ "K", "M", "B", "T" ]
      for (var i=abbrev.length-1; i>=0; i--) {
        var size = Math.pow(10,(i+1)*3)
        if(size <= number) {
          number = Math.round(number*decPlaces/size)/decPlaces
          if((number == 1000) && (i < abbrev.length - 1)) {
            number = 1
            i++
          }
          number += abbrev[i]
          break
        }
      }
      return number
    }
  }
}