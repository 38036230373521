<template>
  <div>
    <div class="ib-app-footer" v-if="user">
      <div class="ib-app-footer-wrp">
        <div class="centeredInline pointer" @click.stop="redirectBtn">
          <svg-icon :name="$route.path!=='/'?'map':'helm'" color="#aeaeae"></svg-icon>
        </div>
        <div>
          <chat-overlay></chat-overlay>
        </div>
        <div class="centeredInline pointer" @click.stop="$store.dispatch('components/SHOW_GLOBAL_MENU_POPUP', true)">
          <svg-icon name="hamburger" color="#aeaeae"></svg-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '../componentsGlobal/SvgIcon.vue'
import ChatOverlay from '../components/MapChatOverlay.vue'
export default {
  components: { SvgIcon, ChatOverlay },
  name: 'app-footer-component',
  data () {
    return {

    }
  },
  methods: {
    redirectBtn () {
      this.$router.push(this.$route.path!=='/'?'/':'/character')
    },
    logout () {
      let self = this
      this.$dialog.confirm({
        title: self.$t('common.logout'),
        message: self.$t('modules.logout.logoutQ'),
        confirmButtonText: self.$t('common.logout'),
        cancelButtonText: self.$t('common.cancel'),
        showCancelButton: true,
        className: 'confirm'
      }).then(() => {
        this.$store.dispatch('auth/LOGOUT').then(() => {
          this.$worker.postMessage({cmd:'logout'})
          this.$router.replace('/welcome')
          document.location.reload()
        }).catch(e => {
          console.log('LOGOUT ERROR', e)
          this.$toast(this.$t('common.errorOccured'))
        })
      }).catch(() => {
        return
      })
    }
  }
}
</script>

<style>

</style>