<template>
  <van-overlay :z-index="200" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline row">
          <div class="modalTitle van-ellipsis">{{ $t('modules.controlPanel.mapVisualizerTitle') }}</div>
          <div class="map-visualizer">
            <div v-for="(num, index) in 100" :key="index" @click="selectLoc(index)" :style="`background-image:url(/assets/mapvisualizer/maps/${num}.png)`">
              {{ num }}
            </div>
          </div>
        </div>
        <div class="infoText">1. {{ $t('common.cities') }}</div>
        <van-cell clickable size="large" v-for="(city, index) in $t('modules.mapVisualizer.cities')" @click="selectLoc(city.id)" :title="city.name" :key="`${index}-${index}`"></van-cell>
        <div class="infoText">2. {{ $t('modules.controlPanel.buildingsSettings') }}</div>
        <van-cell clickable size="large" v-for="(building, index) in $t('modules.mapVisualizer.buildings')" @click="selectLoc(building.id)" :title="building.name" :key="`${index}-${index}-${index}`"></van-cell>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'control-panel-mapvisualizer-modal',
  data () {
    return {
      savePending: false,
      inputed: {
        title: '',
        description: '',
        level: '',
        coorsY: '',
        coorsX: '',
        mapKey: ''
      }
    }
  },
  computed: {
    ...mapState({
      showModal: state => state.cpanel.modals.mapVisualizer.show
    })
  },
  methods: {
    selectLoc (index) {
      this.$emit('selected', index)
      this.closeModal()
    },
    closeModal () {
      this.$store.dispatch('cpanel/SHOW_MAP_VISUALIZER', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    }
  }
}
</script>

<style>

</style>