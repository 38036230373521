<template>
  <div>
    <van-tabs @change="tabChanged" v-model="currTabIndex" style="z-index:10;position:sticky;top:.5rem;margin:.5rem 0 1rem 0;width:100%">
      <van-tab v-for="(tab, index) in $t('modules.quests.actionTypes')" style="padding:1rem 5rem;" :key="`${index}--tab`" :title="tab.title"></van-tab>
    </van-tabs>
    <div class="centeredInline tiny-text mb-1" v-if="currTabName==='done'">{{ $t('modules.quests.actDoneLimitDesc') }}</div>
    <template v-if="currTabName==='active'">
      <div class="centered infoTextSmall" v-if="!itemsActive.length">
        {{ $t('modules.controlPanel.questsEmpty') }}
      </div>
      <template v-else>
        <div class="centeredInline tiny-text mb-1">
          {{ $t('modules.quests.questsLimitInfo') }}
        </div>
        <van-cell @click="showQuestInfo(item)" clickable v-for="(item, index) in itemsActive" :key="`ia-${item.id}-${index}`">
          <template #icon>
            <img :src="`/assets/icons/small/quest_${isQuestDone(item)?'done':'available'}.png`" alt="" style="width:1.5rem;height:1.5rem;" />
          </template>
          <template #title>
            {{ item.name }}
            <template v-if="item.type!=='common'">
              {{ ` (${$t('modules.quests.types')[item.type].title})` }}
            </template>
          </template>
          <template #label>
            <van-tag v-if="item.rewardExp" class="exp-tag mr-05">{{ $t('modules.parameters.exp') }}</van-tag>
            <van-tag v-if="item.rewardGold" class="gold-tag mr-05">{{ $t('common.coins') }}</van-tag>
            <van-tag v-if="item.rewardItems.length" class="item-tag mr-05">{{ $t(item.rewardItems.length>1?'modules.quests.items':'common.item') }}</van-tag>
          </template>
        </van-cell>
      </template>
    </template>
    <template v-else>
      <div class="centeredInline infoText" style="margin-top:30vh" v-if="!itemsDone.length">
        {{ $t('modules.controlPanel.questsEmpty') }}
      </div>
      <template v-else>
        <van-cell v-for="(item, index) in itemsDone" :key="`ia-${item.id}-${index}`">
          <template #icon>
            <img src="/assets/icons/small/quest_done.png" alt="" style="width:1.5rem;height:1.5rem;" />
          </template>
          <template #title>
            {{ item.name }}
            <template v-if="item.type!=='common'">
              {{ ` (${$t('modules.quests.types')[item.type].title})` }}
            </template>
          </template>
          <template #label>
            <van-tag v-if="item.rewardExp" class="exp-tag mr-05">{{ $t('modules.parameters.exp') }}</van-tag>
            <van-tag v-if="item.rewardGold" class="gold-tag mr-05">{{ $t('common.coins') }}</van-tag>
            <van-tag v-if="item.rewardItems.length" class="item-tag mr-05">{{ $t(item.rewardItems.length>1?'modules.quests.items':'common.item') }}</van-tag>
          </template>
        </van-cell>
      </template>
    </template>
    <quest-info v-if="selectedQuest" :quest="selectedQuest" @rerender="selectedQuest=null"></quest-info>
  </div>
</template>

<script>
import QuestInfo from './modals/QuestInfo.vue'
export default {
  name: 'quests-index',
  components: {
    QuestInfo
  },
  data () {
    return {
      currTabIndex: 0,
      currTabName: '',
      itemsActive: [],
      itemsDone: [],
      selectedQuest: null
    }
  },
  methods: {
    showQuestInfo (quest = null) {
      if (!quest) return false
      this.selectedQuest = quest
      this.$nextTick(() => {
        this.$store.dispatch('quests/SHOW_QUEST_INFO', true)
      })
    },
    tabChanged (i) {
      this.currTabName = this.$t('modules.quests.actionTypes')[i].value
    },
    async fetchData () {
      this.itemsActive = await this.$client.service('quests').find({
        query: {
          id: {
            $in: [...this.uActions.questsAccepted]
          }
        }
      }).then(ia => {
        return ia.data
      }).catch(() => [])
      this.itemsDone = await this.$client.service('quests').find({
        query: {
          id: {
            $in: [
              ...this.uActions.questsDone,
              ...this.uActions.questsDoneDaily
            ]
          }
        }
      }).then(id => {
        return id.data
      }).catch(() => [])
    }
  },
  watch: {
    'uActions.lastSync' () {
      this.fetchData()
    }
  },
  created () {
    this.$store.dispatch('common/SET_APP_TITLE', this.$t('modules.quests.title'))
  },
  async mounted () {
    this.currTabName = this.$t('modules.quests.actionTypes')[this.currTabIndex].value
    this.fetchData()
  }
}
</script>

<style>

</style>