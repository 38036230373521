<template>
  <div>
    <div class="map-visualizer">
      <div v-for="(num, index) in locsNum" :key="index" @click="selectLoc(index)" :style="tileStyle(num)">
        <span v-if="showLocNumbers">{{ num }}</span>
        <div class="map-visualizer-dot" v-if="mapId && mapId === num && Object.keys(coors).length > 1" style="width:2px;height:2px" :style="{
          ...calculateDotPosition()
        }"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'map-visualizer-inline-component',
  props: {
    showLocNumbers: {
      type: Boolean,
      default: true
    },
    mapId: {
      type: Number
    },
    coors: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      locsNum: 100
    }
  },
  methods: {
    calculateDotPosition () {
      const xPerc = 100 / 50
      const yPerc = 100 / 50
      return {
        left: `${Math.round(xPerc * this.coors.x)}%`,
        top: `${Math.round(yPerc * this.coors.y)}%`
      }
    },
    tileStyle (num = null) {
      if (isNaN(num)) return {}
      return {
        boxSizing: 'border-box',
        backgroundImage: `url(/assets/mapvisualizer/maps/${num}.png)`,
        transform: this.mapId && this.mapId === num ? 'scale(2.5)' : '',
        border: this.mapId && this.mapId === num ? '1px solid red' : 'none',
        zIndex: this.mapId && this.mapId === num ? '2' : '1',
        position: this.mapId && this.mapId === num ? 'relative' : ''
      }
    },
    selectLoc (index) {
      if (isNaN(index)) return false
      this.$emit('loc-selected', index + 1)
    }
  }
}
</script>

<style>

</style>