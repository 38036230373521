<template>
  <van-overlay :z-index="200" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline row">
          <div class="modalTitle van-ellipsis">{{ `${$t('modules.controlPanel.npcEditTitle')}` }}</div>
          <van-form style="width:100%" @submit="onSubmit" ref="form">
            <div class="infoText">{{$t('modules.controlPanel.npcName')}}</div>
            <van-field
              type="text"
              v-model.trim="inputed.name"
              name="name"
              :placeholder="$t('modules.controlPanel.npcName')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
            <div class="infoText">{{$t('modules.controlPanel.npcMapId')}}<span style="margin-left:.5rem" @click="$store.dispatch('cpanel/SHOW_MAP_VISUALIZER', true)" class="underline pointer">{{ $t('common.select') }}</span></div>
            <van-field
              type="number"
              v-model.number="inputed.mapId"
              name="mapId"
              :placeholder="$t('modules.controlPanel.npcMapId')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
            <!-- sprite upload -->
            <div class="infoText">{{$t('modules.controlPanel.npcSprite')}}
              <br>
              <span class="tiny-text">{{ $t('common.clickImageForEdit') }}</span>
            </div>
            <div class="centeredInline" v-if="uploading" style="width:100%;">
              <van-progress :percentage="uploadProgress"></van-progress>>
            </div>
            <div class="centeredInline" id="__em-selectSpriteBtn">
              <div class="cell-icon pointer">
                <img :src="cdnPath(inputed.spritePath)" alt="" />
              </div>
            </div>
            <!-- sprite upload end -->
            <!-- image upload start -->
            <div class="infoText">{{$t('modules.controlPanel.npcImage')}}
              <br>
              <span class="tiny-text">{{ $t('common.clickImageForEdit') }}</span>
            </div>
            <div class="centeredInline" v-if="imageUploading" style="width:100%;">
              <van-progress :percentage="imageUploadProgress"></van-progress>>
            </div>
            <div class="centeredInline" id="__em-selectImageBtn">
              <div class="cell-icon pointer">
                <img :src="cdnPath(inputed.imagePath)" alt="" />
              </div>
            </div>
            <!-- image upload end -->
            <div class="infoText">{{$t('modules.controlPanel.npcDialogIdOnOpen')}}</div>
            <van-field
              type="number"
              v-model.number="inputed.dialogIdOnOpen"
              name="dialogIdOnOpen"
              :placeholder="$t('modules.controlPanel.npcDialogIdOnOpen')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
            <div class="infoText">{{$t('modules.controlPanel.npcCoors')}}</div>
            <van-field
              type="number"
              v-model.number="inputed.coorsX"
              name="coorsX"
              label="X"
              :placeholder="$t('common.coorsX')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
            <van-field
              type="number"
              v-model.number="inputed.coorsY"
              name="coorsY"
              label="Y"
              :placeholder="$t('common.coorsX')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
              <div class="centeredInline" style="margin:.5rem 0 0 0;">
              <van-button block :loading="savePending" :disabled="savePending" type="primary" native-type="submit">
                {{ $t('common.save') }}
              </van-button>
              <van-button block style="margin:0 0 0 .5rem" type="warning" @click.prevent="removeNpc">
                {{ $t('common.remove') }}
              </van-button>
              </div>
          </van-form>
          <file-upload
            :key="`ucmpk-${uploadingCmpKey}`"
            filetype = "npc-sprite"
            trigger = "__em-selectSpriteBtn"
            @on-selected = "onSelected"
            @on-upload = "onUploading"
            @on-progress = "onProgress"
            @on-success = "onSuccess"
            @on-error = "onError"></file-upload>
          <file-upload
            :key="`unicmp-${uploadingCmpKey}`"
            filetype = "npc-image"
            trigger = "__em-selectImageBtn"
            @on-selected = "onImageSelected"
            @on-upload = "onImageUploading"
            @on-progress = "onImageProgress"
            @on-success = "onImageSuccess"
            @on-error = "onImageError"></file-upload>
        <map-visualizer @selected="locSelected"></map-visualizer>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import FileUpload from '../../../../components/FileUpload.vue'
import MapVisualizer from '../../settings/modals/MapVisualizer.vue'
import { mapState } from 'vuex'
export default {
  name: 'control-panel-edit-npc',
  components: {
    MapVisualizer,
    FileUpload
  },
  props: {
    npc: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data () {
    return {
      savePending: false,
      uploading: false,
      fileSelected: null,
      imageSelected: null,
      imageUploadProgress: 0,
      imageUploading: false,
      uploadProgress: 0,
      uploadingCmpKey: 0,
      inputed: {
        name: '',
        imagePath: '',
        spritePath: '',
        mapId: '',
        coorsX: '',
        coorsY: '',
        dialogIdOnOpen: ''
      }
    }
  },
  computed: {
    ...mapState({
      showModal: state => state.cpanel.modals.editNpc.show
    })
  },
  methods: {
    removeNpc () {
      let self = this
      this.$dialog.confirm({
        title: self.$t('common.removeTitle'),
        message: self.$t('modules.controlPanel.removeNpcQ'),
        confirmButtonText: self.$t('common.remove'),
        cancelButtonText: self.$t('common.cancel'),
        showCancelButton: true,
        className: 'confirm'
      }).then(() => {
        self.$client.service('npc').remove(self.npc.id).then(() => {
          self.$toast(self.$t('modules.controlPanel.removeNpcOk'))
          self.closeModal()
        }).catch(e => {
          console.log(e)
          self.$toast(self.$t('modules.controlPanel.removeNpcError'))
        })
      }).catch(() => {
        // 
      })
    },
    locSelected (id = null) {
      if (!id) return false
      this.inputed.mapId = id
    },
    onSelected (file = null) {
      if (!file) return false
      this.fileSelected = file
    },
    onUploading () {
      this.uploading = true
    },
    onProgress (perc = 0) {
      this.uploadProgress = perc
    },
    onSuccess (file = null) {
      if (!file) return false
      this.$toast(this.$t('modules.controlPanel.uploadNpcSpriteSuccess'))
      this.inputed.spritePath = file.data.path
      this.uploading = false
      this.fileSelected = null
      this.uploadProgress = 0
      this.uploadingCmpKey++
    },
    onError () {
      this.$toast(this.$t('modules.controlPanel.uploadNpcSpriteError'))
      this.uploading = false
      this.uploadProgress = 0
      this.fileSelected = false
      this.uploadingCmpKey++
    },
    onImageSelected (file = null) {
      if (!file) return false
      this.imageSelected = file
    },
    onImageUploading () {
      this.imageUploading = true
    },
    onImageProgress (perc = 0) {
      this.imageUploadProgress = perc
    },
    onImageSuccess (file = null) {
      if (!file) return false
      this.$toast(this.$t('modules.controlPanel.uploadNpcImageSuccess'))
      this.inputed.imagePath = file.data.path
      this.imageUploading = false
      this.imageSelected = null
      this.imageUploadProgress = 0
      this.uploadingCmpKey++
    },
    onImageError () {
      this.$toast(this.$t('modules.controlPanel.uploadNpcImageError'))
      this.imageUploading = false
      this.imageUploadProgress = 0
      this.imageSelected = false
      this.uploadingCmpKey++
    },
    closeModal () {
      this.$store.dispatch('cpanel/SHOW_EDIT_NPC_MODAL', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    },
    async onSubmit () {
      if (this.inputed.name.length < 2 || this.inputed.name.length > 56) return this.$toast(this.$t('modules.controlPanel.npcNameLengthError'))
      if (isNaN(this.inputed.dialogIdOnOpen)) return this.$toast(this.$t('modules.controlPanel.npcDialogIdErr'))
      const checkout = await this.$refs.form.validate()
      if (!checkout) {
        this.savePending = true
        await this.$client.service('npc').patch(this.npc.id, {
          name: this.inputed.name,
          mapId: this.inputed.mapId,
          coorsX: this.inputed.coorsX,
          coorsY: this.inputed.coorsY,
          imagePath: this.inputed.imagePath,
          spritePath: this.inputed.spritePath,
          dialogOnOpenId: this.inputed.dialogIdOnOpen
        }).then(() => {
          this.$toast(this.$t('modules.controlPanel.editNpcOk'))
          this.savePending = false
          this.closeModal()
        }).catch(e => {
          console.log(e)
          this.savePending = false
          this.$toast(this.$t('modules.controlPanel.editNpcError'))
        })
        this.savePending = false
      }
    }
  },
  mounted () {
    this.inputed = {
      ...this.inputed,
      ...this.npc
    }
    this.inputed.dialogIdOnOpen = this.npc.dialogOnOpenId
  }
}
</script>

<style>

</style>