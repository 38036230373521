<template>
  <van-overlay :z-index="200" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline modalTitle">{{ $t('modules.controlPanel.players.manageQuests') }}</div>
        <div class="centeredInline" style="margin:15vh auto" v-if="pending">
          <van-loading />
        </div>
        <div v-else>
          <div v-if="!quests" class="centeredInline infoTextSmall" style="margin:15vh auto">
            {{ $t('modules.controlPanel.players.getQuestsError') }}
          </div>
          <div v-else>
            <div class="infoText mb-05">
              {{ $t('modules.quests.actionTypes')[0].title }}
            </div>
            <div class="infoTextSmall centeredInline mt-1" v-if="!quests.accepted.length">
              {{ $t('modules.controlPanel.questsEmpty') }}
            </div>
            <template v-else>
              <van-cell center v-for="(quest, index) in quests.accepted" :key="`q-acc-${quest.id}-${index}`">
                <template #title>
                  {{ quest.name }}
                </template>
                <template #extra>
                  <van-button @click="removeQuest(quest.id, 'accepted')" size="small" type="warning">{{ $t('common.remove') }}</van-button>
                </template>
              </van-cell>
            </template>
            <div class="infoText mb-05">
              {{ $t('modules.quests.actionTypes')[1].title }}
            </div>
            <div class="infoTextSmall centeredInline mt-1" v-if="!quests.done.length">
              {{ $t('modules.controlPanel.questsEmpty') }}
            </div>
            <template v-else>
              <van-cell center v-for="(quest, index) in quests.done" :key="`q-acc-${quest.id}-${index}`">
                <template #title>
                  {{ quest.name }}
                </template>
                <template #extra>
                  <van-button @click="removeQuest(quest.id, 'done')" size="small" type="warning">{{ $t('common.remove') }}</van-button>
                </template>
              </van-cell>
            </template>
            <div class="infoText mb-05">
              {{ $t('modules.quests.doneDaily') }}
            </div>
            <div class="infoTextSmall centeredInline mt-1" v-if="!quests.doneDaily.length">
              {{ $t('modules.controlPanel.questsEmpty') }}
            </div>
            <template v-else>
              <van-cell center v-for="(quest, index) in quests.doneDaily" :key="`q-acc-${quest.id}-${index}`">
                <template #title>
                  {{ quest.name }}
                </template>
                <template #extra>
                  <van-button @click="removeQuest(quest.id, 'doneDaily')" size="small" type="warning">{{ $t('common.remove') }}</van-button>
                </template>
              </van-cell>
            </template>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  props: {
    uid: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    pending: true,
    quests: null
  }),
  computed: {
    showModal () {
      return this.$store.state.cpanel.modals.managePlayerQuests.show
    }
  },
  methods: {
    removeQuest (qid = null, type = null) {
      if (!qid || !type) return false
      if (!['accepted', 'done', 'doneDaily'].includes(type)) return false
      const self = this
      this.$dialog.confirm({
        title: self.$t('common.removeTitle'),
        message: self.$t('modules.controlPanel.players.removeQuestQ'),
        confirmButtonText: self.$t('common.remove'),
        cancelButtonText: self.$t('common.cancel'),
        showCancelButton: true,
        className: 'confirm'
      }).then(() => {
        self.$client.service('setdata').create({
          removeQuestFromPlayer: true,
          questId: qid,
          playerId: this.uid,
          questType: type
        }).then(() => {
          self.$toast(self.$t('modules.controlPanel.players.removeQuestOk'))
          self.closeModal()
        }).catch(e => {
          console.log(e)
          self.$toast(self.$t('modules.controlPanel.players.removeQuestError'))
        })
      }).catch(() => {
        return false
      })

    },
    closeModal () {
      this.$store.dispatch('cpanel/SHOW_MANAGE_PLAYER_QUESTS_MODAL', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    },
  },
  mounted () {
    this.$client.service('getdata').find({
      query: {
        getQuests: true,
        uid: this.uid
      }
    }).then(d => {
      this.quests = d
      this.$nextTick(() => {
        this.pending = false
      })
    }).catch(() => {
      this.$toast(this.$t('modules.controlPanel.players.getQuestsError'))
      this.pending = false
    })
  }
}
</script>