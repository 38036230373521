<template>
  <van-overlay :z-index="200" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="centeredInline row">
          <div class="modalTitle van-ellipsis">{{ `${$t('modules.controlPanel.editMonsterTitle')}` }}</div>
          <van-form style="width:100%" @submit="onSubmit" ref="form">
            <div class="infoText">{{ $t('modules.controlPanel.monsterImage') }}</div>
            <div class="centeredInline">
              <div class="image-upload pointer" id="__imgPreview">
                <img :src="cdnPath(monster.image)" alt="">
              </div>
            </div>
            <div class="infoText">{{ $t('modules.controlPanel.monsterSprite') }}</div>
            <div class="centeredInline">
              <div class="image-upload pointer" id="__spritePreview">
                <img :src="cdnPath(monster.sprite)" alt="">
              </div>
            </div>
            <van-field
              style="margin-top:.75rem"
              type="text"
              v-model="inputed.name"
              name="name"
              :label="$t('modules.controlPanel.monsterName')"
              :placeholder="$t('modules.controlPanel.monsterName')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <van-field
              type="textarea"
              v-model="inputed.description"
              name="description"
              :label="$t('modules.controlPanel.monsterDescr')"
              :placeholder="$t('modules.controlPanel.monsterDescr')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <van-field
              type="number"
              v-model="inputed.hp"
              name="hp"
              :label="$t('modules.controlPanel.monsterHp')"
              :placeholder="$t('modules.controlPanel.monsterHp')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <van-field
              type="number"
              v-model="inputed.minDamage"
              name="minDamage"
              :label="$t('modules.controlPanel.monsterDmgMin')"
              :placeholder="$t('modules.controlPanel.monsterDmgMin')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <van-field
              type="number"
              v-model="inputed.maxDamage"
              name="maxDamage"
              :label="$t('modules.controlPanel.monsterDmgMax')"
              :placeholder="$t('modules.controlPanel.monsterDmgMax')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <van-radio-group v-model="inputed.damageType">
              <van-cell-group :title="$t('modules.controlPanel.monsterDmgType')">
                <van-cell :title="$t('common.physical')" clickable @click="inputed.damageType = 'phys'">
                  <template #right-icon>
                    <van-radio name="phys">
                      <template #icon="props">
                        <template v-if="props.checked">
                          <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                        </template>
                      </template>
                    </van-radio>
                  </template>
                </van-cell>
                <van-cell :title="$t('common.magical')" clickable @click="inputed.damageType = 'magic'">
                  <template #right-icon>
                    <van-radio name="magic">
                      <template #icon="props">
                        <template v-if="props.checked">
                          <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                        </template>
                      </template>
                    </van-radio>
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
            <van-field
              type="number"
              v-model="inputed.gainExp"
              name="gainExp"
              :label="$t('modules.controlPanel.monsterGainedExp')"
              :placeholder="$t('modules.controlPanel.monsterGainedExp')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <van-field
              type="number"
              v-model.number="inputed.gainCoins"
              name="gainConins"
              :label="$t('modules.controlPanel.monsterGainCoins')"
              :placeholder="$t('modules.controlPanel.monsterGainCoins')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <van-radio-group v-model="inputed.isBoss">
              <van-cell-group :title="$t('modules.controlPanel.monsterIsBoss')">
                <van-cell :title="$t('common.monster')" clickable @click="inputed.isBoss = false">
                  <template #right-icon>
                    <van-radio :name="false">
                      <template #icon="props">
                        <template v-if="props.checked">
                          <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                        </template>
                      </template>
                    </van-radio>
                  </template>
                </van-cell>
                <van-cell :title="$t('common.boss')" clickable @click="inputed.isBoss = true">
                  <template #right-icon>
                    <van-radio :name="true">
                      <template #icon="props">
                        <template v-if="props.checked">
                          <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                        </template>
                      </template>
                    </van-radio>
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
            <van-field
              type="number"
              v-model="inputed.locationId"
              name="locationId"
              readonly
              :label="$t('modules.controlPanel.monsterLocId')"
              :placeholder="$t('modules.controlPanel.monsterLocId')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <template v-if="inputed.isBoss">
              <van-field
                type="number"
                v-model="inputed.coorsX"
                name="coorsX"
                :label="$t('modules.controlPanel.monsterCoorsX')"
                :placeholder="$t('modules.controlPanel.monsterCoorsX')"
                :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
              <van-field
                type="number"
                v-model="inputed.coorsY"
                name="coorsY"
                :label="$t('modules.controlPanel.monsterCoorsY')"
                :placeholder="$t('modules.controlPanel.monsterCoorsY')"
                :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
            </template>
              <van-field
                type="number"
                v-model="inputed.physDef"
                name="physDef"
                :label="$t('modules.controlPanel.monsterPhysDef')"
                :placeholder="$t('modules.controlPanel.monsterPhysDef')"
                :rules="[{ required: true, message: $t('common.requiredField') }]"
              />
            <van-field
              type="number"
              v-model="inputed.magicDef"
              name="magicDef"
              :label="$t('modules.controlPanel.monsterMagDef')"
              :placeholder="$t('modules.controlPanel.monsterMagDef')"
              :rules="[{ required: true, message: $t('common.requiredField') }]"
            />
            <van-cell-group :title="$t('modules.controlPanel.monsterLoot')">
              <van-cell>
                <template #title>
                  <div style="items-center wrap">
                    <span v-if="!inputed.loot.length">
                      {{ $t('modules.controlPanel.noLoot') }}
                    </span>
                    <template v-else>
                      <van-tag style="margin:0 .125rem" v-for="(loot, index) in inputed.loot" :key="`loot-${loot.id}-${loot.chance}-${index}`" size="large" type="warning" @click.stop="removeLoot(index)">
                        {{ `ID: ${loot.id} (${loot.chance}%)` }}
                      </van-tag>
                    </template>
                    <div class="fake-button-small" v-show="!addLootMode" @click="addLootMode=true" style="margin-left:.5rem;">{{ $t('common.add') }}</div>
                    <div style="flex:1 0 100%" v-if="addLootMode">
                      <van-field
                        type="number"
                        v-model.number="inputed.loot.id"
                        name="lootItemId"
                        :label="$t('modules.controlPanel.itemId')"
                        :placeholder="$t('modules.controlPanel.itemId')"
                      />
                      <van-field
                        type="number"
                        v-model.number="inputed.loot.chance"
                        name="lootChance"
                        :label="$t('modules.controlPanel.lootChance')"
                        :placeholder="$t('modules.controlPanel.lootChance')">
                        <template #button>%</template>
                      </van-field>
                      <div class="centeredInline">
                        <div class="fake-button" @click.prevent="addMonsterLoot" style="margin-top:.5rem">{{ $t('common.add') }}</div>
                      </div>
                    </div>
                  </div>
                </template>
              </van-cell>
            </van-cell-group>
            <van-radio-group v-model="inputed.rarity">
              <van-cell-group :title="$t('common.rarity')">
                <van-cell :title="$t('common.rarityTypes')[0]" clickable @click="inputed.rarity = 'common'">
                  <template #right-icon>
                    <van-radio name="common">
                      <template #icon="props">
                        <template v-if="props.checked">
                          <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                        </template>
                      </template>
                    </van-radio>
                  </template>
                </van-cell>
                <van-cell :title="$t('common.rarityTypes')[1]" clickable @click="inputed.rarity = 'rare'">
                  <template #right-icon>
                    <van-radio name="rare">
                      <template #icon="props">
                        <template v-if="props.checked">
                          <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                        </template>
                      </template>
                    </van-radio>
                  </template>
                </van-cell>
                <van-cell :title="$t('common.rarityTypes')[2]" clickable @click="inputed.rarity = 'epic'">
                  <template #right-icon>
                    <van-radio name="epic">
                      <template #icon="props">
                        <template v-if="props.checked">
                          <fa-icon style="font-size:1rem" icon="check"></fa-icon>
                        </template>
                      </template>
                    </van-radio>
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
            <div style="margin-top: 1rem;" class="centeredInline">
              <van-button :loading="savePending" type="primary" :disabled="savePending" block native-type="submit">
                {{ $t('common.save') }}
              </van-button>
              <van-button style="margin-left:.75rem" :loading="removePending" type="warning" :disabled="removePending" block @click.prevent="remove">
                {{ $t('common.remove') }}
              </van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'control-panel-edit-monster',
  props: {
    monster: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      savePending: false,
      removePending: false,
      imgUploadKey: 0,
      spriteUploadKey: 0,
      imgFile: null,
      spriteFile: null,
      imgUploading: false,
      spriteUploading: false,
      addLootMode: false,
      imgProgress: 0,
      sprtProgress: 0,
      inputed: {
        loot: {
          id: '',
          chance: ''
        },
        name: '',
        description: '',
        hp: 100,
        minDamage: 0,
        maxDamage: 0,
        damageType: 'phys',
        gainExp: 0,
        gainCoins: 0,
        loot: [],
        isBoss: false,
        loot: '',
        locationId: '',
        coorsX: 0,
        coorsY: 0,
        physDef: 0,
        magicDef: 0,
        rarity: 'common',
        image: '',
        sprite: ''
      }
    }
  },
  computed: {
    ...mapState({
      showModal: state => state.cpanel.modals.editMonster.show
    })
  },
  methods: {
    removeLoot (index) {
      index ? this.inputed.loot.splice(index, 1) : this.inputed.loot.shift()
    },
    addMonsterLoot () {
      if (!this.inputed.loot.id || !this.inputed.loot.chance) return this.$toast(this.$t('modules.controlPanel.addLootError'))
      let index = this.inputed.loot.findIndex(i => +i.id === +this.inputed.loot.id)
      if (index > -1) return this.$toast(this.$t('modules.controlPanel.addLootError'))
      this.inputed.loot.push({
        id: this.inputed.loot.id,
        chance: this.inputed.loot.chance
      })
      this.inputed.loot.id = ''
      this.inputed.loot.chance = ''
      this.addLootMode = false
    },
    remove () {
      let self = this
      this.$dialog.confirm({
        title: self.$t('common.removeTitle'),
        message: self.$t('modules.controlPanel.removeMonsterQ'),
        confirmButtonText: self.$t('common.remove'),
        cancelButtonText: self.$t('common.cancel'),
        showCancelButton: true,
        className: 'confirm'
      }).then(() => {
        self.$client.service('monsters').remove(self.monster.id).then(() => {
          self.$toast(self.$t('modules.controlPanel.removeMonsterOk'))
          self.closeModal()
        }).catch(e => {
          console.log(e)
          self.$toast(self.$t('modules.controlPanel.removeMonsterError'))
        })
      }).catch(() => {
        // 
      })
    },
    onSelectedImg (file = null) {
      if (!file) return false
      this.imgFile = file
    },
    onUploadingImg () {
      this.imgUploading = true
    },
    onProgressImg (perc = 0) {
      this.imgProgress = perc
    },
    onSuccessImg (img) {
      this.inputed.image = img.data.path
      this.imgUploading = false
      this.imgProgress = 0
      this.imgFile = null
      this.$toast(this.$t('modules.controlPanel.monsterImageUploadOk'))
    },
    onErrorImg (err) {
      this.$toast(this.$t('modules.controlPanel.monsterImageUploadError'))
      console.log(err)
      this.imgUploading = false
      this.imgProgress = false
      this.imgFile = null
    },
    onSelectedSprt (file = null) {
      if (!file) return false
      this.spriteFile = file
    },
    onUploadingSprt () {
      this.spriteUploading = true
    },
    onProgressSprt (progress = 0) {
      this.sprtProgress = progress
    },
    onSuccessSprt (img) {
      this.inputed.sprite = img.data.path
      this.spriteUploading = false
      this.sprtProgress = 0
      this.spriteFile = null
      this.$toast(this.$t('modules.controlPanel.monsterSpriteUploadOk'))
    },
    onErrorSprt (err) {
      this.$toast(this.$t('modules.controlPanel.monsterSpriteUploadError'))
      console.log(err)
      this.spriteUploading = false
      this.sprtProgress = false
      this.spriteFile = null
    },
    closeModal () {
      this.$store.dispatch('cpanel/SHOW_EDIT_MONSTER_MODAL', false)
      setTimeout(() => {
        this.$emit('rerender')
      }, 300)
    },
    async onSubmit () {
      const checkout = await this.$refs.form.validate()
      if (!checkout) {
        this.savePending = true
        await this.$client.service('monsters').patch(this.monster.id, {
          image: this.inputed.image,
          sprite: this.inputed.sprite,
          name: this.inputed.name,
          description: this.inputed.description,
          hp: this.inputed.hp,
          minDamage: this.inputed.minDamage,
          maxDamage: this.inputed.maxDamage,
          damageType: this.inputed.damageType,
          loot: this.inputed.loot,
          gainCoins: this.inputed.gainCoins,
          gainExp: this.inputed.gainExp,
          isBoss: this.inputed.isBoss,
          locationId: this.inputed.locationId,
          coorsX: this.inputed.coorsX,
          coorsY: this.inputed.coorsY,
          physDef: this.inputed.physDef,
          magicDef: this.inputed.magicDef,
          rarity: this.inputed.rarity
        }).then(() => {
          this.$toast(this.$t('modules.controlPanel.editMonsterOk'))
          this.closeModal()
        }).catch(e => {
          console.log(e)
          this.$toast(this.$t('modules.controlPanel.editMonsterError'))
        })
        this.savePending = false
      }
    }
  },
  mounted () {
    this.inputed = {
      ...this.inputed,
      ...this.monster
    }
  }
}
</script>

<style>

</style>