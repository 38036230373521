<template>
  <van-overlay :z-index="11" :show="showModal" @click="closeModal" :lock-scroll="false">
    <div class="modal_common-overlay" @click.stop="closeModal">
      <div class="modal_common-content" @click.stop>
        <div class="close-modal" @click.stop="closeModal"><fa-icon icon="times"></fa-icon></div>
        <div class="modalTitle centeredInline">{{ monster.name }}</div>
        <div class="centeredInline">
          <div class="image-upload" style="padding:1rem">
            <img :src="cdnPath(monster.image)" alt="" />
          </div>
        </div>
        <p>{{ monster.description }}</p>
        <ul>
        </ul>
      </div>
    </div>
  </van-overlay>
</template>

<script>
export default {
  name: 'global-component-monster-info',
  props: {
    monster: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
    }
  },
  computed: {
    showModal () {
      return this.$store.state.components.modals.monsterInfo.show
    }
  },
  methods: {
    cancelQuest () {
      this.closeModal()
    },
    closeModal () {
      this.$store.dispatch('components/SET_MONSTER_INFO', { monster: this.monster, show: false })
      setTimeout(() => {
        this.$emit('rerender')
        this.$store.dispatch('components/SET_MONSTER_INFO', { monster: null, show: false })
      }, 300)
    }
  }
}
</script>

<style>

</style>